(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.footer', [
    'pp.services.core',
    'pp.services.browser-store',
    'pp.services.config',
    'pp.services.route',
    'pp.widgets.performance-warning',
    'pp.filters.dates'
]);

var HOME_PATH = '/';
var HOW_IT_WORKS_PATH = '/howitworks';

ngModule.component('ppFooter', {
    templateUrl: 'widgets/_angular/footer/footer.tpl.html',
    bindings: {
        isSlim: '<'
    },
    controllerAs: 'vm',
    controller: ['$window', '$q', 'configService', 'routeService', function ($window, $q, configService, routeService) {
        var vm = this;

        // -- initial state

        vm.knowledgeBaseUrl = routeService.knowledgeBaseLink;

        vm.date = new Date();

        vm.propertyTeamPath = routeService.propertyTeamPath;
        vm.crowdfundingLpPath = routeService.crowdfundingLpPath;
        vm.isaLandingPage = routeService.isaLandingPage;
        vm.feesChargesHelp = routeService.feesChargesHelp;

        vm.keyRisksUrl = routeService.keyRisksUrl;

        vm.investmentSafeguard = routeService.investmentSafeguard;
        vm.blogPath = routeService.blogPath;
        vm.recentTradesUrl = routeService.recentTradesUrl;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);