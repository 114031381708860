(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.classification-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.investor',
    'pp.widgets.classification',
    'pp.widgets.kyc-and-classification'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('classificationDialogService', ['$rootScope', '$timeout', '$q', 'ppTrack', 'dialogService', 'investorService', function ($rootScope, $timeout, $q, ppTrack, dialogService, investorService) {

    // -- initial state

    var promiseCache;

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(user, onFail, onSuccess, onDismiss, isReclassification) {

        ppTrack.action('classification.dialog.show');

        var scope = $rootScope.$new();
        scope.user = user;
        scope.onFail = onFail;
        scope.onSuccess = onSuccess;
        scope.onDismiss = onDismiss;
        scope.quizPassed = false;
        scope.isReclassification = !!isReclassification;

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: true,
            classNames: 'large pp-classification-dialog',
            scope: scope
        };

        options.contents = '<div pp-classification user="user" on-fail="onFail()" on-success="onSuccess()" quiz-passed="quizPassed" is-reclassification="isReclassification"></div>';

        return dialogService.show(options).then(function (response) {

            var checkResponse = response || {};

            if (checkResponse.dismissed) {

                // if modal is closed by user
                // notify controller (page may need to be refreshed to update state)
                // using $$childTail as the last resort (no binding between current and the child scope)
                ppTrack.pageEvent('classification', 'dismiss');
                var quizPassed = angular.isObject(scope.$$childTail) ? scope.$$childTail.quizPassed : false;
                onDismiss(quizPassed);

            }
        });
    }

    function showKycAndClassificationDialog(user, onFail, onSuccess, onDismiss) {
        var scope = $rootScope.$new();
        scope.user = user;
        scope.onFail = onFail;
        scope.onSuccess = onSuccess;
        scope.onDismiss = onDismiss;
        scope.quizPassed = false;

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: true,
            classNames: 'large pp-kyc-and-classification-dialog',
            scope: scope
        };

        options.contents = '<pp-kyc-and-classification user="user" on-load="angular.noop()" no-more-information-required="vm.noMoreKycAndClassificationInfoNeeded()" on-fail="vm.noMoreKycAndClassificationInfoNeeded()"></pp-kyc-and-classification>';
        return dialogService.show(options).then(function (response) {

            var checkResponse = response || {};

            if (checkResponse.dismissed) {
                ppTrack.pageEvent('kyc-and-classification', 'dismiss');
                onDismiss();
            }
        });
    }

    // -- api

    var api = {};

    api.show = function (user, onFail, onSuccess, onDismiss) {
        promiseCache = showDialog(user, onFail, onSuccess, onDismiss);
        return promiseCache;
    };

    api.close = function () {
        if (promiseCache) {
            dialogService.close();
        }
    };

    api.classifyUser = function (user, onFail) {

        var defer = $q.defer();

        var next = function (defer) {
            defer.resolve();
        }.bind(this, defer);

        if (investorService.isClassifiedUser(user)) {
            return $q.when(user);
        }

        promiseCache = showDialog(user, onFail, next, next);

        return defer.promise.then(function () {
            investorService.purgeCache('investor$');
            return investorService.getInvestor();
        }).then(function (user) {
            api.close();
            if (investorService.isClassifiedUser(user)) {
                return $q.when(user);
            } else {
                return $q.reject();
            }
        });
    };

    api.kycAndClassifyUser = function (user, onFail) {
        var defer = $q.defer();

        var next = function (defer) {
            defer.resolve();
        }.bind(this, defer);

        if (investorService.isClassifiedAndKycedUser(user)) {
            return $q.when(user);
        }

        promiseCache = showKycAndClassificationDialog(user, onFail, next, next);

        return defer.promise.then(function () {
            investorService.purgeCache('investor$');
            return investorService.getInvestor();
        }).then(function (user) {
            api.close();
            if (investorService.isClassifiedAndKycedUser(user)) {
                return $q.when(user);
            } else {
                return $q.reject();
            }
        });
    };

    api.reclassifyUser = function (user, onFail) {

        var defer = $q.defer();

        var next = function (defer) {
            defer.resolve();
        }.bind(this, defer);

        dialogService.close();

        return $timeout(function () {
            promiseCache = showDialog(user, onFail, next, next, true);
            return defer.promise.then(function () {
                investorService.purgeCache('investor$');
                return investorService.getInvestor();
            }).then(function (user) {
                api.close();
                if (investorService.isClassifiedUser(user)) {
                    return $q.when(user);
                } else {
                    return $q.reject();
                }
            });
        });

    };

    return api;
}]);
})(window, window.angular);