(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-financials', [
    'ui.router',
    'pp.widgets-templates',
    'pp.components-templates',
    'pp.widgets.financials-share-valuation',
    'pp.widgets.financials-hpi',
    'pp.widgets.financials-forecast-income',
    'pp.widgets.financials-property-units',
    'pp.components.tabs',
    'pp.filters.numbers',
    'pp.filters.text',
    'pp.filters.numbers',
    'pp.widgets.hpi-range',
    'pp.services.core'
]);

ngModule.directive('ppPropertyFinancials', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-financials/property-financials.tpl.html',
        scope: {
            property: '<ppPropertyFinancials',
            incomeDisclosure: '<',
            unitsDisclosure: '<',
            onTabChange: '&',
            unitValuationDate: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$state', 'R', function ($scope, $state, R) {
            var vm = this;

            // -- initial state

            vm.tabs = [];

            var shareValuation = {
                title: 'Valuation',
                key: 'shareValuation'
            };

            var forecastIncome = {
                title: 'Income',
                key: 'forecastIncome'
            };

            var unitsDisclosure = {
                title: 'Unit Details',
                key: 'unitsDisclosure'
            };

            var DEFAULT_TAB = shareValuation.key;

            var isArray = R.is(Array);
            var notEmpty = R.complement(R.isEmpty);

            // -- util functions

            function showUnitsDisclosureTab(unitsDisclosure) {
                var currentUnits = R.prop('current', unitsDisclosure);
                var soldUnits = R.prop('sold', unitsDisclosure);
                return (isArray(currentUnits) && notEmpty(currentUnits)) || (isArray(soldUnits) && notEmpty(soldUnits));
            }

            function getActiveTab(tabs) {
                var state = $state.current.name.replace('property.', '');

                var activeTab = R.compose(R.head, R.filter(function (item) {
                    return item.key === state;
                }))(tabs);

                if (R.isNil(activeTab)) {
                    return DEFAULT_TAB;
                } else {
                    return activeTab.key;
                }

            }

            // -- api

            vm.tabChange = function (tab) {
                vm.onTabChange({
                    tab: tab
                });
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.tabs = [shareValuation, forecastIncome];

                if (showUnitsDisclosureTab(vm.unitsDisclosure)) {
                    vm.tabs = vm.tabs.concat([unitsDisclosure]);
                }

                vm.activeTab = getActiveTab(vm.tabs);
            };

        }]
    };
}]);
})(window, window.angular);