(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace', [
    'ui.router',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.browser-store',
    'pp.services.marketplace',
    'pp.services.dashboard',
    'pp.services.property',
    'pp.services.apo',
    'pp.services.receipt',
    'pp.services.vote',
    'pp.services.config',
    'pp.services.dialog',
    'pp.widgets.property-card',
    'pp.widgets.property-card-simple',
    'pp.widgets.property-card-mobile',
    'pp.widgets.placeholder-promotion',
    'pp.widgets.advance-pre-order-card',
    'pp.widgets.live-marketplace',
    'pp.widgets.selling-record',
    'pp.components.secondary-nav-center',
    'pp.ui.services.dashboard-individual-property-sidebar'
]);

// if current view is not equal to page name track
// page name: marketplace, data-view (use these constants throughout)
var PAGE_NAME_MARKETPLACE = 'marketplace';
var DEFAULT_GRID_FILTER = 'uk';
var DEFAULT_GRID_SORT;

var DEFAULT_MAP_FILTER = 'marketplace';
var HOLDINGS_MAP_FILTER = 'holdings';
var MAP_FILTER_STORAGE_KEY = 'map-view.filter';
var GRID_FILTER_STORAGE_KEY = 'grid-view.filter';
var GRID_SORT_STORAGE_KEY = 'grid-view.sort';
var GRID_SEARCH_STORAGE_KEY = 'grid-view.search';
var DEFAULT_INVESTMENT_PLAN_POSITION = 0;

ngModule.component('ppMarketplace', {
    templateUrl: 'widgets/_angular/marketplace/marketplace.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$sce', '$q', '$timeout', '$anchorScroll', '$transitions', '$state', '$stateParams', 'ppTrack', 'ppComponentEmitter', 'investorService', 'marketplaceService', 'apoService', 'configService', 'browserStoreService', 'receiptService', 'propertyService', 'dashboardService', 'R', 'dashboardIndividualPropertySidebarService', 'dialogService', 'voteService', function ($scope, $sce, $q, $timeout, $anchorScroll, $transitions, $state, $stateParams, ppTrack, ppComponentEmitter, investorService, marketplaceService, apoService, configService, browserStoreService, receiptService, propertyService, dashboardService, R, dashboardIndividualPropertySidebarService, dialogService, voteService) {
        var vm = this;

        // -- initial state

        vm.apoFunds = {};

        vm.currentState = $state.current.name;

        vm.mapFilter = browserStoreService.getSessionStorageItem(MAP_FILTER_STORAGE_KEY) || DEFAULT_MAP_FILTER;

        vm.gridFilter = $state.params.filter || browserStoreService.getSessionStorageItem(GRID_FILTER_STORAGE_KEY) || DEFAULT_GRID_FILTER;
        vm.gridSearch = browserStoreService.getSessionStorageItem(GRID_SEARCH_STORAGE_KEY) || '';
        vm.gridSort = browserStoreService.getSessionStorageItem(GRID_SORT_STORAGE_KEY) || DEFAULT_GRID_SORT;

        vm.featuredProperties = [];

        vm.mapReady = false;

        vm.anchorScroll = $anchorScroll;

        vm.trustAsHtml = $sce.trustAsHtml;

        // -- util functions

        function catchToEmptyList(promise) {
            return promise.catch(function () {
                return [];
            });
        }

        function extractSymbol(holding) {
            return holding.propertySymbol;
        }

        function tagPropertiesWithHoldings(properties, holdings) {
            var holdingsArray = holdings.map(extractSymbol);

            return properties.map(function (property) {
                property.holding = holdingsArray.indexOf(property.symbol) > -1;
                return property;
            });
        }

        function getSelectedPropertyOnMap(symbol, properties) {
            for (var ix = 0; ix < properties.length; ix++) {

                //if the filter is set to my investments, set properties to null if not tagee
                if (properties[ix].symbol === symbol) {

                    //if selected prooperty is not a holding and in holding filter don't show property
                    if ((vm.mapFilter === HOLDINGS_MAP_FILTER) && !properties[ix].holding) {
                        return null;
                    } else {
                        return properties[ix];
                    }

                }
            }
        }

        function getSearchResultCount(search, searchResult) {
            return search ? searchResult.length : null;
        }

        function getParamsFromState() {
            var params = {};
            if ($state.params.filter) {
                params.filter = $state.params.filter;
            } else if (vm.gridFilter) {
                params.filter = vm.gridFilter;
            }

            params.sort = vm.gridSort;

            return params;
        }

        function pushFeaturedProperty(symbol) {
            if (vm.featuredProperties.indexOf(symbol) === -1) {
                vm.featuredProperties = vm.featuredProperties.concat([symbol]);
            }
        }

        function createFeaturedList(sets) {
            var featuredSets = angular.copy(sets);

            // featuredSets.splice(vm.getInvestmentPlanPosition(), 0, {
            //     investmentPlans: true
            // });

            return featuredSets;
        }

        function bindFeaturedViewFilter(fn) {
            vm.isFeaturedProperty = fn;
        }

        function bindFeaturedViewSort(fn) {
            vm.featuredViewSort = fn;
        }

        function bindGetInvestmentPlanPosition(fn) {
            vm.getInvestmentPlanPosition = fn;
        }

        function refreshFeaturedProperties() {
            if (vm.featuredViewList && vm.propertySets) {
                $scope.$evalAsync(function () {
                    vm.featuredViewList = createFeaturedList(vm.propertySets);
                });
            }
        }

        function isNotFund(property) {
            return !property.isFund;
        }

        function setImportantNews(items) {
            if (R.is(Array, items)) {
                $scope.$evalAsync(function () {
                    vm.importantNews = R.map(R.clone, items);
                });
            }
        }

        var mergeHolding = R.curry(function (holdings, item) {
            var holding = R.path([item.symbol], holdings);
            return R.mergeRight(holding, item);
        });

        function loadHoldings(user) {
            if (user.anon) {
                vm.holdingsReady = true;
                return $q.when();
            } else {
                return $q.all({
                    equity: dashboardService.getTotalReturn().catch(function () {
                        return {
                            collection: [],
                            bids: [],
                            offers: []
                        };
                    }),
                    debt: dashboardService.getCurrentLoans().catch(function () {
                        return {
                            collection: []
                        };
                    })
                }).then(function (res) {
                    var mergeHoldings = R.map(mergeHolding(res.equity.holdings || {}));

                    vm.holdingsSymbolDict = R.indexBy(
                        R.prop('symbol'),
                        R.concat(
                            mergeHoldings(res.equity.collection),
                            mergeHoldings(res.debt.collection)
                        )
                    );
                    vm.bids = res.equity.bids;
                    vm.offers = res.equity.offers;
                    vm.holdingsReady = true;
                });
            }
        }

        function loadPendingInvestment(user) {
            if (user.anon) {
                vm.pendingInvestmentsReady = true;
                return $q.when();
            } else {
                return investorService.getHoldingsPending().then(function (res) {
                    vm.pendingInvestmentsReady = true;
                    return res;
                }, function () {
                    vm.pendingInvestmentsReady = true;
                    return [];
                });
            }
        }

        function routeToPropertySidebar(symbol) {
            $state.go($state.current.name.replace('.open-property', '') + '.open-property', {
                propertySymbol: symbol
            });
        }

        function openPropertySidebar(property) {
            var holding = R.path([property.symbol], vm.holdingsSymbolDict) || {};
            dashboardIndividualPropertySidebarService.show(property, holding, function () {
                    loadHoldings(vm.user);
                })
                .then(function (res) {
                    if (R.prop('dismissed', res) || R.prop('closed', res)) {
                        $state.go($state.current.name.replace('.open-property', ''));
                    }
                });
        }

        function openPropertySidebarFromSymbol(symbol) {
            if (!symbol) {
                // Note early return
                return;
            }

            var property = vm.propertiesDict[symbol];

            if (property) {
                $scope.$evalAsync(function () {
                    openPropertySidebar(property);
                });
            }
        }

        // -- api

        vm.onViewPropertyDetail = function (property) {
            routeToPropertySidebar(property.symbol);
        };

        vm.onViewPropertyDetailFromSymbol = function (symbol) {
            var property = vm.propertiesDict[symbol];
            if (!property) {
                return;
            }
            routeToPropertySidebar(symbol);
        };

        $transitions.onSuccess({}, function (transition) {
            $scope.$evalAsync(function () {
                openPropertySidebarFromSymbol($stateParams.propertySymbol);
            });
        });

        vm.clearStoredFilters = function () {
            vm.gridFilter = DEFAULT_GRID_FILTER;
            vm.gridSearch = '';
            vm.gridSort = '';
            browserStoreService.deleteSessionStorageItem(GRID_SEARCH_STORAGE_KEY);
            browserStoreService.deleteSessionStorageItem(GRID_SORT_STORAGE_KEY);
            browserStoreService.deleteSessionStorageItem(GRID_FILTER_STORAGE_KEY);
            vm.updateMarketplaceProperties(getParamsFromState(), vm.gridSearch);
        };

        vm.isFeaturedProperty = function (property) {
            if (property.type === 'development_loan') {
                return true;
            } else {
                return propertyService.isPrimaryOfferedProperty(property);
            }
        };

        vm.featuredViewSort = function () {
            return 0;
        };

        vm.getInvestmentPlanPosition = function () {
            return DEFAULT_INVESTMENT_PLAN_POSITION;
        };

        //on selcting a pin change card
        vm.onSelectedProperty = function (symbol, properties) {
            // refreshes the property card dom by setting property to null before resetting it,
            // this is a workaround for the component emitter so that dom changes in optimizely
            // are refreshed on property update
            $timeout(function () {
                vm.property = null;
            }).then(function () {
                $timeout(function () {
                    vm.property = getSelectedPropertyOnMap(symbol, properties);
                });
            });
        };

        vm.loadMapData = function () {
            var promises = {
                properties: marketplaceService.getPropertiesList(),
                user: investorService.getInvestor()
            };

            return $q.all(promises).then(function (data) {
                var promises = {
                    properties: $q.when(data.properties.filter(isNotFund))
                };

                if (!data.user.anon) {
                    promises.holdings = investorService.getHoldingsCurrent();
                }

                return $q.all(promises);

            }).then(function (data) {

                if (!data.holdings) {
                    return marketplaceService.filterMapViewProperties(data.properties);
                }

                return tagPropertiesWithHoldings(
                    marketplaceService.filterMapViewProperties(data.properties),
                    data.holdings
                );
            });
        };

        vm.mapViewFilter = function (filter) {
            browserStoreService.setSessionStorageItem(MAP_FILTER_STORAGE_KEY, filter);
            ppTrack.event('pp.map-view.filter.' + filter);
            vm.onSelectedProperty(vm.property.symbol);
        };

        vm.updateMarketplaceProperties = function (params, search) {

            vm.loadingGrid = true;
            vm.propertiesReady = false;
            var cachedParams = params || {};
            vm.gridSort = cachedParams.sort;
            vm.gridFilter = cachedParams.filter || vm.gridFilter;
            vm.gridSearch = search;
            browserStoreService.setSessionStorageItem(GRID_SEARCH_STORAGE_KEY, search || '');
            browserStoreService.setSessionStorageItem(GRID_FILTER_STORAGE_KEY, vm.gridFilter);
            browserStoreService.setSessionStorageItem(GRID_SORT_STORAGE_KEY, vm.gridSort || '');

            var promises = {
                properties: marketplaceService.getPropertiesList(params, search),
                apoCardPositions: configService.getApoCardPositions()
            };

            return $q.all(promises).then(function (data) {

                var properties = marketplaceService.shuffleFeaturedPropertiesToTop(vm.featuredProperties, data.properties.filter(function (item) {
                    return item.symbol !== 'UKEC4N5AT002';
                }));
                var apoCardPositions = data.apoCardPositions;

                if (!search) {
                    properties = apoCardPositions.reduce(apoService.insertApoCardInArrayPosition, properties);
                }

                // vm.gridViewProperties = properties;

                vm.liveTradingProperties = marketplaceService.filterLiveTradingProperties(properties);
                vm.otherInvestments = marketplaceService.filterOtherInvestments(properties);

                vm.loadingGrid = false;
                vm.propertiesReady = true;
                return properties;
            });
        };

        // -- scope bindings

        $transitions.onStart({
            to: 'marketplace.**'
        }, function (trans) {
            vm.currentState = trans.$to().name;
            ppTrack.pageLoad(vm.currentState);
            dialogService.close();
        });

        apoService.on('apo-funds-updated', function (apoFunds) {
            vm.apoFunds = apoFunds;
        });

        investorService.on('investor-updated', function (user) {
            vm.user = user;

            //update apo funds if user logs in and gets updated
            if (!user.anon) {
                configService.getApoCardPositions().then(function (apoCardPositions) {
                    if (apoCardPositions.length) {
                        apoService.purgeCache();
                        apoService.getAdvancedPreOrderForInvestor();
                    }
                });
            }
        });

        // -- main

        var propertyPromise = marketplaceService.getPropertiesList(getParamsFromState(), vm.gridSearch);

        var allPropertyPromise = marketplaceService.getPropertiesList();

        var investorPromise = investorService.getInvestor();

        var voteResultPromise = voteService.getVoteResults();

        marketplaceService.getTrades().then(function (trades) {
            vm.trades = trades;
        });

        var generalPromises = {
            user: investorPromise,
            apoPositions: configService.getApoCardPositions(),
            bondCardConfig: configService.getBondConfig()
        };

        //check for receipts at the same time we load the data
        receiptService.checkForReceipts();

        vm.ready = true;

        allPropertyPromise.then(function (properties) {
            vm.topRisers = marketplaceService.getTopRisers(properties);
            vm.topFallers = marketplaceService.getTopFallers(properties);
            vm.propertiesDict = R.indexBy(R.prop('symbol'), properties);
            vm.rightsIssueFundings = marketplaceService.filterRightsIssues(properties);
            vm.blockTradeFundings = marketplaceService.filterBlockTrades(properties);
        });

        voteResultPromise.then(function (voteResults) {
            vm.voteResults = voteResults;
        });

        $q.all(generalPromises).then(function (result) {
            var user = result.user;
            var apoCardPositions = result.apoPositions;
            vm.user = user;
            vm.loadingGrid = true;
            vm.bondCardConfig = result.bondCardConfig;

            if (!user.anon && apoCardPositions.length) {
                //will emit apo-funds-updated
                apoService.getAdvancedPreOrderForInvestor();
            }

            var holdingsPromise = loadHoldings(user);
            var pendingInvestmentsPromise = loadPendingInvestment(user);

            propertyPromise.then(function (properties) {

                vm.liveTradingProperties = apoCardPositions.reduce(
                    apoService.insertApoCardInArrayPosition,
                    marketplaceService.shuffleFeaturedPropertiesToTop(
                        vm.featuredProperties,
                        marketplaceService.filterLiveTradingProperties(properties)
                    )
                );

                vm.otherInvestments = marketplaceService.filterOtherInvestments(properties);

                vm.loadingGrid = false;
                vm.propertiesReady = true;
                ppTrack.pageLoad(PAGE_NAME_MARKETPLACE);
            });

            $q.all({
                sets: marketplaceService.getPropertySets(allPropertyPromise),
                allProperties: allPropertyPromise,
                holdingsPromise: holdingsPromise,
                pendingInvestments: pendingInvestmentsPromise
            }).then(function (res) {
                var sets = res.sets;
                vm.propertySets = sets;
                vm.pendingInvestments = res.pendingInvestments;
                vm.featuredViewList = createFeaturedList(sets);
                openPropertySidebarFromSymbol($stateParams.propertySymbol);
            });

        });

        var themesPromise = marketplaceService.getThemes();
        var sortPromise = marketplaceService.getSortOptions();

        $q.all({
            user: investorPromise,
            filters: themesPromise,
            sorts: sortPromise
        }).then(function (data) {
            vm.filterOptions = data.filters;
            vm.sortOptions = data.sorts;
            vm.optionsReady = true;
        });

        var publicApi = {
            pushFeaturedProperty: pushFeaturedProperty,
            bindFeaturedViewFilter: bindFeaturedViewFilter,
            bindFeaturedViewSort: bindFeaturedViewSort,
            bindGetInvestmentPlanPosition: bindGetInvestmentPlanPosition,
            refreshFeaturedProperties: refreshFeaturedProperties,
            setImportantNews: setImportantNews,
            showNewCards: angular.noop
        };

        ppComponentEmitter.emit('pp.marketplace.api', publicApi);

    }]
});
})(window, window.angular);