(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.withdrawal', ['pp.services.investor']);

var POST_WITHDRAW_ENDPOINT = '/investor/account/withdraw';

var CLASSIFY_CODES = {
    'high-net': 'HW',
    'sophisticated': 'SI',
    'regular': 'RI'
};

var ACCOUNT_TYPE_MAP = {
    uk: 'UK',
    international: 'IBAN',
    us: 'US',
    rotw: 'ROTW'
};

ngModule.service('withdrawalService', ['$http', '$q', 'investorService', function ($http, $q, investorService) {

    var api = {};

    api.withdraw = function (amount, currencyCode, accountId, bankAccountType, password) {
        var payload = {
            amount: amount,
            currencyCode: currencyCode,
            targetAccountId: accountId,
            accountType: ACCOUNT_TYPE_MAP[bankAccountType],
        };

        var endpoint = POST_WITHDRAW_ENDPOINT;

        var options = {
            'headers': {
                'Content-type': 'application/json;charset=UTF-8'
            }
        };

        return $http.post(endpoint, payload, options).then(
            function () {
                return true;
            },
            function (error) {
                switch (error.status) {
                case 403:
                    return $q.reject({
                        reason: 'withdrawal.error.unauthorised'
                    });
                default:
                    return $q.reject({
                        reason: 'withdrawal.error'
                    });
                }

            }
        );
    };

    return api;
}]);
})(window, window.angular);