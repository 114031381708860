(function(window, angular){'use strict';

var ngModule = angular.module('pp.app', [
    'pp.app.property',
    'pp.controllers.portfolio',
    'pp.controllers.reset',
    'pp.controllers.password-reset',
    'pp.controllers.pin-reset',
    'pp.controllers.settings',
    'pp.controllers.account-router',
    'pp.controllers.marketplace-router',
    'pp.controllers.investor-router',
    'pp.controllers.student-lp',
    'pp.controllers.commercial-lp',
    'pp.widgets.crowdfunding-lp',
    'pp.widgets.how-crowdfunding-works',
    'pp.widgets.crowdfunding-benefits',
    'pp.widgets.what-is-crowdfunding-lp',
    'pp.widgets.property-team-lp',
    'pp.widgets.how-it-works',
    'pp.widgets.resources-dropdown',
    'pp.ui.services.required-user-data-dialog',
    'pp.services.run',
    // styleguide @todo move some of these widget (non global ones) to the controllers that require them
    'pp.widgets.premium-services-lp',
    'pp.widgets.affiliate-landing-page',
    'pp.widgets.footer',
    'pp.widgets.sell',
    'pp.widgets.isa-lp',
    'pp.widgets.mortgage-bond-list',
    'pp.widgets.trust-certificate-list',
    'pp.widgets.refer-a-friend',
    'pp.widgets.referral-banner',
    'pp.widgets.auth-modal',
    'pp.widgets.auth-login',
    'pp.widgets.auth-signup',
    'pp.widgets.password-reset-request-confirmation',
    'pp.widgets.password-reset-complete-confirmation',
    'pp.widgets.auth-nav',
    'pp.widgets.auth-nav-mobile',
    'pp.widgets.auth-signup-cta',
    'pp.widgets.header-nav-mobile',
    'pp.widgets.kyc-document-verification',
    'pp.widgets.kyc-more-info',
    'pp.widgets.map',
    'pp.widgets.bid',
    'pp.widgets.property',
    'pp.components.toggle-options',
    'pp.ui.services.risk-warning'
]);

ngModule.run(['runService', 'requiredUserDataDialogService', 'riskWarningService', function (runService, requiredUserDataDialogService, riskWarningService) {

    runService.init(requiredUserDataDialogService);
    riskWarningService.showMainRiskWarning();

}]);

ngModule.config(['$locationProvider', '$compileProvider', function ($locationProvider, $compileProvider) {
    $locationProvider.hashPrefix('');
    $compileProvider.debugInfoEnabled(false);
}]);
})(window, window.angular);