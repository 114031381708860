(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-simple-thumbnail', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.filters.numbers',
    'pp.components.time-left',
    'pp.components.tooltip',
    'pp.services.config',
    'pp.services.route',
    'pp.widgets.investment-ownership-markers'
]);

ngModule.component('ppPropertyCardSimpleThumbnail', {
    templateUrl: 'widgets/_angular/property-card-simple-thumbnail/property-card-simple-thumbnail.tpl.html',
    bindings: {
        property: '<',
        holdings: '<',
        bids: '<',
        offers: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'configService', 'routeService', 'R', function ($scope, ppConfig, configService, routeService, R) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var assetsEndpoint = __endpoints.assets;

        vm.propertyTypeMap = {
            'development_loan': 'Development Loan',
            residential: 'Residential',
            commercial: 'Commercial',
            student: 'Student',
            fund: 'Fund',
            trust: 'Bridging Finance'
        };

        // -- util functions

        function createThumbnailStyle(property) {
            return {
                'background-image': 'url(' + assetsEndpoint + '/images/properties/' + vm.property.symbol + '/image3-grid-list.jpg)'
            };
        }

        function getPropertyTypeLabel(property) {
            if (property.isMortgage) {
                return 'Mortgage Bond';
            } else if (property.assetType === 'development_loan' && property.replacementLoanType) {
                return vm.property.replacementLoanType;
            } else {
                return vm.propertyTypeMap[property.assetType];
            }
        }

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (changes.property && !changes.property.isFirstChange() && changes.property.currentValue) {
                vm.thumbnailStyle = createThumbnailStyle(changes.property.currentValue);
            }

            var symbol = R.path(['property', 'currentValue', 'symbol'], changes);
            if (symbol) {
                vm.propertyLink = routeService.getIndividualPropertyPath(symbol);
            }

            if (changes.property && changes.property.currentValue) {
                vm.propertyTypeLabel = getPropertyTypeLabel(changes.property.currentValue);
            }

        };

        vm.$onInit = function () {
            vm.thumbnailStyle = createThumbnailStyle(vm.property || {});

            if (vm.property.isDevelopmentLoan) {
                configService.isIsaPublic().then(function (isaPublic) {
                    vm.isaPublic = isaPublic;
                });
            }
        };

        // -- main

    }]
});
})(window, window.angular);