(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.investor', [
    'pp.services.core',
    'pp.services.preference',
    'pp.services.apo',
    'pp.values.form-lists',
    'pp.values.numbers'
]);

var ACCOUNT_NUMBER_REGEXP = /^(\d){7,10}$/;
var US_ACCOUNT_NUMBER_REGEXP = /^[0-9]{4,17}$/;
var SORT_CODE_REGEXP = /^(\d){6}$/;
var BIC_REGEXP = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;
var IBAN_REGEXP = /^[a-zA-z]{2}[0-9]{2}[A-Za-z0-9]{5,30}$/;
var ABA_ROUTING_CODE_REGEXP = /^([0-9]{9})|([0-9]{2}-[0-9]{4}\/[0-9]{4})$/;

var STAGE_FUNDED = 'funded';
var STAGE_INVESTED = 'invested';

var INVESTOR_KIND_ISA = 'isa';
var INVESTOR_KIND_GENERAL = 'general';

var INVESTOR_BETA_ENABLED_PREFERENCE = 'investor.beta.enabled';
var INVESTOR_BETA_ALLOWED_PREFERENCE = 'investor.beta.allowed';

var API_BASE_PATH = '/feapi/r1';
var API_CONTENT_TYPE = 'application/vnd.propertypartner.feapi.auth.v1+json';
var API_INVESTOR_ENDPOINT = '/investor';
var API_INVESTOR_NI_NUMBER_ENDPOINT = '/investor/ni-number';
var API_USER_INVESTORS_ENDPOINT = '/user/investors';
var API_CREATE_SELL_ORDER = '/secondary/sell';
var API_CREATE_BID_ORDER = '/secondary/bid';
var API_REPLACE_BID_ORDER = '/secondary/bid';
var API_CANCEL_SELL_ORDER = '/secondary/cancel';
var API_CANCEL_BID_ORDER = '/secondary/cancel-bid';
var API_INVESTOR_ADDRESS_ENDPOINT = '/investor/address';
var API_INVESTOR_BILLING_ADDRESS_ENDPOINT = '/investor/billing-address';
var API_INVESTOR_ACCOUNTS_ENDPOINT = '/investor/bank-accounts';
var API_INVESTOR_CREATE_ACCOUNT_ENDPOINT = '/investor/account/add/:type';
var API_INVESTOR_HOLDINGS_ENDPOINT = '/investor/holdings/:symbol';
var API_INVESTOR_GOOD_REPUTE_STATEMENT = '/investor/good-repute-statement';
var API_USER_REFERRAL_STATS = '/user/referral-stats';
var API_SOURCE_OF_FUNDS = API_BASE_PATH + '/user/funding-source';
var API_CONFIRM_PLATFORM_USE = API_BASE_PATH + '/investor/confirm-platform-use-intent';

var API_INVESTMENT_MEMORANDUM_URL = API_BASE_PATH + '/investment-memorandum';

var API_INVESTOR_TRANSFER_FUNDS_ENDPOINT = '/investor/account/transfer';

var API_ISA_DECLARATION_ENDPOINT = '/investor/declaration-and-terms';
var API_ISA_AMOUNT_REMAINING_ENDPOINT = '/investor/remaining-isa-amount';

var API_USER_KYC_STATUS_ENDPOINT = '/user/kyc-status';

var INVESTOR_PERFORMANCE_TOTALS_ENDPOINT = '/investor/investment-totals';

var PROPERTY_NOT_TRADABLE_ERROR = 'property.currently.not.tradable';
var PRICE_OUTSIDE_BAND = 'specified.price.outside.band';

var UNCHECKED_STATE = 'UNCHECKED';
var GOJI_SUBMISSION_REQUIRED = 'ENHANCED_VERIFICATION_REQUIRED';
var GOJI_DOCS_STATES = [GOJI_SUBMISSION_REQUIRED];

var REFERRED_STATE = 'REFER';
var PENDING_STATE = 'PENDING';

var ACCOUNT_TYPES = {
    uk: 'ukAccounts',
    us: 'usAccounts',
    iban: 'ibanAccounts',
    international: 'rotwAccounts',
};

/**
 * @ngdoc service
 * @name investorService
 *
 * @todo post-conversion-phase-2 move to a separate module
 *
 * @description
 * Provides methods to invoke the FEAPI `/investor` endpoints.
 */
ngModule.service('investorService', ['$http', '$q', '$window', 'preferenceService', 'ppConfig', 'ppEmitter', 'ppTrack', 'formLists', 'apoService', 'ppBig', 'isaMaxPerYear', 'R', 'ppServerTimeIso', 'ppMoment', function ($http, $q, $window, preferenceService, ppConfig, ppEmitter, ppTrack, formLists, apoService, ppBig, isaMaxPerYear, R, ppServerTimeIso, ppMoment) {

    var __config = ppConfig.get('pp.modules.auth') || {};

    var promiseCache = {};

    // allows setting the stage for anonymous users based on the pp_funnel cookie
    // initializing as object, othwerwise it would polute all tests with a mock call
    var __cookieData = {};

    // stores callbacks per event and triggers them
    // trigger events via emitter.emit('name', arg1, ...)
    var emitter = ppEmitter.create();

    var api = {};

    // adds .on() add off() methods to the service API, but bound to the internal emitter registry
    ppEmitter.mixin(api, emitter);

    api.registerUnauthorised = function () {
        emitter.emit('unauthorised');
    };

    // used on permissions checks, for brevity
    function hasPassedKyc(user) {
        return user.kyc && user.kyc.passed;
    }

    function hasConfirmedPlatformUse(user) {
        var platformUseConfirmationRequired = R.path(['permissions', 'confirmationOfPlatformUseRequired'], user);
        return !platformUseConfirmationRequired;
    }

    function platformUseConfirmationRequired(user) {
        if (hasConfirmedPlatformUse(user)) {
            return false;
        } else {
            var coolingOffPeriodEndDate = R.path(['permissions', 'coolingOffPeriodEndDate'], user);
            if (!coolingOffPeriodEndDate) {
                return false;
            } else {
                return ppMoment(coolingOffPeriodEndDate).isBefore(ppServerTimeIso);
            }

        }

    }

    function isClassifiedUser(user) {
        var permissions = user.permissions || {};
        return user.classification && !permissions.complianceRequired;
    }

    function isRestrictedInvestor(user) {
        return isClassifiedUser(user) && user.classification === 'RetailInvestor';
    }

    function isClassifiedAndKycedUser(user) {
        return isClassifiedUser(user) && hasPassedKyc(user);
    }

    function hasNotDoneKyc(user) {
        return !user.kyc.status;
    }

    function kycMoreInformationRequired(user) {
        return user.kyc.status === REFERRED_STATE.toLowerCase() || user.kyc.status === PENDING_STATE.toLowerCase();
    }

    function isBusiness(user) {
        return user.kyc && user.kyc.business;
    }

    // unest classification.classification
    function normalizeClassification(user) {
        user.classification = (user.classification) ? user.classification.classification : null;
        user.isNonRetail = !!isClassifiedUser(user) && !isRestrictedInvestor(user);
    }

    function hasFunded(user) {
        return user && user.stage && user.stage === STAGE_FUNDED;
    }

    function hasInvested(user) {
        return user && user.stage && user.stage === STAGE_INVESTED;
    }

    function hasFundedOrInvested(user) {
        return hasFunded(user) || hasInvested(user);
    }

    // unest classification.classification
    function normalizeKyc(user) {
        var kycState = user.kycState || {};
        var status = kycState.status;
        delete user.kycState;
        // @todo SOMEDAY why an "if" around status when switch/default would set to 'unknown'
        if (status) {
            switch (status) {
            case 'REFER_ON_ERROR':
                status = 'error';
                break;
            case 'UNCHECKED':
            case PENDING_STATE:
            case REFERRED_STATE:
            case 'PASS':
                status = status.toLowerCase();
                break;
            case 'UK_BUSINESS':
                if (kycState.pending) {
                    status = 'pending';
                    break;
                }
                /* falls through */
                default:
                    status = 'unknown';
            }
            if (kycState.pepMatch && !kycState.pending) {
                status = 'pep';
            }
        }
        kycState.status = status;
        user.kyc = kycState;
    }

    // rename `.investorFinancials` to `.financials`
    // rename `.availableFunds` to `.funds`
    // add grade
    function normalizeFinancials(user) {
        var financials = user.investorFinancials || {};
        delete user.investorFinancials;

        financials.hasInvested = !!financials.hasInvested || false;
        financials.funds = financials.availableFunds || 0;
        financials.totalDeposited = financials.totalDeposited || 0;
        financials.promotions = financials.promotions || [];

        financials.withdrawableFunds = 0;
        financials.autoInvestFunds = 0;
        financials.autoInvestBalance = 0;

        if (financials.fundingAccounts) {

            financials.withdrawableFunds = financials.fundingAccounts.reduce(function (amount, account) {
                return amount + account.withdrawableBalance;
            }, 0);

            financials.manualFunds = financials.fundingAccounts.reduce(function (amount, account) {
                if (account.type === 'CF') {
                    return amount + account.withdrawableBalance;
                } else {
                    return amount;
                }
            }, 0);

            financials.autoInvestFunds = financials.fundingAccounts.reduce(function (amount, account) {
                if (account.type === 'AI') {
                    return amount + account.withdrawableBalance;
                } else {
                    return amount;
                }
            }, 0);

            financials.autoInvestBalance = financials.fundingAccounts.reduce(function (amount, account) {
                if (account.type === 'AI') {
                    return amount + account.balance;
                } else {
                    return amount;
                }
            }, 0);
        }

        delete financials.fundingAccounts;
        delete financials.availableFunds;

        // @todo SEOMDAY delete
        user.grade = Math.ceil(financials.totalDeposited / 100);
        user.financials = financials;
    }

    // makes sure permissions is initialized
    // and funding limit is turned into a pair
    // hasFundingLimit (bool) and fundingLimit (number)
    // and set s
    function normalizePermissions(user) {
        user.permissions = user.permissions || {};
        var permissions = user.permissions;
        // defensive
        // TECH-385 remove permissions.fundingLimit & permissions.allowedFunding
        if (!permissions.fundingLimit) {
            permissions.fundingLimit = {
                fundingLimit: 'Zero'
            };
        }
        switch (permissions.fundingLimit.fundingLimit) {
        case 'NoLimit':
            permissions.hasFundingLimit = false;
            permissions.fundingLimit = null;
            break;
        case 'SystemLimit':
            permissions.hasFundingLimit = true;
            permissions.fundingLimit = __config.sysFundingLimit || 0;
            break;
        case 'Zero':
            /*jshint -W086*/
        default: // defensive
            /*jshint +W086*/
            permissions.hasFundingLimit = true;
            permissions.fundingLimit = 0;
        }
        if (user.permissions.hasFundingLimit) {
            permissions = user.permissions;
            permissions.allowedFunding = permissions.fundingLimit - user.financials.totalDeposited;
            // defensive
            if (permissions.allowedFunding < 0) {
                permissions.allowedFunding = 0;
            }
        }

        if (!user.kyc.status) {
            user.permissions.canSetIdentifiers = false;
        } else {
            user.permissions.canSetIdentifiers = true;
        }

        // TODO REMOVE THIS this should be from backend STUBBED TEMPORARILY

    }

    function normalizeKind(user) {
        if (user.kind === INVESTOR_KIND_ISA) {
            user.isIsa = true;
        }
    }

    function normalizeFlags(user) {
        user.flags = user.flags || {};
    }

    function normalizeStatistics(user) {
        var userStats = {};
        var cookieData = __cookieData || {};
        if (!user.anon) {
            var funnel = user.funnel || {};
            var stats = funnel.statistics || {};
            userStats.primaryInvestmentCounter = parseInt(stats.primary_investment_counter) || 0;
            userStats.secondaryInvestmentCounter = parseInt(stats.secondary_investment_counter) || 0;
            userStats.investmentCounter = userStats.primaryInvestmentCounter + userStats.secondaryInvestmentCounter;
            user.referredBy = funnel.referredBy;
            user.referredByIntroducer = funnel.referredByIntroducer;
        }

        if (user.anon) {
            user.referredBy = cookieData['user.referredBy'] || cookieData['user.r'];
        }

        user.statistics = userStats;
    }

    function setUserStage(user) {
        var stage;
        if (user.anon) {
            // PP-1961 setting the stage for anonymous users based on the pp_funnel cookie
            stage = __cookieData['user.stage'] || 'initial';
        } else if (user.permissions.complianceRequired) {
            stage = 'complianceRequired';
        } else if (!user.classification) {
            stage = 'signup';
        } else if (!user.kyc.status) {
            stage = 'classified';
        } else {
            stage = 'kyc';
            if (user.financials.totalDeposited) {
                stage = STAGE_FUNDED;
            }
            if (user.financials.hasInvested) {
                stage = STAGE_INVESTED;
            }
        }
        user.stage = stage;
    }

    function normalizeUser(user) {
        normalizeClassification(user);
        normalizeKyc(user);
        normalizeFinancials(user);
        normalizePermissions(user);
        normalizeFlags(user);
        normalizeStatistics(user);
        setUserStage(user);
        normalizeKind(user);
    }

    /**
     * @param {string} countryCode
     * @returns {sting}
     */
    function getEndpointByCountyCode(countryCode) {
        if (countryCode === 'GBR') {
            return 'uk';
        } else if (countryCode === 'USA') {
            return 'us';
        } else if (formLists.isEuCountry(countryCode)) {
            return 'international';
        } else if (angular.isDefined(countryCode)) {
            return 'rotw';
        }
    }

    function normaliseHoldingsCurrent(holdings) {
        var yieldOverride = {
            currentYield: 0
        };
        return holdings.map(function (item) {
            return R.mergeRight(item, yieldOverride);
        });
    }

    function getHoldings(urlKey) {

        var urlMapping = {
            pending: '/investor/pending-investments',
            current: '/investor/holdings',
            listed: '/investor/sale-offers',
            sold: API_BASE_PATH + '/investor/dashboard/exited-data',
            exitedLoans: API_BASE_PATH + '/investor/dashboard/exited-debt-data',
            bid: '/investor/bid-offers'
        };

        var endpoint = urlMapping[urlKey];
        var cacheKey = 'investor.holdings.' + urlKey;

        var options = {};
        options.headers = {
            'Content-type': API_CONTENT_TYPE
        };

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, options).then(function (response) {
                var data = response.data || {};

                if (urlKey === 'current') {
                    data = normaliseHoldingsCurrent(response.data);
                }

                emitter.emit(cacheKey + '.updated', data);
                return data;
            }, function (error) {
                delete promiseCache[cacheKey];
                switch (error.status) {
                case 401:
                    // bound in app.js
                    emitter.emit('unauthorised');
                    return $q.reject({
                        reason: 'investor.error.unexpected'
                    });
                case 404:
                    return $q.reject({
                        reason: 'investor.error.no-holdings'
                    });
                default:
                    return $q.reject({
                        reason: 'investor.error.unexpected'
                    });
                }
            });
        }
        return promiseCache[cacheKey];
    }

    function parseSegmentString(segmentsString) {
        var segments = [];
        if (!segmentsString) {
            return segments;
        }

        try {
            segments = JSON.parse(segmentsString);
            if (!angular.isArray(segments)) {
                segments = [];
            }
        } finally {
            return segments;
        }
    }

    /**
     * @ngdoc method
     * @name investorService#setCookieData
     *
     * @description
     * Stores cookie data (pp_funnel) so that when getInvestor() is invoked we can set the stage for anonymous users based on cookie data
     *
     * @note: called from app.js, just before getInvestor()
     *
     * @params {object} cookieData
     */
    api.setCookieData = function (cookieData) {
        __cookieData = cookieData;
    };

    api.getCookieData = function () {
        return angular.copy(__cookieData);
    };

    /**
     * @ngdoc method
     * @name investorService#decorateHeadersWithPassword
     *
     * @description
     * Adds password to request headers
     *
     * @note: called from service/withdrawal.js:
     *
     * @params {object} options
     * @params {string} password
     * @returns {object}
     */
    api.decorateHeadersWithPassword = function (options, password) {
        options = options || {
            headers: {}
        };
        options.headers = options.headers || {};
        options.headers['ppAuthorization'] = password ? btoa(encodeURIComponent(password)) : password;
        return options;
    };

    /**
     * @ngdoc method
     * @name investorService#getInvestor
     *
     * @description
     * Returns a promise that resolves with the investor details for the user currently logged in.
     *
     * Success response:
     * ```
     * {
     *    investorFinancials: {
     *       numberHeld: 248676,           // these are only available if:
     *       numberSold: 529439,           // - user has bought shares in secondary market
     *       propertyCount: 32,            // - OR on made a primary market reservation that has completed
     *       premiumDiscount: -8440.5481,  // .
     *       unrealisedGains: 12314.5401,  // .
     *       rentalIncome: 1139.27,        // .
     *       aggregatedTaxPaid: 39.31,     // .
     *       aggregatedFeesPaid: 4764.11,  // .
     *       cashback: 0.00,               // .
     *       realisedGains: 15528.7938,    // .
     *       fundingAccounts: [
     *          {
     *             id: 22,
     *             number: '2372a43a9ada48d7b128d165f33eba5d',
     *             type: 'CF',
     *             balance: 11413.25,
     *             imvpRunningTotal: 89551.00,
     *             currencyCode: 'GBP'
     *          }
     *       ],
     *       investorReservedFunds: [
     *          {
     *             investorId: 3,
     *             currencyCode: 'GBP',
     *             investment: 999.63
     *          }
     *       ],
     *       totalInvestmentValuation: 114489.39,
     *       totalRentalIncomePcm: 305.331296080851866666666666666666670357,
     *       totalWithdrawn: -3561.00,
     *       totalDeposited: 89461.00
     *    },
     *    investorId: 3,
     *    userId: 3,
     *    firstName: 'Dan',
     *    lastName: 'Gandesha',
     *    primaryEmail: 'TESTdan@londonhouseexchange.com',
     *    onboarded: true,
     *    userVoiceToken: 'Fo13za7Pdg5ln3ZXpJNrHg14GvZ80ZFKammD%2BZblpTDOqZBI0fnmPAClAfRM3OqJuiPOPvY4Lo%2BymtDn8WVhwvBMpC%2FkWXo67IsFUF%2BNobI6RxFGZlhhImjmtnwmdJ74M9r%2BhUzmx6qpisLsMBWlUA%3D%3D',
     *    classification: {
     *       classification: 'RetailInvestor'
     *    },
     *    classificationAttempts: 0,
     *    classificationDate: '2014-11-21',
     *    uniqueIdentifier: 'f4554961-8ab2-42c3-8c1c-40294dc64cc9',
     *    funnel: {},
     *    kycState: {
     *       passed: true,
     *       pending: false,
     *       referred: false,
     *       pepMatch: false,
     *       passWithPep: false,
     *       sysError: false,
     *       business: false,
     *       status: 'PASS',
     *       stage: 'FIRST',
     *       canCheck: false
     *    },
     *    permissions: {
     *       fundingLimit: {
     *          fundingLimit: 'NoLimit'
     *       },
     *       canWithdraw: true,
     *       canInvestPrimary: true,
     *       canInvestSecondary: true,
     *       canSellSecondary: true,
     *
     *       riComplianceRequired: false
     *    }
     * }
     * ```
     * Loads data from: `/feapi/<VERSION>/investor`.
     * Promises are cached, only one request ever made per investor.
     *
     * @returns {Promise}
     */
    api.getInvestor = function () {

        var endpoint = API_BASE_PATH + API_INVESTOR_ENDPOINT;
        var cacheKey = 'investor';

        if (!promiseCache[cacheKey]) {
            var options = {};
            options.headers = {
                'Content-type': API_CONTENT_TYPE
            };

            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                var user = response.data;
                // normalize classification, permissions and funding limit
                normalizeUser(user);

                // note: always loads ALL preferences
                return preferenceService.getPreference('segments').then(function (segmentsString) {
                    user.segments = parseSegmentString(segmentsString);

                    // @todo REMOVE DEBUG CODE
                    // user.permissions.canInvestPrimary = false;
                    // user.permissions.canInvestSecondary = false;
                    // user.permissions.canSellSecondary = false;
                    // user.permissions.canWithdraw = false;
                    // user.permissions.hasFundingLimit = true;
                    // user.permissions.fundingLimit = 500;
                    // user.financials.totalDeposited = 50;
                    // user.financials.funds = 100;
                    // pre-calculate allowed funding

                    // notify subscribers everytime the investor object is (re)loaded
                    emitter.emit('investor-updated', user);

                    return user;
                }, function () {
                    user.segments = [];
                    // notify subscribers everytime the investor object is (re)loaded
                    emitter.emit('investor-updated', user);
                    return user;
                });
            }, function (error) {
                delete promiseCache[cacheKey];
                switch (error.status) {
                case 404:
                    var user = {
                        anon: true
                    };
                    normalizeUser(user);
                    emitter.emit('investor-updated', user);
                    return user;
                default:
                    return $q.reject({
                        reason: 'investor.error.unexpected'
                    });
                }
            });
        }

        return promiseCache[cacheKey];
    };

    api.getUserInvestors = function () {
        var endpoint = API_BASE_PATH + API_USER_INVESTORS_ENDPOINT;
        var cacheKey = 'user';
        if (!promiseCache[cacheKey]) {

            var options = {};
            options.headers = {
                'Content-type': API_CONTENT_TYPE
            };

            promiseCache[cacheKey] = $http.get(endpoint, {}, options)
                .then(function (response) {
                    var user = response.data;
                    emitter.emit('user-updated', user);
                    return user;
                }, function (error) {
                    delete promiseCache[cacheKey];
                    switch (error.status) {
                    case 404:
                        var users = [{
                            anon: true
                        }];
                        return users;
                    default:
                        return $q.reject({
                            reason: 'investor.error.unexpected'
                        });
                    }
                });
        }

        return promiseCache[cacheKey];
    };

    api.escapeFromIsa = function (investor) {
        if (investor.kind === INVESTOR_KIND_ISA) {
            return api.switchToInvestorByKind(INVESTOR_KIND_GENERAL);
        }

        return $q.when(investor);
    };

    // get investor and escape from isa in one method
    api.getGeneralInvestor = function () {
        return api.getInvestor()
            .then(api.escapeFromIsa);
    };

    api.escapeFromIsaForNonIsaInvestments = function (investor, property) {
        if (investor.kind === INVESTOR_KIND_ISA && !property.isIsaEligible) {
            return api.switchToInvestorByKind(INVESTOR_KIND_GENERAL);
        } else {
            return $q.when(investor);
        }

    };

    api.createIsaInvestor = function (declarationToken, termsToken, niNumber) {
        var endpoint = API_BASE_PATH + API_INVESTOR_ENDPOINT;

        var payload = {
            nationalInsuranceNumber: niNumber,
            declarationToken: declarationToken,
            termsToken: termsToken,
            kind: INVESTOR_KIND_ISA
        };

        return $http.post(endpoint, payload).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            case 400:
                return $q.reject({
                    reason: error.data.code
                });
            default:
                return $q.reject({
                    reason: 'investor.create.error.unexpected'
                });
            }
        });
    };

    api.getInvestorNiNumber = function () {
        var endpoint = API_BASE_PATH + API_INVESTOR_NI_NUMBER_ENDPOINT;

        return $http.get(endpoint).then(function (response) {
            var data = response.data || {};
            return data.niNumber;
        }, function (error) {
            switch (error.status) {
            case 404:
                return undefined;
            default:
                return $q.reject({
                    reason: 'investor.ni-number.error.unexpected'
                });
            }
        });

    };

    api.transferFundsToInvestor = function (amount, fromInvestor, toInvestor) {
        var endpoint = API_BASE_PATH + API_INVESTOR_TRANSFER_FUNDS_ENDPOINT;

        var payload = {
            amount: amount,
            from: fromInvestor,
            to: toInvestor
        };

        return $http.post(endpoint, payload).then(function () {
            return true;
        }, function (error) {
            return $q.reject({
                reason: 'investor.transfer-funds.error.unexpected'
            });
        });
    };

    api.isIsaSetup = function (investors) {

        if (!angular.isArray(investors)) {
            return;
        }

        return investors.reduce(function (isSetup, investor) {

            if (!investor || !investor.kind) {
                return isSetup;
            }

            return isSetup || investor.kind === INVESTOR_KIND_ISA;
        }, false);
    };

    api.getIsaAmountRemaining = function () {
        var endpoint = API_BASE_PATH + API_ISA_AMOUNT_REMAINING_ENDPOINT;
        return $http.get(endpoint).then(function (response) {
            return response.data.remainingIsaAmount;
        });
    };

    api.getIsaAmountUsed = function () {
        return api.getIsaAmountRemaining().then(function (remaining) {
            return isaMaxPerYear - remaining;
        });
    };

    api.getInvestorMaximumFundingLimit = function () {
        return api.getInvestor().then(function (investor) {
            if (investor.kind === INVESTOR_KIND_ISA) {
                return api.getIsaAmountRemaining();
            } else {
                return Infinity;
            }
        });
    };

    api.reloadInvestor = function () {
        api.purgeCache('investor$');
        api.purgeCache('user$');
        apoService.purgeCache('get\-apo$');
        api.getUserInvestors();
        return api.getInvestor();
    };

    api.switchToInvestor = function (investorId) {
        var endpoint = API_BASE_PATH + API_INVESTOR_ENDPOINT;
        var payload = {
            investorId: investorId
        };

        return api.getInvestor().then(function (investor) {

            if (investor.investorId === investorId) {
                return investor;
            }

            return $http.put(endpoint, payload)
                .then(api.reloadInvestor, api.getInvestor)
                .then(function (investor) {
                    // clear all investor holdings
                    api.purgeCache('investor.holdings');
                    ppTrack.setContext('investor-kind', investor.kind);
                    return investor;
                });
        });

    };

    api.switchToInvestorByKind = function (kind) {

        return api.getUserInvestors().then(function (users) {

            if (!angular.isArray(users)) {
                $q.reject({
                    reason: 'cannot-switch.no-investors'
                });
            }

            var investorId = users.reduce(function (id, user) {

                if (id) {
                    return id;
                }

                if (user.kind === kind) {
                    return user.investorId;
                }

            }, undefined);

            if (investorId) {
                return api.switchToInvestor(investorId);
            } else {
                $q.reject({
                    reason: 'cannot-switch.no-investor-with-supplied-kind'
                });
            }

        });
    };

    api.getIsaDeclarationKey = function () {
        var endpoint = API_BASE_PATH + API_ISA_DECLARATION_ENDPOINT;

        return $http.get(endpoint).then(function (response) {
            return response.data;
        }, function () {
            return $q.reject({
                reason: 'declaration-key.error.unexpected'
            });
        });
    };

    api.isExtraUserDataRequired = function (user) {
        if (!angular.isObject(user) || user.anon || !user.classification) {
            return false;
        } else if (user.needsReclassification) {
            return true;
        } else {
            return false;
        }

    };

    /**
     * @ngdoc method
     * @name investorService#getInvestorAddress
     *
     * @description
     * Returns a promise that resolves with the investor address for the user currently logged in.
     *
     * Success response:
     * ```
     * {
     *   'investorId': '<STRING>',
     * }
     * ```
     * Loads data from: `/feapi/<VERSION>/investor/address`.
     * Promises are cached, only one request ever made per investor.
     *
     * @returns {Promise}
     */
    api.getInvestorAddress = function () {

        var endpoint = API_BASE_PATH + API_INVESTOR_ADDRESS_ENDPOINT;
        var cacheKey = 'investor.address';

        if (!promiseCache[cacheKey]) {

            var options = {};
            options.headers = {
                'Content-type': API_CONTENT_TYPE
            };

            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                return response.data;
            }, function (error) {
                delete promiseCache[cacheKey];
                switch (error.status) {
                case 404:
                    //@todo: post-conversion-phase-2 fix KYC pending case not having an address
                    return {};
                default:
                    return $q.reject({
                        reason: 'investor.error.unexpected'
                    });
                }
            });
        }

        return promiseCache[cacheKey];
    };

    api.doesInvestorHaveUKAddress = function () {
        return api.getInvestorAddress().then(function (address) {
            return R.propEq('countryCode', 'GBR', address);
        });
    };

    /*
    *
    *
    * GOJI STATUS MODEL
    * Note lastFailure can be null
    * {
        "documentsApproved": [
            "string"
        ],
        "documentsPendingApproval": [
            "string"
        ],
        "documentsRequired": [
            "string"
        ],
        "lastFailure": {
            "failureDate": "string",
            "rejectedDocuments": [
                {
                    "notes": "string",
                    "reason": "string",
                    "type": "string"
                }
            ]
        },
        "status": "string"
    * }
    *
    *

    */
    function normaliseKycStatus(status) {
        var equalsTrue = R.equals(true);
        var equalsStatus = R.equals(status.status);
        var allTrue = R.all(equalsTrue);
        var anyTrue = R.any(equalsTrue);
        var isNotEmpty = R.compose(R.not, R.isEmpty);

        var documentsRequired = R.or(R.path(['documentsRequired'], status), []);
        var hasDocumentsRequired = isNotEmpty(documentsRequired);
        var documentsSubmitted = isNotEmpty(status.documentsPendingApproval);
        var documentResubmissionRequired = R.hasPath(['lastFailure', 'rejectedDocuments'], status);

        var config = {
            isPendingApproval: allTrue([documentsSubmitted, R.not(hasDocumentsRequired)]),
            hasDocumentsRequired: hasDocumentsRequired,
            proofOfAddressRequired: R.includes('PROOF_OF_ADDRESS', documentsRequired),
            proofOfIdentityRequired: R.includes('PROOF_OF_IDENTITY', documentsRequired),
            documentResubmissionRequired: documentResubmissionRequired,
            isPassed: anyTrue([equalsStatus('ELECTRONICALLY_VERIFIED'), equalsStatus('ENHANCED_VERIFIED')])
        };

        return R.mergeLeft(status, config);
    }

    api.getUsetKycStatusDetails = function () {
        var endpoint = API_BASE_PATH + API_USER_KYC_STATUS_ENDPOINT;

        return $http.get(endpoint).then(function (res) {
            return normaliseKycStatus(res.data.kyc);
        }, function () {
            return $q.reject({
                reason: 'user.kyc-details.error.unexpected'
            });
        });
    };

    /**
     * @ngdoc method
     * @name investorService#getInvestorBillingAddress
     *
     * @description
     * Returns a promise that resolves with the investor address for the user currently logged in.
     *
     * Success response:
     * ```
     * {
     *   'houseNameOrNumber': '<STRING>',
     *   'street': '<STRING>',
     *   'townOrCity': '<STRING>',
     *   'countyOrState': 'Option[<STRING>]',
     *   'postcode': '<STRING>',
     *   'countryCode': '<STRING>'
     * }
     * ```
     * Loads data from: `/feapi/<VERSION>/investor/billing-address`.
     * Promises are cached, only one request ever made per investor.
     *
     * @returns {Promise}
     */
    api.getInvestorBillingAddress = function () {

        var endpoint = API_BASE_PATH + API_INVESTOR_BILLING_ADDRESS_ENDPOINT;
        var cacheKey = 'investor.billingAddress';

        if (!promiseCache[cacheKey]) {

            var options = {};
            options.headers = {
                'Content-type': API_CONTENT_TYPE
            };

            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                return response.data;
            }, function (error) {
                delete promiseCache[cacheKey];
                switch (error.status) {
                case 404:
                    return null;
                default:
                    return $q.reject({
                        reason: 'investor.error.unexpected'
                    });
                }
            });
        }

        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name investorService#putInvestorBillingAddress
     *
     * @description
     * Upserts investor billing address and returns a promise
     *
     * Payload:
     * ```
     * {
     *   'houseNameOrNumber': '<STRING>',
     *   'street': '<STRING>',
     *   'townOrCity': '<STRING>',
     *   'countyOrState': 'Option[<STRING>]',
     *   'postcode': '<STRING>',
     *   'countryCode': '<STRING>'
     * }
     * ```
     *
     * Success response:
     * ```
     * {}
     * ```
     *
     * @returns {Promise}
     */
    api.putInvestorBillingAddress = function (address) {

        var endpoint = API_BASE_PATH + API_INVESTOR_BILLING_ADDRESS_ENDPOINT;

        var options = {};
        // @todo expecting content type application/json or text/json
        // @todo should be expecting application/vnd.propertypartner.feapi.auth.v1+json
        options.headers = {
            'Content-type': 'application/json'
        };

        return $http.put(endpoint, address).then(function (response) {
            return response.data;
        }, function (error) {
            switch (error.status) {
            default:
                return $q.reject({
                    reason: 'investor.error.unexpected'
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name investorService#getInvestorAccounts
     *
     * @description
     * Returns a promise that resolves with the investor bank accounts for the user currently logged in.
     *
     *
     * @returns {Promise}
     */
    api.getInvestorActiveAccounts = function () {

        var endpoint = API_BASE_PATH + API_INVESTOR_ACCOUNTS_ENDPOINT;
        var cacheKey = 'investor.accounts';

        if (!promiseCache[cacheKey]) {

            var options = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            };
            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                var data = response.data || [];
                return data.map(function (account) {
                    account.isVerified = account.verificationStatus === 'VERIFIED';
                    return account;
                });
            }, function (error) {

                delete promiseCache[cacheKey];

                return $q.reject({
                    reason: 'get-investor-accounts.error.unexpected'
                });
            });
        }

        return promiseCache[cacheKey];

    };

    /**
     * @ngdoc method
     * @name investorService#createAccount
     *
     * @description
     * Creates a bank account the user can later use to withdraw from his property partner account.
     *
     * Success response:
     * ```
     * {
     *   outcome: "Success"
     * }
     * ```
     *
     * Error response:
     * ```
     * {
     *   outcome: "Duplicate" // or Denied, ot something else
     * }
     *
     * ```
     * Posts data to: `/investor/account/add/:type`.
     *
     * @param {object} account
     * @returns {Promise}
     */
    api.createAccount = function (account, password) {

        var type = getEndpointByCountyCode(account.countryCode);

        if (!type) {
            return $q.reject({
                reason: 'investor.error.invalid-account'
            });
        }

        // backend not resilient to parasite attributes in account types where they're not relevant
        for (var prop in account) {
            if (!account[prop]) {
                delete account[prop];
            }
        }

        var endpoint = API_INVESTOR_CREATE_ACCOUNT_ENDPOINT.replace(':type', type);

        var options = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        options = api.decorateHeadersWithPassword(options, password);

        return $http.post(endpoint, account, options).then(function (response) {
            switch (response.data.outcome) {
            case 'Success':
                return true;
            case 'Duplicate':
                return $q.reject({
                    reason: 'investor.error.duplicate-account'
                });
            case 'Denied':
                return $q.reject({
                    reason: 'investor.error.max-accounts'
                });
            default:
                return $q.reject({
                    reason: 'investor.error.unexpected'
                });
            }
        }, function (error) {
            switch (error.status) {
            case 403:
                return $q.reject({
                    reason: 'investor.error.unauthorised'
                });
            default:
                return $q.reject({
                    reason: 'investor.error.unexpected'
                });
            }
        });
    };

    api.updateBankAccount = function (account, password) {

        // backend not resilient to parasite attributes in account types where they're not relevant
        for (var prop in account) {
            if (!account[prop]) {
                delete account[prop];
            }
        }

        var endpoint = API_BASE_PATH + API_INVESTOR_ACCOUNTS_ENDPOINT;

        var payload = angular.copy(account);
        payload.active = true;

        var options = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        return $http.post(endpoint, payload, options).then(function (response) {
            return true;
        }, function (error) {
            switch (error.status) {
            case 403:
                return $q.reject({
                    reason: 'investor.error.unauthorised'
                });
            default:
                return $q.reject({
                    reason: 'investor.error.unexpected'
                });
            }
        });
    };

    // This is not used at the moment might be reinstated
    // need to resolve issues with readding bank accounts
    // with same display name and account number after deletion
    api.deleteBankAccount = function (account) {

        var endpoint = API_BASE_PATH + API_INVESTOR_ACCOUNTS_ENDPOINT + '/' + account.bankAccountType + '/' + account.id;

        return $http.delete(endpoint, {}).then(function (response) {
            return true;
        }, function (error) {
            switch (error.status) {
            case 403:
                return $q.reject({
                    reason: 'investor.error.unauthorised'
                });
            default:
                return $q.reject({
                    reason: 'investor.error.unexpected'
                });
            }
        });
    };

    function getPriceLimitsFromErrorDetails(details) {
        return details.reduce(function (match, detail) {
            if (detail.maxPrice) {
                match['maxPrice'] = detail.maxPrice;
            }

            if (detail.minPrice) {
                match['minPrice'] = detail.minPrice;
            }

            return match;
        }, {});
    }

    /**
     * @ngdoc method
     * @name investorService#createSellOrder
     *
     * @description
     * Creates a sell order for
     *
     * Example request:
     * ```
     * {
     *   symbol: <STRING>,
     *   numberOffered: <NUMBER>,
     *   askPrice: <NUMBER>,
     * }
     * ```
     *
     * Success response:
     * ```
     * {
     *   success: true
     * }
     * ```
     *
     * Error response:
     * ```
     * {
     *   code: '<STRING>',
     *   message: '<STRING>',
     *   details: [{
     *     kind: '<STRING>'
     *     field: '<STRING>'
     *     code: '<STRING>'
     *     message: '<STRING>'
     *   }]
     * }
     * ```
     * @param {string} symbol
     * @param {number} numberOffered
     * @param {number} askPrice
     * @returns {boolean}
     * @TOOD document api in yaml -> post-conversion-phase-2
     */
    api.createSellOrder = function (symbol, numberOffered, askPrice, grecaptchaResponse) {

        var endpoint = API_BASE_PATH + API_CREATE_SELL_ORDER;
        var payload = {
            symbol: symbol,
            numberOffered: numberOffered,
            askPrice: askPrice
        };

        if (grecaptchaResponse) {
            payload.recaptchaResponse = grecaptchaResponse;
        }

        return $http.post(endpoint, payload).then(function (response) {
            api.purgeCache('investor.holdings.listed');
            api.purgeCache('investor.holdings.current');
            api.purgeCache('investor.holdings.' + symbol);
            return true;
        }, function (error) {
            switch (error.status) {
            case 422:
                return $q.reject({
                    reason: error.data.code
                });
            case 400:
                var errorReason = 'secondary.offer.sell.json.invalid';
                var rejection = {};
                if (error.data && (error.data.code === PROPERTY_NOT_TRADABLE_ERROR || error.data.code === PRICE_OUTSIDE_BAND)) {
                    errorReason = error.data.code;
                }

                rejection.reason = errorReason;

                if (error.data && error.data.details && errorReason === PRICE_OUTSIDE_BAND) {
                    rejection.priceLimits = getPriceLimitsFromErrorDetails(error.data.details);
                }

                return $q.reject(rejection);
            case 401:
                return $q.reject({
                    reason: 'auth.error.not.authenticated'
                });
            case 404:
                return $q.reject({
                    reason: 'secondary.property.not.found'
                });
            default:
                return $q.reject({
                    reason: 'secondary.offer.sell.unexpected'
                });
            }

        });
    };

    function handleCreateBidOrderSuccess(response) {
        if (response.data.details && response.data.details[0]) {
            var details = response.data.details[0];
            var amountInvested = details.total;
            var investmentCost = details.total + details.fees + details.taxes;
            var tradeObject = {};
            var trades = details.trades;
            var tradesGroupedByPricePoint = [];
            var averageTradedPrice = null;

            for (var ix = 0; ix < trades.length; ix++) {
                tradeObject[trades[ix].price] = tradeObject[trades[ix].price] || {
                    price: trades[ix].price,
                    unitsTraded: 0
                };
                tradeObject[trades[ix].price].unitsTraded += trades[ix].unitsTraded;

            }

            for (var key in tradeObject) {
                tradeObject[key].amountInvested = tradeObject[key].unitsTraded * tradeObject[key].price;
                tradesGroupedByPricePoint.push(tradeObject[key]);
            }

            var totalShares = trades.reduce(function (shares, trade) {
                return shares + trade.unitsTraded;
            }, 0);

            if (totalShares) {
                averageTradedPrice = amountInvested / totalShares;
            }

            return {
                bidId: details.id,
                code: response.data.code,
                amountInvested: amountInvested,
                investmentCost: investmentCost,
                averageTradedPrice: averageTradedPrice,
                trades: tradesGroupedByPricePoint
            };
        } else {
            return {
                amountInvested: 0,
                code: response.data.code
            };
        }
    }

    api.createBidOrder = function (symbol, numberOffered, askPrice, recaptchaResponse) {

        var endpoint = API_BASE_PATH + API_CREATE_BID_ORDER;
        var payload = {
            symbol: symbol,
            numberOffered: numberOffered,
            askPrice: askPrice
        };

        if (recaptchaResponse) {
            payload.recaptchaResponse = recaptchaResponse;
        }

        return $http.post(endpoint, payload).then(function (response) {
            api.purgeCache('investor.holdings.bid');
            api.purgeCache('investor.holdings.current');
            api.purgeCache('investor.holdings.' + symbol);
            return handleCreateBidOrderSuccess(response);
        }, function (error) {
            var code = error.data && error.data.code ? error.data.code : 'secondary.offer.bid.unexpected';
            var rejection = {
                reason: code
            };

            if (error.data && error.data.details && code === PRICE_OUTSIDE_BAND) {
                rejection.priceLimits = getPriceLimitsFromErrorDetails(error.data.details);
            }

            return $q.reject(rejection);

        });
    };

    /**
     * @ngdoc method
     * @name investorService#cancelSellOrder
     *
     * @description
     * Cancels a sell order
     *
     * Example request:
     * ```
     * {
     *   symbol: <STRING>,
     *   offerId: <STRING>
     * }
     * ```
     *
     * Success response:
     * ```
     * {
     *   success: true
     * }
     * ```
     *
     * Error response:
     * ```
     * {
     *   code: '<STRING>',
     *   message: '<STRING>',
     *   details: [{
     *     kind: '<STRING>'
     *     field: '<STRING>'
     *     code: '<STRING>'
     *     message: '<STRING>'
     *   }]
     * }
     * ```
     * @param {String} symbol
     * @param {String} offerId
     * @TOOD document api in yaml -> post-conversion-phase-2
     */
    api.cancelSellOrder = function (symbol, offerId) {

        var endpoint = API_BASE_PATH + API_CANCEL_SELL_ORDER;
        var payload = {
            symbol: symbol,
            offerId: offerId
        };

        return $http.post(endpoint, payload).then(function (response) {
            api.purgeCache('investor.holdings.listed');
            api.purgeCache('investor.holdings.' + symbol);
            return true;
        }, function (error) {
            switch (error.status) {
            case 422:
            case 400:
            case 401:
            case 404:
                return $q.reject({
                    reason: error.data.code
                });
            default:
                return $q.reject({
                    reason: 'secondary.offer.cancel.unexpected'
                });
            }

        });
    };

    /**
     * @ngdoc method
     * @name investorService#cancelBidOrder
     *
     * @description
     * Cancels a bid
     *
     * Example request:
     * ```
     * {
     *   symbol: <STRING>,
     *   offerId: <STRING>
     * }
     * ```
     *
     * Success response:
     * ```
     * {
     *   success: true
     * }
     * ```
     *
     * Error response:
     * ```
     * {
     *   code: '<STRING>',
     *   message: '<STRING>',
     *   details: [{
     *     kind: '<STRING>'
     *     field: '<STRING>'
     *     code: '<STRING>'
     *     message: '<STRING>'
     *   }]
     * }
     * ```
     * @param {String} symbol
     * @param {String} bidId
     */
    api.cancelBidOrder = function (symbol, bidId, revision) {

        var endpoint = API_BASE_PATH + API_CANCEL_BID_ORDER;
        var payload = {
            symbol: symbol,
            offerId: bidId
        };

        if (revision) {
            payload.revision = revision;
        }

        return $http.post(endpoint, payload).then(function (response) {
            api.purgeCache('investor.holdings.bid');
            api.purgeCache('investor.holdings.' + symbol);
            return true;
        }, function (error) {
            switch (error.status) {
            case 422:
            case 400:
            case 401:
            case 404:
            case 409:
                return $q.reject({
                    reason: error.data.code
                });
            default:
                return $q.reject({
                    reason: 'secondary.offer.cancel.unexpected'
                });
            }

        });
    };

    /**
     * @ngdoc method
     * @name investorService#getInvestorHoldingsByProperty
     *
     * @description
     * Given one property symbol, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     * ```
     * {
     *   units: <NUMBER>,
     *   offered: <NUMBER>,
     *   incomeReceived: <NUMBER>,
     *   aggregateFeesPaid: <NUMBER>,
     *   aggregateTaxPaid: <NUMBER>,
     *   avgUnitPurchasePrice: <NUMBER>,
     *   aggregateTaxPaid: <NUMBER>,
     *   aggregateFeesPaid: <NUMBER>,
     *   avgUnitPurchasePrice: <NUMBER>
     * }
     * ```
     *
     * Loads data from: `/feapi/<VERSION>/investor/holdings/:symbol`.
     * Promises are cached, only one request ever made per property.
     *
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getInvestorHoldingsByProperty = function (symbol) {

        var endpoint = API_INVESTOR_HOLDINGS_ENDPOINT.replace(':symbol', symbol);
        var cacheKey = 'investor.holdings.' + symbol;

        var options = {};
        options.headers = {
            'Content-type': API_CONTENT_TYPE
        };

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, options).then(function (response) {
                return response.data;
            }, function (error) {
                delete promiseCache[cacheKey];
                switch (error.status) {
                case 404:
                    return $q.reject({
                        reason: 'investor.error.no-shares'
                    });
                default:
                    return $q.reject({
                        reason: 'investor.error.unexpected'
                    });
                }
            });
        }
        return promiseCache[cacheKey];
    };

    api.getBetaPreferencesForInvestor = function () {
        var promises = {
            isBetaAllowed: preferenceService.getPreference(INVESTOR_BETA_ALLOWED_PREFERENCE),
            isBetaEnabled: preferenceService.getPreference(INVESTOR_BETA_ENABLED_PREFERENCE)
        };

        return $q.all(promises);
    };

    /**
     * @ngdoc method
     * @name investorService#getInvestorPerformanceTotals
     *
     * @description
     * Returns data for the investor's performance totals
     *
     * @returns {Promise}
     *
     * @todo handle rejections, purge rejections from cache
     */
    api.getInvestorPerformanceTotals = function () {
        var cacheKey = 'dashboard.graph';
        var endpoint = INVESTOR_PERFORMANCE_TOTALS_ENDPOINT;

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (response) {
                return response.data;
            });
        }

        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name investorService#getPrimaryHolding
     *
     * @description
     * Given one property symbol, returns a promise that resolves with the investor's pending holdings for the user currently logged in.
     *
     * Success response:
     * ```
     * {
     *   units: <NUMBER>,
     *   offered: <NUMBER>,
     *   incomeReceived: <NUMBER>,
     *   aggregateFeesPaid: <NUMBER>,
     *   aggregateTaxPaid: <NUMBER>,
     *   avgUnitPurchasePrice: <NUMBER>,
     *   aggregateTaxPaid: <NUMBER>,
     *   aggregateFeesPaid: <NUMBER>,
     *   avgUnitPurchasePrice: <NUMBER>
     * }
     * ```
     *
     * Loads data from: `/feapi/<VERSION>/investor/pending-investments`.
     * Promises are cached, only one request ever made per property.
     *
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getPrimaryHolding = function (propertySymbol) {
        return getHoldings('pending').then(function (holdings) {
            for (var ix = 0; ix < holdings.length; ix++) {
                if (holdings[ix].symbol === propertySymbol) {
                    return holdings[ix];
                }
            }
            // if symbol not found in results, return empty object
            return {};
        });
    };

    /**
     * @ngdoc method
     * @name investorService#getInvestorPropertyUnits
     *
     * @description
     * Given one property symbol and isPrimary, returns a promise that resolves with the number of units the investor has in that property
     *
     * Calls api.getPrimaryHolding or api.getInvestorHoldingsByProperty
     *
     * @parm {Boolean} isPrimary
     * @parm {string} symbol
     * @returns {Promise}
     */
    api.getInvestorPropertyUnits = function (isPrimaryOrPreorder, symbol) {
        if (isPrimaryOrPreorder) {
            return api.getPrimaryHolding(symbol).then(function (holdings) {
                return holdings.units || 0;
            });
        } else {
            return api.getInvestorHoldingsByProperty(symbol).then(function (holdings) {
                return holdings.units;
            }, function (error) {
                if (error.reason === 'investor.error.no-shares') {
                    return 0;
                } else {
                    return $q.reject(error);
                }
            });
        }
    };

    /**
     * @ngdoc method
     * @name investorService#setInvestorGoodRepute
     *
     * @description
     * Set good repute statemnt preference to true, so user can be tracked as being of good repute, MTF requirement
     *
     * @returns {Promise}
     */
    api.setInvestorGoodRepute = function () {

        var payload = {
            agreed: true
        };

        var endpoint = API_BASE_PATH + API_INVESTOR_GOOD_REPUTE_STATEMENT;

        return $http.post(endpoint, payload).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            case 400:
                return $q.reject({
                    reason: 'investor.good-repute-statement.invalid-payload'
                });
            case 401:
                return $q.reject({
                    reason: 'auth.error.not.authenticated'
                });
            default:
                return $q.reject({
                    reason: 'investor.good-repute-statement.unexpected'
                });
            }
        });
    };

    api.getUserReferralStats = function () {
        var endpoint = API_BASE_PATH + API_USER_REFERRAL_STATS;

        var cacheKey = 'user.referral-stats';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (res) {
                return res.data;
            }, function () {
                return null;
            });
        }

        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name investorService#getHoldings
     *
     * @description
     * Given one url key, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @returns {Promise}
     */
    api.getHoldings = getHoldings;

    /**
     * @ngdoc method
     * @name investorService#getHoldingsPending
     *
     * @description
     * Given one url key, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @returns {Promise}
     */
    api.getHoldingsPending = function () {
        return getHoldings('pending').then(function (holdings) {

            if (!angular.isArray(holdings)) {
                return;
            }

            return holdings.map(function (holding) {
                holding.feesPaid = Number(ppBig(holding.units).times(holding.unitPrice).times(holding.feeRate));
                return holding;
            });
        });
    };

    /**
     * @ngdoc method
     * @name investorService#getHoldingsCurrent
     *
     * @description
     * Given one url key, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @returns {Promise}
     */
    api.getHoldingsCurrent = function () {
        return getHoldings('current').then(normaliseHoldingsCurrent);
    };

    function normaliseHoldingsListed(holding) {
        var purchasePrice = ppBig(holding.avgUnitPurchasePrice)
            .times(holding.unitsOffered);

        var transactionCosts = ppBig(holding.aggregateFeesPaid)
            .plus(holding.aggregateTaxPaid);

        var gainLoss = ppBig(holding.saleValue).minus(purchasePrice).minus(transactionCosts);

        holding.totalCost = Number(purchasePrice.plus(transactionCosts));
        holding.gainLoss = Number(gainLoss);
        holding.totalReturn = Number(gainLoss.plus(holding.aggregateRentalIncome));

        return holding;
    }

    /**
     * @ngdoc method
     * @name investorService#getHoldingsListed
     *
     * @description
     * Given one url key, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @returns {Promise}
     */
    api.getHoldingsListed = function () {
        return getHoldings('listed').then(function (items) {
            return items.map(normaliseHoldingsListed);
        });
    };

    api.getHoldingsListedForProperty = function (symbol) {
        return api.getHoldingsListed().then(function (items) {
            return items.filter(function (item) {
                return item.propertySymbol === symbol;
            });
        });
    };

    /**
     * @ngdoc method
     * @name investorService#getHoldingsSold
     *
     * @description
     * Given one url key, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @param {string} urlKey
     * @returns {Promise}
     */
    api.getHoldingsSold = function () {
        return getHoldings('sold');
    };

    /**
     * @ngdoc method
     * @name investorService#getExitedDebt
     *
     * @description
     * Given one url key, returns a promise that resolves with the exited debt holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @param {string} urlKey
     * @returns {Promise}
     */
    api.getExitedLoans = function () {
        return getHoldings('exitedLoans');
    };

    /**
     * @ngdoc method
     * @name investorService#getBids
     *
     * @description
     * Given one url key, returns a promise that resolves with the investor holdings for the user currently logged in.
     *
     * Success response:
     *
     * Promises are cached, only one request ever made per bucket.
     *
     * @param {string} urlKey
     * @returns {Promise}
     */
    api.getBids = function () {
        return getHoldings('bid');
    };

    api.setSourceOfFunds = function (intent, source) {
        var endpoint = API_SOURCE_OF_FUNDS;
        var payload = {
            fundingSource: source,
            estimatedAmount: intent
        };

        return $http.post(endpoint, payload).then(function () {
            return true;
        }, function () {
            return $q.reject({
                reason: 'set-source-of-funds.error.unexpected'
            });
        });
    };

    api.getBidsForProperty = function (symbol) {
        return api.getBids().then(function (bids) {
            return bids.filter(function (bid) {
                return bid.symbol === symbol;
            });
        });
    };

    api.getLinkForInvestmentMemorandum = function () {
        return $http.get(API_INVESTMENT_MEMORANDUM_URL).then(function (res) {
            return res.data.url;
        }, function () {
            return $q.reject({
                reason: 'investment-memorandum.error.unexpected'
            });
        });
    };

    /**
     * @ngdoc method
     * @name investorService#purgeCache
     *
     * @description
     * Purges cached promises of requests made for the investor.
     * Ex: `investorService.purgeCache('investor.holdings')` will purge all holdings cached so far.
     *
     * @param {string} pattern If provide with no arguments, an empty string or `.*`, purges everything.
     */
    api.purgeCache = function (pattern) {
        if (pattern) {
            pattern = new RegExp(pattern);
        }
        for (var prop in promiseCache) {
            if (!pattern || pattern.test(prop)) {
                delete promiseCache[prop];
            }
        }
    };

    // -- tracking

    /**
     * @ngdoc method
     * @name investorService#trackUserContext
     *
     * @description
     * Given a user obejct, returns the relevant attributes to use in analytics/tracking
     * Allows external code to consistenly track user context after resuming a session or login
     *
     * @parm {Object} user
     * @parm {Object} context
     */
    api.getUserTrackContext = function (user) {
        var context = {};
        context['user.stage'] = user.stage;
        if (!user.anon) {
            var funnel = user.funnel || {};
            context['user.email'] = user.primaryEmail;
            context['user.name'] = (user.firstName + ' ' + user.lastName).trim();
            context['user.classification'] = user.classification || 'none';
            context['user.kyc'] = user.kyc.status || 'none';
            context['user.type'] = user.kyc.status ? (user.kyc.business ? 'business' : 'individual') : 'unknown';
            // context['user.country'] = user.... @todo post-conversion-phase-2 track user country
            // context['user.segment'] = user.segment @todo post-conversion-phase-2 track user segment
            context['user.funds'] = user.financials.funds;
            // @todo conversion-phase-2 update funnel data
            context['user.initialReferrer'] = funnel.initialReferrer;
            context['user.referredBy'] = funnel.referredBy;
            context['user.referredByIntroducer'] = funnel.referredByIntroducer;
            context['user.investor.id'] = user.investorId;
            context['user.id'] = user.userId;
            user.segments.forEach(function (segment) {
                context['user.segment.' + segment] = true;
            });
        }
        return context;
    };

    // -- permissions

    /**
     * @ngdoc method
     * @name investorService#isUserAllowedToQuote
     *
     * @description
     * Given a user object and an isPrimaryProperty flag, determines if user is allowed to get a quote.
     * On primary quotes, we allow anonymous users and users that haven't classified and kyced yet.
     * On secondary quotes, we don't allow anonymous users, but we allow users that haven't kyced.
     *
     * @param {object} user
     * @param {boolean} isPrimaryProperty
     * @returns {boolean}
     */
    api.isUserAllowedToQuote = function (user, isPrimaryProperty) {

        var kyc = user.kyc || {};

        // verbose for the sake of clarity
        if (isPrimaryProperty && !isClassifiedAndKycedUser(user) && !kyc.pepMatch) {
            return true;
        } else if (!isPrimaryProperty && !user.anon && !isClassifiedAndKycedUser(user) && !user.kyc.pepMatch) {
            return true;
        } else {
            return api.isUserAllowedToInvest(user, isPrimaryProperty);
        }
    };

    /**
     * @ngdoc method
     * @name investorService#isUserAllowedToInvest
     *
     * @description
     * Given a user object and an offer object, determines if user is allowed to invest.
     *
     * @param {object} user
     * @param {boolean} isPrimaryProperty
     * @returns {boolean}
     */
    api.isUserAllowedToInvest = function (user, isPrimaryProperty) {
        // defensive
        var permissions = user.permissions || {};
        // verbose for the sake of clarity
        if (!isClassifiedAndKycedUser(user)) {
            return false;
        } else if (isPrimaryProperty && !permissions.canInvestPrimary) {
            return false;
        } else if (!isPrimaryProperty && !permissions.canInvestSecondary) {
            return false;
        }
        return true;
    };

    /**
     * @ngdoc method
     * @name investorService#isUserAllowedToSell
     *
     * @description
     * Given a user object, determines if user is allowed to sell shares.
     *
     * @param {object} user
     * @returns {boolean}
     */
    api.isUserAllowedToSell = function (user) {
        // defensive
        var permissions = user.permissions || {};
        // verbose for the sake of clarity
        if (!isClassifiedAndKycedUser(user)) {
            return false;
        } else if (!permissions.canSellSecondary) {
            return false;
        }
        return true;
    };

    /**
     * @ngdoc method
     * @name investorService#isUserAllowedToWithdraw
     *
     * @description
     * Given a user object, determines if user is allowed to withdraw money.
     *
     * @param {object} user
     * @returns {boolean}
     */
    api.isUserAllowedToWithdraw = function (user) {
        // defensive
        var permissions = user.permissions || {};
        // verbose for the sake of clarity
        if (!isClassifiedAndKycedUser(user)) {
            return false;
        } else if (!permissions.canWithdraw) {
            return false;
        }
        return true;
    };

    /**
     * @ngdoc method
     * @name investorService#hasAddress
     *
     * @description
     * Given a user object, determines if user has an address.
     * This is to avoid failed request to /investor/address blocking when reloading the user after a kyc.
     *
     * @param {object} user
     * @returns {boolean}
     */
    api.hasAddress = function (user) {
        var kyc = user.kyc;
        // defensive
        if (!user.anon && kyc.status) {
            return true;
        }
    };

    /**
     * @ngdoc method
     * @name investorService#nationalityNotRequired
     *
     * @description
     * Given a user object, and if nationality is already set.
     * Is nationality required before proceeding to fund or invest.
     *
     *
     * @param {boolean} isNationalitySet
     * @param {object} user
     * @returns {boolean}
     */
    api.nationalityNotRequired = function (isNationalitySet, user) {
        return isNationalitySet || api.isBusiness(user) || api.hasFundedOrInvested(user);
    };

    /**
     * @ngdoc method
     * @name investorService#hasActiveRff
     *
     * @description
     *
     * @param {object} user
     * @returns {boolean}
     */
    api.hasActiveRff = function (user) {

        var hasRff = false;
        user.financials.promotions.forEach(function (promotion) {
            if (promotion.typeOf === 'RFF' && promotion.end > $window.pp.serverTime.getTime()) {
                hasRff = true;
            }
        });

        return hasRff;
    };

    api.confirmPlatformUse = function (confirmed) {
        var path = API_CONFIRM_PLATFORM_USE;
        var payload = {
            confirmed: confirmed
        };

        return $http.post(path, payload);
    };

    /**
     * @ngdoc method
     * @name investorService#hasWithdrawRestriction
     *
     * @description
     *
     * @param {object} user
     * @returns {boolean}
     */
    api.hasWithdrawRestriction = function (user) {
        return user.financials.funds > user.financials.manualFunds;
    };

    /**
     * @ngdoc method
     * @name investorService#getAccountTypeByCountyCode
     *
     * @description
     * Given a country code (3 chars) returns the account type, as used in the api endpoint `/investor/account/add/:type`.
     * See also {@link investorService#createAccount}.
     *
     * @param {string} countryCode
     * @returns {sting}
     */
    api.getAccountTypeByCountyCode = function (countryCode) {
        if (countryCode === 'GBR') {
            return 'UK';
        } else if (countryCode === 'USA') {
            return 'US';
        } else if (formLists.isEuCountry(countryCode)) {
            return 'IBAN';
        } else if (angular.isDefined(countryCode)) {
            return 'ROTW';
        }
    };

    /**
     * @ngdoc method
     * @name getClassificationMappedValue
     *
     * @description
     * Given a classificationType and a category,
     * returns either the key or the label for the classification.
     *
     * @param {string} classificationType
     * @param {string} category
     * @returns {string}
     */
    api.getClassificationMappedValue = function (classificationType, category) {
        var classificationMapping = {
            RetailInvestor: {
                key: 'regular',
                label: 'Restricted'
            },
            SophisticatedInvestor: {
                key: 'sophisticated',
                label: 'Self Certified Sophisticated'
            },
            HighNetWorthIndividual: {
                key: 'high-net',
                label: 'High Net Worth'
            }
        };
        return classificationMapping[classificationType][category];
    };

    api.isClassifiedUser = isClassifiedUser;

    api.isRestrictedInvestor = isRestrictedInvestor;

    api.isClassifiedAndKycedUser = isClassifiedAndKycedUser;

    api.hasPassedKyc = hasPassedKyc;

    api.kycMoreInformationRequired = kycMoreInformationRequired;

    api.hasNotDoneKyc = hasNotDoneKyc;

    api.hasConfirmedPlatformUse = hasConfirmedPlatformUse;
    api.platformUseConfirmationRequired = platformUseConfirmationRequired;

    api.isBusiness = isBusiness;

    api.hasFunded = hasFunded;

    api.hasInvested = hasInvested;

    api.hasFundedOrInvested = hasFundedOrInvested;

    /**
     * exposes the accountNumber regexp
     *
     * @property {RegExp}
     */
    api.accountNumberRegexp = ACCOUNT_NUMBER_REGEXP;

    /**
     * exposes the sortCode regexp
     *
     * @property {RegExp}
     */
    api.sortCodeRegexp = SORT_CODE_REGEXP;

    /**
     * exposes the usAccountNumber regexp
     *
     * @property {RegExp}
     */
    api.usAccountNumberRegexp = US_ACCOUNT_NUMBER_REGEXP;

    /**
     * exposes the bic regexp
     *
     * @property {RegExp}
     */
    api.bicRegexp = BIC_REGEXP;

    /**
     * exposes the abaRoutingCode regexp
     *
     * @property {RegExp}
     */
    api.abaRoutingCodeRegexp = ABA_ROUTING_CODE_REGEXP;

    /**
     * exposes the iban regexp
     *
     * @property {RegExp}
     */
    api.ibanRegexp = IBAN_REGEXP;

    return api;
}]);
})(window, window.angular);