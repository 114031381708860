(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.account-history-row', [
    'ngSanitize',
    'pp.services.core',
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.services.route'
]);

var ICONS_MAP = {
    RBN: 'historyReferralBonus-2.png',
    DBN: 'historycashbackblank.png',
    CBK: 'historycashbackblank.png',
    RFF: 'historycashbackblank.png',
    RFFR: 'historycashbackblank.png',
    BXR: 'historyBankBlueRefund.png',
    RID: 'historyRentGreen.png',
    BXF: 'historyBankGreen.png',
    BXW: 'historyBankBlue.png',
    CCF: 'historyCardGreen.png',
    RCP: 'historyCardGreen.png',
    CCW: 'historyCardBlue.png',
    TRDB: 'historyUnitWhite.png',
    CRP: 'historyUnitGreen.png',
    ICD: 'historyUnitGreen.png',
    ICDR: 'historyUnitWhite.png',
    TRDBB: 'historyUnitBid.png',
    TRDS: 'historyUnitGreen.png',
    PPM: 'historyUnitWhite.png',
    RIC: 'historyRentGreen.png',
    TRF: 'historyFee.png',
    PMF: 'historyFee.png',
    ACF: 'historyFee.png',
    TRT: 'historyTax.png',
    ITX: 'historyInterAccount.png',
    ITI: 'historyInterAccount.png',
    ISI: 'historyInterAccount.png',
    ITO: 'historyInterAccount.png',
    ISIR: 'historyInterAccount.png',
    ISIE: 'historyInterAccount.png',
    ITR: 'historycashbackblank.png',
    MAN: 'historyFee.png'
};

var TITLES_MAP = {
    RBN: 'Referral Bonus',
    DBN: 'Referral Cashback',
    CBK: 'Cashback Received',
    RFF: 'Funds Received',
    RFFR: 'Welcome Bonus Expired',
    BXR: 'Withdrawal Refund',
    RID: 'Dividends',
    BXF: 'Bank Account Transfer',
    BXW: 'Withdrawal to Bank Account',
    CCF: 'Debit Card Transfer',
    CRP: 'Capital Repaid',
    ICD: 'Interim Cash Distribution',
    ICDR: 'Interim Cash Distribution Reversal',
    RCP: 'Debit Card Transfer',
    CCW: 'Withdrawal to Debit Card',
    TRDB: 'Shares Bought',
    TRDBB: 'Shares Bought - Bid Matched',
    TRDS: 'Shares Sold',
    PPM: 'Shares Bought',
    PPM2: 'Bonds Bought',
    PPM3: 'Certificates Bought',
    RIC: 'Shares Sold',
    TRF: 'Property Partner Transaction Fee',
    PMF: 'Property Partner Transaction Fee',
    TRT: 'Tax on Purchase',
    ITX: 'Funds Moved',
    ITI: 'Funds Moved',
    ISI: 'ISA Transferred In',
    ISIR: 'Excess ISA Funds Transferred In',
    ISIE: 'ISA Transferred in Residual Amount',
    ITO: 'ISA Transferred Out',
    ITR: 'Interest',
    ITR2: 'Income',
    MAN: 'Account Correction',
    ACF: 'London House Exchange Account Fee'
};

var SOURCE_LABEL_MAP = {
    balanced: ' (Balanced plan)',
    growth: ' (Investment plan)',
    income: ' (Income plan)',
    dividends: ' (Dividends reinvested)'
};

ngModule.directive('ppAccountHistoryRow', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/account-history-row/account-history-row.tpl.html',
        scope: {
            transaction: '=ppAccountHistoryRow'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppConfig', 'routeService', 'R', function ($scope, ppConfig, routeService, R) {
            var vm = this;

            // -- initial state
            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var assetsEndpoint = __endpoints.assets + '/images/icons/history/';

            vm.incomeTaxWithheldHelp = routeService.incomeTaxWithheldHelp;

            var unitTypeMap = {
                'development_loan': 'bonds',
                'trust': 'certificates'
            };

            // -- util functions
            vm.getTitle = function (originalType) {

                var type = angular.copy(originalType);

                var metadata = vm.transaction.metadata || {};

                if (type.match('^TRD')) {
                    if (!vm.transaction.credit) {
                        type += 'B';
                    } else {
                        type += 'S';
                    }
                }

                if (type.match('^ICD')) {
                    if (!vm.transaction.credit) {
                        type += 'R';
                    }
                }

                if (type === 'CBK' && vm.transaction.metadata['cashback-type']) {
                    return metadata['cashback-type'];
                }

                if (type === 'PPM' && vm.transaction.assetType === 'development_loan') {
                    return TITLES_MAP['PPM2'] + (SOURCE_LABEL_MAP[metadata['source']] || '');
                }

                if (type === 'PPM' && vm.transaction.assetType === 'trust') {
                    return TITLES_MAP['PPM3'];
                }

                if (type === 'ITR' && vm.transaction.assetType === 'trust') {
                    return TITLES_MAP['ITR2'];
                }

                // primary and secondary market transactions
                if (['PMF', 'PPM', 'TRDB', 'TRF', 'TRT'].indexOf(type) > -1) {
                    return TITLES_MAP[type] + (SOURCE_LABEL_MAP[metadata['source']] || '');
                }

                return TITLES_MAP[type];
            };

            vm.getIcon = function (originalType) {

                var type = angular.copy(originalType);

                if (type.match('^TRD')) {
                    if (!vm.transaction.credit) {
                        type += 'B';
                    } else {
                        type += 'S';
                    }
                }

                if (type.match('^ICD')) {
                    if (!vm.transaction.credit) {
                        type += 'R';
                    }
                }

                if (ICONS_MAP[type]) {
                    return assetsEndpoint + ICONS_MAP[type];
                } else {
                    return assetsEndpoint + 'historyUnknown.png';
                }

            };

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.unitType = R.defaultTo('shares', R.prop(vm.transaction.assetType, unitTypeMap));
            };

        }]
    };
}]);
})(window, window.angular);