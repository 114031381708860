(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.trust-disclaimer-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.investor',
    'pp.widgets.trust-certificate-warning'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('trustDisclaimerDialog', ['$window', '$rootScope', '$q', 'ppTrack', 'dialogService', 'investorService', function ($window, $rootScope, $q, ppTrack, dialogService, investorService) {

    // -- initial state

    // -- util functions

    var dialogInstance;

    // create a scope, show the dialog
    function showDialog() {

        ppTrack.event('user.trust-warning.shown');

        var scope = $rootScope.$new();

        scope.onBlock = function () {
            $window.location.href = '/';
        };

        scope.onContinue = function () {
            dialogService.resolve();
        };

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'pp-trust-certificate-warning-dialog',
            scope: scope
        };

        options.contents = '<pp-trust-certificate-warning on-block="onBlock()" on-continue="onContinue()"></pp-trust-certificate-warning>';

        dialogInstance = dialogService.show(options);

        return dialogInstance;
    }
    // -- api

    var api = {
        showDialog: showDialog
    };

    return api;
}]);
})(window, window.angular);