(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.trust-certificate-warning', [
    'pp.widgets-templates',
    'pp.services.preference'
]);

ngModule.component('ppTrustCertificateWarning', {
    templateUrl: 'widgets/_angular/trust-certificate-warning/trust-certificate-warning.tpl.html',
    bindings: {
        onBlock: '&',
        onContinue: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'preferenceService', function ($scope, preferenceService) {
        var vm = this;

        // -- initial state

        vm.agree = undefined;

        // -- util functions

        function continueOrBlock(agree) {
            if (agree === 'yes') {
                vm.onContinue();
            } else {
                vm.onBlock();
            }
        }

        // -- api

        vm.submit = function () {
            vm.processing = true;
            preferenceService.setAgreedToTrustDisclaimer(vm.agree).then(function () {
                continueOrBlock(vm.agree);
                vm.processing = false;
            }, function () {
                continueOrBlock(vm.agree);
                vm.processing = false;
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);