(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.property', [
    'pp.services.auth',
    'pp.services.core',
    'pp.services.number',
    'pp.services.browser-store',
    'pp.filters.serialize-json',
    'pp.filters.numbers'
]);

var STATUS_PRE_LISTING = 'PRE_LISTING';
var STATUS_PRIMARY_OFFERED = 'PRIMARY_OFFERED';
var STATUS_PRIMARY_CLOSED = 'PRIMARY_CLOSED';
var STATUS_PRIMARY_INFORMATIONAL = 'PRIMARY_INFORMATIONAL';
var STATUS_SECONDARY_INFORMATIONAL = 'SECONDARY_INFORMATIONAL';
var STATUS_SECONDARY_MARKET = 'SECONDARY_MARKET';
var STATUS_SUSPENDED = 'SUSPENDED';
var STATUS_HALTED = 'HALTED';
var STATUS_FINISHED = 'FINISHED';

var CLOSED_PERIOD_REASON = ['CLOSED_PERIOD', 'AD_HOC_CLOSED_PERIOD'];
var EXIT_MECHANIC_VOTE_REASON = 'EXIT_MECHANIC_VOTE';
var EXIT_MECHANIC_SALE_REASON = 'EXIT_MECHANIC_SALE';
var EXIT_MECHANIC_BLOCK_TRADE_REASON = 'EXIT_MECHANIC_BLOCK_TRADE';
var EXIT_MECHANIC_BLOCK_TRADE_CLOSED_REASON = 'EXIT_MECHANIC_BLOCK_TRADE_CLOSED';
var RIGHTS_ISSUE_REASON = 'RIGHTS_ISSUE_FUNDING';
var RIGHTS_ISSUE_PARENT_REASON = 'RIGHTS_ISSUE_PARENT';
var UNIT_OFFER_SHAREHOLDER_VOTE_REASON = 'UNIT_OFFER_SHAREHOLDER_VOTE';
var PROPERTY_OFFER_SHAREHOLDER_VOTE_REASON = 'PROPERTY_OFFER_SHAREHOLDER_VOTE';
var UNIT_OFFER_SALE_IN_PROGRESS_REASON = 'UNIT_OFFER_SALE_IN_PROGRESS';
var PROPERTY_OFFER_SALE_IN_PROGRESS_REASON = 'PROPERTY_OFFER_SALE_IN_PROGRESS';
var UNIT_SALE_DEFER_VOTE_REASON = 'UNIT_SALE_DEFER_VOTE';
var PROPERTY_MUTATE_VOTE_REASON = 'PROPERTY_MUTATE_VOTE';
var EXIT_MAJORITY_VOTE_REASON = 'EXIT_MAJORITY_VOTE';
var REFURBISHMENT_VOTE_REASON = 'REFURBISHMENT_VOTE';
var EXIT_MECHANIC_DEFERRED_REASON = 'EXIT_MECHANIC_DEFERRED';
var EF_AUCTION_VOTE_REASON = 'EF_AUCTION_VOTE';

var RIGHTS_ISSUE_METADATA_KEY = 'rights_issue_funding_symbol';

var VOTING_REASONS = [
    EXIT_MECHANIC_VOTE_REASON,
    UNIT_OFFER_SHAREHOLDER_VOTE_REASON,
    PROPERTY_OFFER_SHAREHOLDER_VOTE_REASON,
    UNIT_SALE_DEFER_VOTE_REASON,
    PROPERTY_MUTATE_VOTE_REASON,
    REFURBISHMENT_VOTE_REASON,
    EF_AUCTION_VOTE_REASON,
    EXIT_MAJORITY_VOTE_REASON
];

// These pre-order statuses don't exist in the database
var STATUS_PRE_ORDER = 'PRE_ORDER';
var STATUS_PRE_ORDER_CLOSED = 'PRE_ORDER_CLOSED';

var STUDENT_THEME_NAME = 'Student';
var COMMERCIAL_THEME_NAME = 'Commercial';
var TITLE_REGISTER_DESCRIPTION = 'Here you can access a copy of the \'Title Register\' document from the UK Land Registry. The Title Register confirms that the property(ies) is/are owned by (SPV number) and was purchased for the amount of (:capital_raise) as previously stated in the Solicitors Report.';
var CERTIFICATE_OF_INCORPORATION_DESCRIPTION = 'Here you can access a copy of the Certificate of Incorporation from Companies House. The Certificate of Incorporation confirms that the SPV is a legal UK entity.';
var STATUS_MAP = {};
STATUS_MAP[STATUS_PRE_LISTING] = 'prelisting';
STATUS_MAP[STATUS_PRE_ORDER] = 'pre-order';
STATUS_MAP[STATUS_PRE_ORDER_CLOSED] = 'pre-order-closed';
STATUS_MAP[STATUS_PRIMARY_OFFERED] = 'primary';
STATUS_MAP[STATUS_PRIMARY_CLOSED] = 'primary-closed';
STATUS_MAP[STATUS_SECONDARY_MARKET] = 'secondary';
STATUS_MAP[STATUS_SECONDARY_INFORMATIONAL] = 'secondary-informational';
STATUS_MAP[STATUS_SUSPENDED] = 'suspended';
STATUS_MAP[STATUS_HALTED] = 'halted';
STATUS_MAP[STATUS_FINISHED] = 'finished';
STATUS_MAP[STATUS_PRIMARY_INFORMATIONAL] = 'preview';

var PRIMARY_STATUSES = [
    STATUS_MAP[STATUS_PRE_LISTING],
    STATUS_MAP[STATUS_PRIMARY_OFFERED],
    STATUS_MAP[STATUS_PRIMARY_CLOSED],
    STATUS_MAP[STATUS_PRIMARY_INFORMATIONAL],
    STATUS_MAP[STATUS_PRE_ORDER],
    STATUS_MAP[STATUS_PRE_ORDER_CLOSED]
];

var SECONDARY_STATUSES = [
    STATUS_MAP[STATUS_SECONDARY_MARKET],
    STATUS_MAP[STATUS_SECONDARY_INFORMATIONAL]
];

var SECONDARY_MARKETABLE = [
    STATUS_MAP[STATUS_SECONDARY_MARKET],
    STATUS_MAP[STATUS_SUSPENDED],
    STATUS_MAP[STATUS_HALTED]
];

/**
 * @ngdoc service
 * @name propertyService
 *
 * @description
 * Provides methods to invoke the `/properties` endpoints.
 */
ngModule.service('propertyService', ['$rootScope', '$http', '$q', '$filter', '$interpolate', 'authService', 'numberService', 'browserStoreService', 'ppConfig', 'ppUtil', 'ppBig', 'ppEmitter', 'ppMoment', 'R', function ($rootScope, $http, $q, $filter, $interpolate, authService, numberService, browserStoreService, ppConfig, ppUtil, ppBig, ppEmitter, ppMoment, R) {

    var __config = ppConfig.get('pp.services.property') || {};
    var __endpoints = ppConfig.get('pp.external.endpoints') || {};

    var __corsSupported = 'withCredentials' in new window.XMLHttpRequest();

    var API_BASE_PATH = '/feapi/r1';
    var API_PROPERTY_ENDPOINT = '/properties';
    var API_PROPERTY_STATUS_ENDPOINT = '/properties/:symbol/events?pageNumber=1&pageSize=100';
    var API_PRIMARY_AVAILABLE_ENDPOINT = '/marketplace/primary/:symbol/available';
    var API_SECONDARY_AVAILABLE_ENDPOINT = '/marketplace/secondary/:symbol/available';
    var API_SECONDARY_LIQUIDITY_ENDPOINT = '/secondary/:symbol/liquidity';
    var API_SECONDARY_QUOTE_ENDPOINT = '/secondary/generate-quote';
    var API_PRIMARY_QUOTE_ENDPOINT = '/properties/:symbol/quote';
    var API_SECONDARY_TRADED_ENDPOINT = '/marketplace/secondary/:symbol/trading-history';

    var API_DISCLOSURE_ENDPOINT = API_BASE_PATH + '/properties/:symbol/disclosure/:disclosureType';

    var API_PROPERTIES_STATIC = API_BASE_PATH + '/properties?view=static';
    var API_PROPERTIES_MARKETDATA = API_BASE_PATH + '/properties?view=marketdata';
    var API_PROPERTIES_TILE = API_BASE_PATH + '/properties?view=tile';

    // make sure local requests take the `'X-Requested-With': 'XMLHttpRequest'` symbol
    // inferred from endpoint string because it depennds on both server config (dev mode) and browser being cors capable
    var IS_LOCAL_REQUEST = API_PROPERTY_ENDPOINT === '/properties';

    var promiseCache = {};

    var dataCache = {};

    // stores callbacks per event and triggers them
    // trigger events via emitter.emit('name', arg1, ...)
    var emitter = ppEmitter.create();

    function createNewScope(data) {
        var scope = $rootScope.$new();
        for (var key in data) {
            scope[key] = data[key];
        }
        return scope;
    }

    function getStatusFromPropertyOrOffer(propertyOrOffer) {
        propertyOrOffer = propertyOrOffer || {};
        return propertyOrOffer.status || STATUS_MAP[propertyOrOffer.marketStatus];
    }

    function isPrimaryStatus(status) {
        return PRIMARY_STATUSES.indexOf(status) !== -1;
    }

    function isSecondaryStatus(status) {
        return SECONDARY_STATUSES.indexOf(status) !== -1;
    }

    function hasStatus(property) {
        return ppUtil.hasPathIn(property, 'state') && property.state.status;
    }

    // for new property api (not offer)
    function isPrimaryProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return isPrimaryStatus(status);
    }

    // for new property api (not offer)

    var getStatus = R.path(['state', 'status']);

    var getReason = R.path(['state', 'reason']);

    var checkIsSuspended = R.compose(
        R.equals(true),
        R.prop('isSuspended')
    );

    var checkIsPrimaryOfferedProperty = R.compose(
        R.equals(STATUS_MAP[STATUS_PRIMARY_OFFERED]),
        R.path(['state', 'status'])
    );

    var checkIsPrimaryClosedProperty = R.compose(
        R.equals(STATUS_MAP[STATUS_PRIMARY_CLOSED]),
        R.path(['state', 'status'])
    );

    function isPrimaryOfferedProperty(property) {
        return checkIsPrimaryOfferedProperty(property);
    }

    // for new property api (not offer)
    function isSecondaryMarketableProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return SECONDARY_MARKETABLE.indexOf(status) !== -1;
    }

    // for new property api (not offer)
    function isSecondaryProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return status === STATUS_MAP[STATUS_SECONDARY_MARKET];
    }

    // for new property api (not offer)
    function isPreorderProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return status === STATUS_MAP[STATUS_PRE_ORDER];
    }

    // for new property api (not offer)
    function isPrimaryClosedProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return status === STATUS_MAP[STATUS_PRIMARY_CLOSED];
    }

    // for new property api (not offer)
    function isSecondaryInformationalProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return status === STATUS_MAP[STATUS_SECONDARY_INFORMATIONAL];
    }

    function isPreorderClosedProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return status === STATUS_MAP[STATUS_PRE_ORDER_CLOSED];
    }

    function isPreviewProperty(property) {
        if (!hasStatus(property)) {
            return;
        }
        var status = property.state.status;
        return status === STATUS_MAP[STATUS_PRIMARY_INFORMATIONAL];
    }

    function isFinished(propertyOrOffer) {
        propertyOrOffer = propertyOrOffer || {};
        var status = ppUtil.path(propertyOrOffer, 'state.status');
        return status === STATUS_MAP[STATUS_FINISHED];
    }

    function getPropertySharesAvailablePct(hasPathIn, property) {
        if (!hasPathIn(property, 'market.primary')) {
            return;
        }

        return property.market.primary.fundedPct;
    }

    // for new property api (not offer)
    function hasPrimarySharesAvailable(hasPathIn, property) {
        return getPropertySharesAvailablePct(hasPathIn, property) < 100;
    }

    // for new property api (not offer)
    function isPropertyInvestmentActive(property) {
        return !!(isPrimaryOfferedProperty(property) || isSecondaryProperty(property) || isPreorderProperty(property));
    }

    function mapStatus(propertyOrOffer) {
        propertyOrOffer.status = propertyOrOffer.status || STATUS_MAP[propertyOrOffer.marketStatus];
        return propertyOrOffer.status;
    }

    function getRatesForProperty(rates, isPrimaryProperty) {
        var ratesCopy = rates || {};
        return {
            taxRate: isPrimaryProperty ? ratesCopy.primaryMarketTaxRate : ratesCopy.secondaryMarketTaxRate,
            feeRate: isPrimaryProperty ? ratesCopy.primaryMarketFeeRate : ratesCopy.secondaryMarketFeeRate
        };
    }

    var checkIsRightsIssueReason = R.compose(
        R.equals(RIGHTS_ISSUE_REASON),
        getReason
    );

    var checkIsRightsIssue = R.allPass([
        checkIsRightsIssueReason,
        R.anyPass([
            checkIsPrimaryOfferedProperty,
            checkIsPrimaryClosedProperty
        ])
    ]);

    function isRightsIssue(property) {
        return checkIsRightsIssue(property);
    }

    var checkIsRightsIssueParentReason = R.compose(
        R.equals(RIGHTS_ISSUE_PARENT_REASON),
        getReason
    );

    var checkHasRightsIssueFundingSymbol = R.compose(
        R.complement(R.isNil),
        R.path(['metadata', RIGHTS_ISSUE_METADATA_KEY])
    );

    var checkIsRightsIssueParent = R.allPass([
        checkIsRightsIssueParentReason,
        checkHasRightsIssueFundingSymbol,
        checkIsSuspended
    ]);

    function isRightsIssueParent(property) {
        return checkIsRightsIssueParent(property);
    }

    /**
     * handles all requests (and cache) to all endpoints /:symbol/foo/bar?version=:version
     *
     * @param {string|Object} symbolOrProperty
     * @param {number=} version
     * @returns {Promise}
     */
    function normalizedRequest(resource, symbolOrProperty) {
        var symbol = symbolOrProperty;
        // override
        if (angular.isObject(symbolOrProperty)) {
            symbol = symbolOrProperty.symbol;
        }
        var endpoint = API_PROPERTY_ENDPOINT + '/' + symbol + '/' + resource;
        var cacheKey = symbol + '.' + resource;

        var options = {};
        if (IS_LOCAL_REQUEST) {
            options.headers = {
                'X-Requested-With': 'XMLHttpRequest'
            };
        }

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, options).then(function (response) {
                return response.data;
            });
        }
        return promiseCache[cacheKey];
    }

    function addParamsToEndpoint(url, params) {

        if (angular.isArray(params)) {
            return params.reduce(function (url, item) {
                return url + '&' + item.key + '=' + item.value;
            }, url);
        }

        if (!params || !Object.keys(params)) {
            return url;
        }

        for (var key in params) {
            url += '&' + key + '=' + params[key];
        }
        return url;
    }

    /**
     * public api surface
     */
    var api = {};

    // adds .on() add off() methods to the service API, but bound to the internal emitter registry
    ppEmitter.mixin(api, emitter);

    var defaultToUndefined = R.defaultTo(undefined);
    var defaultToFalse = R.defaultTo(false);

    function normaliseRightsIssue(property) {
        var metadata = property.metadata || {};
        // Rights issue / Equity fundraise
        property.isRightsIssue = isRightsIssue(property);
        property.rightsIssueFundingSymbol = metadata[RIGHTS_ISSUE_METADATA_KEY];
        property.isRightsIssueParent = isRightsIssueParent(property);

        if (property.isRightsIssueParent || property.efAuctionVoteInProgress) {
            property.equityRaiseNewSharesPct = defaultToUndefined(Number(R.path(['equity_raise_new_shares_pct'], metadata)));

            property.propertyValue = defaultToUndefined(Number(R.path(['property_value'], metadata)));
            property.mortgageValue = defaultToUndefined(Number(R.path(['mortgage_value'], metadata)));
            property.loanToValue = defaultToUndefined(Number(R.path(['loan_to_value'], metadata)));
            property.cashSurplus = defaultToUndefined(Number(R.path(['cash_surplus'], metadata)));
            property.equityValue = defaultToUndefined(Number(R.path(['equity_value'], metadata)));
            property.targetMortgageRepayment = defaultToUndefined(Number(R.path(['target_mortgage_repayment'], metadata)));
            property.targetDefecitReduction = defaultToUndefined(Number(R.path(['target_defecit_reduction'], metadata)));
            property.targetFundraise = defaultToUndefined(Number(R.path(['target_fundraise'], metadata)));
            property.sharePriceValuation = defaultToUndefined(Number(R.path(['share_price_valuation'], metadata)));
            property.sharePriceTrading = defaultToUndefined(Number(R.path(['share_price_trading'], metadata)));
            property.sharePriceReference = defaultToUndefined(Number(R.path(['share_price_reference'], metadata)));
            property.sharePriceFundraise = defaultToUndefined(Number(R.path(['share_price_fundraise'], metadata)));
            property.newSharesIssued = defaultToUndefined(Number(R.path(['new_shares_issued'], metadata)));
            property.totalSharesInIssue = defaultToUndefined(Number(R.path(['total_shares_in_issue'], metadata)));
            property.valueOfEquity = defaultToUndefined(Number(R.path(['value_of_equity'], metadata)));
            property.shareholderDilution = defaultToUndefined(Number(R.path(['shareholder_dilution'], metadata)));
            property.shareholderDilutionOwnershipShare = defaultToUndefined(Number(R.path(['shareholder_dilution_ownership_share'], metadata)));
            property.equityFundraiseDiscountToVpv = defaultToUndefined(Number(R.path(['equity_fundraise_discount_to_vpv'], metadata)));
            property.spvNameEquityRaise = R.path(['spv_name_equity_raise'], metadata);

            // Flag to hide invest button    
            property.rightsIssueOpen = defaultToFalse(R.equals('true', R.toLower(R.defaultTo('', R.path(['rights_issue_open'], metadata)))));
        }

        return property;

    }

    function normalisePropertyStage(property) {
        var status = hasStatus(property) && property.state.status;
        var stage;
        // set overarching stage
        // pre-listing, preview, pre-order, pre-order-closed, primary, primary-closed, secondary, halted, suspened];
        switch (status) {
        case 'pre-listing':
        case 'preview':
        case 'pre-order':
        case 'pre-order-closed':
            stage = 'pre-order';
            break;
        case 'primary':
        case 'primary-closed':
            stage = 'primary';
            break;
        case 'secondary':
        case 'halted':
        case 'suspended':
            stage = 'secondary';
            break;
        default:
            stage = undefined;
        }

        if (hasStatus(property)) {
            property = property || {};
            var state = property.state || {};
            state.stage = stage;
            property.state = state;
        }

        return property;
    }

    function createExitMechanicDesc(date) {

        if (!date) {
            // note early return
            return;
        }

        if (date.isAfter()) {
            return date.fromNow(true);
        } else {
            return '0 days';
        }

    }

    function createZerodpCurrency(amount) {
        return $filter('currency')(amount, '£', 0);
    }

    function normalisePropertyDescriptions(property) {

        var ifIsNilNullElseGbp = R.ifElse(R.isNil, R.always(null), createZerodpCurrency);

        if (ppUtil.path(property, 'media.propertyDetailsDescription')) {
            var valuations = R.filter(R.complement(R.isNil))({
                latestInvestmentValue: ifIsNilNullElseGbp(R.path(['valuation', 'propertyPriceIv'], property)),
                latestVacantPossessionValue: ifIsNilNullElseGbp(R.path(['valuation', 'propertyPriceVpv'], property))
            });

            var scopeObj = R.mergeRight(
                property.metadata,
                valuations
            );

            var scope = createNewScope(scopeObj);
            property.media.propertyDetailsDescriptionWithData = $interpolate(property.media.propertyDetailsDescription)(scope);
        }
        return property;
    }

    function normalisePropertyMarketData(data) {

        var lastTradePrice = R.path(['secondary', 'lastTradePrice'], data);
        var sevenDayWasp = R.path(['secondary', '7DayWasp'], data);

        var notNil = R.compose(R.not, R.isNil);

        var canCreatePctChange = R.and(notNil(lastTradePrice), notNil(sevenDayWasp));

        var pctChange;

        if (canCreatePctChange) {
            pctChange = Number(ppBig(lastTradePrice)
                .minus(sevenDayWasp)
                .div(sevenDayWasp)
                .times(100)
                .round(2)
            );
        }

        return R.compose(
            R.mergeLeft({
                lastTradePctChange: pctChange
            })
        )(data);
    }

    api.calculateLastTradedPricePremiumToValuation = function (valuation, property) {
        var buyPrice = R.path(['market', 'secondary', 'lastTradePrice'], property);
        if (R.isNil(buyPrice) || R.isNil(valuation)) {
            // Note early return
            return;
        }

        return Number(ppBig(buyPrice).div(valuation).minus(1));
    };

    // requires metadata [Object], metadataKey [String] as inputs
    var isMetadataItemTrue = R.curry(R.compose(
        defaultToFalse,
        R.equals('true'),
        R.toLower,
        R.defaultTo(''),
        R.flip(R.prop)
    ));

    function createUnitName(one, many) {
        return {
            caps: {
                one: one.charAt(0).toUpperCase() + one.slice(1),
                many: many.charAt(0).toUpperCase() + many.slice(1)
            },
            lower: {
                one: one,
                many: many
            }
        };
    }

    function getUnitName(property) {
        if (property.isDevelopmentLoan) {
            return createUnitName('bond', 'bonds');
        } else if (property.isTrust) {
            return createUnitName('certificate', 'certificates');
        } else {
            return createUnitName('share', 'shares');
        }
    }

    function normaliseProperty(property) {
        var metadata = property.metadata || {};
        var isMetadataKeyTrue = isMetadataItemTrue(metadata);
        var status = hasStatus(property) && property.state.status;
        var reason = R.path(['state', 'reason'], property);

        property.isStudent = property.assetType === 'student';
        property.isCommercial = property.assetType === 'commercial';
        property.isResidential = property.assetType === 'residential';
        property.isDevelopmentLoan = property.assetType === 'development_loan';
        property.isTrust = property.assetType === 'trust';
        property.isIsaEligible = property.isDevelopmentLoan;
        property.isFund = property.assetType === 'fund';
        property.isHighRisk = metadata['risk_level'] === 'high';
        property.minInvestment = Number(metadata['min_investment']) || 0;
        property.isValueAdd = !!metadata['value_add'];
        property.willNotTrade = !!metadata['cannot_trade'];
        // As block trades happen on clone properties this is only true on the property clone where the primary block trade fundraising is occuring
        property.isBlockTrade = !!metadata['is_block_trade'];

        property.unitName = getUnitName(property);

        property.hideLoanSecurity = !!metadata['hide_loan_security_desc'];
        property.hideLoanRepayment = !!metadata['hide_loan_repayment_desc'];
        property.hideDueDiligence = !!metadata['hide_due_diligence_desc'];
        property.hideFees = !!metadata['hide_fees_desc'];
        property.notMiniBond = isMetadataKeyTrue('not_mini_bond');
        property.replacementLoanType = metadata['replacement_loan_type'];

        property.keyRisksInInvestmentCase = !!metadata['key_risks_in_investment_case'] && String(metadata['key_risks_in_investment_case']).toLowerCase() === 'true';

        property.noInvestmentPage = !!metadata['no_investment_page'];
        property.exitMechanicDate = !!metadata['exit_mechanic_date'] ? ppMoment(metadata['exit_mechanic_date'], 'YYYY-MM-DD') : null;
        property.exitMechanicDateIsEstimate = !!metadata['exit_mechanic_date_is_estimate'] ? !!metadata['exit_mechanic_date_is_estimate'] : false;
        property.showExitMechanicDateBanner = !!metadata['show_exit_mechanic_date_banner'] ? !!metadata['show_exit_mechanic_date_banner'] : false;
        property.hideExitMechnicDateNote = !!metadata['hide_exit_mechanic_date_note'] ? !!metadata['hide_exit_mechanic_date_note'] : false;

        property.hasNoInvestmentValue = !!metadata['has_no_investment_value'] && String(metadata['has_no_investment_value']).toLowerCase() === 'true';
        property.isVpvBased = !!metadata['is_vpv_valuation_based'] && String(metadata['is_vpv_valuation_based']).toLowerCase() === 'true';
        property.hasVpv = R.not(R.isNil(R.path(['valuation', 'latestValueVpv'], property)));

        property.exitMechanicVoteEndDate = !!metadata['exit_mechanic_vote_end_date'] ? ppMoment(metadata['exit_mechanic_vote_end_date'], 'YYYY-MM-DD') : null;
        property.exitMechanicVoteEndJsDate = property.exitMechanicVoteEndDate ? new Date(property.exitMechanicVoteEndDate) : null;
        property.exitMechanicJsDate = property.exitMechanicDate ? new Date(property.exitMechanicDate) : null;
        property.exitMechanicDateDesc = createExitMechanicDesc(property.exitMechanicDate);
        property.isActive = isPropertyInvestmentActive(property);
        property.isSecondaryTrading = !!isSecondaryProperty(property);
        property.isSecondaryInformational = !!isSecondaryInformationalProperty(property);
        property.isSecondaryMarketable = !!isSecondaryMarketableProperty(property);

        property.canBid = !!isSecondaryProperty(property);
        property.canSell = !!isSecondaryProperty(property);
        property.isPending = !!isPrimaryClosedProperty(property);
        property.isPrimary = PRIMARY_STATUSES.indexOf(status) !== -1;
        property.isPrimaryFunding = !!isPrimaryOfferedProperty(property);
        property.isPreorder = !!isPreorderProperty(property);
        property.isFinished = isFinished(property);
        property.isShares = ['student', 'commercial', 'residential', 'fund'].indexOf(property.assetType) !== -1;
        property.isNMPI = !!metadata['is_nmpi'];
        property.isMortgage = isMetadataKeyTrue('is_mortgage');

        if (property.isMortgage) {
            property.equityPropertySymbol = R.prop('mortgage_equity_property_symbol', metadata);
            property.equityPropertyName = R.prop('mortgage_equity_property_name', metadata);
            property.isFundraisingForShareholdersOnly = isMetadataKeyTrue('mortgage_fundraising_shareholders_only');
            property.mortgageAvailableToAllDate = R.prop('mortgage_available_to_all_date', metadata) ? new Date(R.prop('mortgage_available_to_all_date', metadata)) : undefined;
        }

        property.isSukuk = isMetadataKeyTrue('is_sukuk');

        property.mortgageBondPropertySymbol = metadata['equity_mortgage_property_symbol'];
        property.parentPropertySymbol = metadata['parent_property_symbol'];

        property.isDebtFund = !!metadata['is_debt_fund'];
        property.isFundingRound = !!metadata['is_funding_round'];
        property.isFundingForBlockListing = property.isPrimaryFunding && !!metadata['is_in_exit_mechanic'] && String(metadata['is_in_exit_mechanic']).toLowerCase() === 'true';
        property.isBlockedForRetail = (property.isNMPI && property.isActive) || property.isFundingRound || property.isFund || property.isTrust;
        property.isBlockedForRetailNonShareholders = property.isDevelopmentLoan && property.isMortgage;
        property.hardCodedFundedPct = defaultToUndefined(Number(R.path(['hard_coded_funded_pct'], metadata)));
        property.investmentOpenDate = R.prop('investment_open_date', metadata) ? new Date(R.prop('investment_open_date', metadata)) : undefined;
        property.unitScheduleDate = R.prop('unit_schedule_date', metadata) ? new Date(R.prop('unit_schedule_date', metadata)) : undefined;

        if (R.prop('primary_market_appears_closed', metadata)) {
            property.primaryAppearClosed = isMetadataKeyTrue('primary_market_appears_closed');
        }

        if (status === STATUS_MAP[STATUS_SUSPENDED] || status === STATUS_MAP[STATUS_HALTED]) {
            property.state.status = STATUS_MAP[STATUS_SECONDARY_MARKET];
            property.isSuspended = true;

            property.isInClosedPeriod = CLOSED_PERIOD_REASON.indexOf(reason) !== -1;
            property.isExitMechanicVote = reason === EXIT_MECHANIC_VOTE_REASON;
            property.isExitMechanicVoteDeferred = reason === EXIT_MECHANIC_DEFERRED_REASON;
            property.unitOfferSale = reason === UNIT_OFFER_SALE_IN_PROGRESS_REASON;
            property.isBlockListing = reason === UNIT_OFFER_SALE_IN_PROGRESS_REASON;
            property.isVoting = VOTING_REASONS.indexOf(reason) !== -1;
            property.unitSaleVoteActive = reason === UNIT_OFFER_SHAREHOLDER_VOTE_REASON;
            property.propertySaleVoteActive = reason === PROPERTY_OFFER_SHAREHOLDER_VOTE_REASON;
            property.propertySaleInProgress = reason === PROPERTY_OFFER_SALE_IN_PROGRESS_REASON;
            property.saleDeferVoteInProgress = reason === UNIT_SALE_DEFER_VOTE_REASON;
            property.efAuctionVoteInProgress = reason === EF_AUCTION_VOTE_REASON;

            // As block trades happen on clone properties this is only true on the original property, where the primary block trade fundraising is NOT occuring
            property.blockTradeOnPropertyActive = reason === EXIT_MECHANIC_BLOCK_TRADE_REASON;
            property.blockTradeOnPropertyClosed = reason === EXIT_MECHANIC_BLOCK_TRADE_CLOSED_REASON;
            if (property.blockTradeOnPropertyActive || property.blockTradeOnPropertyClosed) {
                property.blockTradePropertySymbol = metadata['block_trade_property_symbol'];
            }
        }

        property.isExitMechanicSale = reason === EXIT_MECHANIC_SALE_REASON;

        property = normaliseRightsIssue(property);

        return normalisePropertyDescriptions(normalisePropertyStage(property));
    }

    var normaliseTile = R.compose(
        R.over(R.lensPath(['market']), normalisePropertyMarketData),
        R.over(R.lensPath([]), normaliseProperty)
    );

    /**
     * @ngdoc method
     * @name propertyService#getPropertyStatic
     *
     * @description
     * Given one property symbol, returns a promise that resolves with a property static model (partial model)
     * Invokes the webapp API endpoint: `GET /feapi/r1/properties/:symbol?view=static`.
     * Promises are cached, only one request ever made per property/version pair.
     *
     * @param {string|Object} symbolOrProperty
     * @param {number=} version
     * @returns {Promise}
     *
     * @todo handle rejections, purge rejections from cache
     */

    api.getPropertyStatic = function (symbol) {

        var cacheKey = symbol + '.static';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(API_BASE_PATH + '/properties/' + symbol + '?view=static').then(function (property) {
                var normalisedProperty = normaliseProperty(property.data);
                if (normalisedProperty.rightsIssueFundingSymbol) {
                    return api.getPropertyData(normalisedProperty.rightsIssueFundingSymbol).then(function (rightsIssueProperty) {
                        normalisedProperty.rightsIssueProperty = rightsIssueProperty;
                        return normalisedProperty;
                    }, function () {
                        return normalisedProperty;
                    });
                } else if (normalisedProperty.blockTradePropertySymbol) {
                    return api.getPropertyData(normalisedProperty.blockTradePropertySymbol).then(function (blockTradeProperty) {
                        normalisedProperty.blockTradeProperty = blockTradeProperty;
                        return normalisedProperty;
                    }, function () {
                        return normalisedProperty;
                    });
                } else {
                    return normalisedProperty;
                }

            }, function (error) {
                delete promiseCache[cacheKey];
                return $q.reject(error);
            });
        }

        return promiseCache[cacheKey];
    };

    api.fetchPropertiesStatic = function (params) {
        var endpoint = addParamsToEndpoint(API_PROPERTIES_STATIC, params);

        return $http.get(endpoint).then(function (response) {
                if (!response) {
                    return undefined;
                }
                return normaliseProperty(response.data);
            },
            function (error) {
                switch (error.status) {
                case 404:
                    return $q.reject(error);
                default:
                    return $q.reject({
                        reason: 'marketplace.error.unexpected'
                    });
                }
            });
    };

    api.fetchPropertiesMarketdata = function (params) {

        var endpoint = addParamsToEndpoint(API_PROPERTIES_MARKETDATA, params);

        return $http.get(endpoint).then(function (response) {
                if (!response) {
                    return undefined;
                }
                return normalisePropertyMarketData(response.data);
            },
            function (error) {
                switch (error.status) {
                case 404:
                    return $q.reject(error);
                default:
                    return $q.reject({
                        reason: 'marketplace.error.unexpected'
                    });
                }
            });
    };

    api.fetchPropertiesTile = function (params) {
        var endpoint = addParamsToEndpoint(API_PROPERTIES_TILE, params);

        return $http.get(endpoint).then(function (response) {
                if (!response) {
                    return undefined;
                }
                return response.data.collection
                    .map(normaliseTile);
            },
            function (error) {
                switch (error.status) {
                case 404:
                    return $q.reject(error);
                default:
                    return $q.reject({
                        reason: 'marketplace.error.unexpected',
                        status: error.status
                    });
                }
            });
    };

    api.getPropertiesList = api.fetchPropertiesTile;

    api.getPropertyMarketdata = function (symbol) {

        var cacheKey = symbol + '.marketdata';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(API_BASE_PATH + '/properties/' + symbol + '?view=marketdata').then(function (property) {
                var propertyData = normalisePropertyMarketData(property.data);
                return propertyData;
            }, function (error) {
                delete promiseCache[cacheKey];
                return $q.reject(error);
            });
        }

        return promiseCache[cacheKey];
    };

    api.getPremiumDiscount = function (property) {

        var defaultResult = {};

        if (ppUtil.hasPathIn(property, 'market.secondary.bestOffer') && ppUtil.hasPathIn(property, 'valuation')) {
            var delta = property.market.secondary.bestOffer.price - property.valuation.share;
            var premiumDiscountPct = (delta / property.valuation.share) * 100;
            return {
                premiumDiscountPct: premiumDiscountPct,
                delta: delta
            };
        }

        return defaultResult;

    };

    api.getPropertyData = function (symbol) {

        var staticPromise = api.getPropertyStatic(symbol);
        var marketPromise = api.getPropertyMarketdata(symbol);
        var property;

        return $q.all([staticPromise, marketPromise]).then(function (data) {
            var staticData = data[0];
            var marketData = data[1];

            property = staticData;
            property.market = marketData;
            property.market.premiumDiscount = api.getPremiumDiscount(property);

            return property;
        });
    };

    function getPropertyDisclosure(symbol, disclosureType) {
        var endpoint = API_DISCLOSURE_ENDPOINT.replace(':symbol', symbol).replace(':disclosureType', disclosureType);
        var cacheKey = disclosureType + '_' + symbol;

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (res) {
                return res.data;
            }, function (err) {
                delete promiseCache[cacheKey];
                return $q.reject({
                    reason: 'property.disclosure.unexpected-error'
                });
            });
        }

        return promiseCache[cacheKey];

    }

    api.getPropertyIncomeDisclosure = function (symbol) {
        return getPropertyDisclosure(symbol, 'income');
    };

    var isSoldUnit = R.propSatisfies(R.complement(R.isNil), 'salePrice');
    var isCurrentUnit = R.propSatisfies(R.isNil, 'salePrice');
    var addMergeList = R.reduce(R.mergeWith(R.add), {});

    api.getPropertyUnitsDisclosure = function (symbol) {
        return getPropertyDisclosure(symbol, 'units').then(function (history) {
            return history.map(function (units) {
                var currentUnits = R.filter(isCurrentUnit, units.units);
                var soldUnits = R.filter(isSoldUnit, units.units);
                return R.mergeRight(units, {
                    current: currentUnits,
                    sold: soldUnits,
                    total: {
                        current: addMergeList(currentUnits),
                        sold: addMergeList(soldUnits)
                    }
                });
            });
        });
    };

    // -- Related to the getSummaries api method 

    function cacheSummaries(summaries, cacheKeySuffix) {
        for (var ix = 0; ix < summaries.length; ix++) {
            dataCache[summaries[ix].symbol + cacheKeySuffix] = summaries[ix];
        }
    }

    function getUncachedSymbols(symbols, cacheKeySuffix) {
        return symbols.filter(function (symbol) {
            return !dataCache[symbol + cacheKeySuffix];
        });
    }

    function getUniqueSymbol(arr) {
        var b = {};
        arr.forEach(function (elm) {
            b[elm] = null;
        });
        return Object.keys(b);
    }

    function composePropertyMap(symbols, cacheKeySuffix) {
        var propertyMap = {};
        for (var ix = 0; ix < symbols.length; ix++) {
            propertyMap[symbols[ix]] = dataCache[symbols[ix] + cacheKeySuffix];
        }
        return propertyMap;
    }

    /**
     * @ngdoc method
     * @name propertyService#getSummaries
     *
     * @description
     * Given an array of property symbols, returns a promise that resolves with a map of property summary models (partial model)
     * Invokes the webapp API endpoint: `GET /feapi/r1/property-summary`.
     * Individual properties are cached as data (Only cached after api responds)
     *
     * @param {[string]} symbols
     * @returns {Promise}
     *
     */
    api.getSummaries = function (symbols) {
        var cacheKeySuffix = '.short.summary';

        var uSymbols = getUniqueSymbol(getUncachedSymbols(symbols, cacheKeySuffix)).map(function (symbol) {
            return {
                key: 'symbol',
                value: symbol
            };
        });

        if (!uSymbols.length) {
            return $q.when(composePropertyMap(symbols, cacheKeySuffix));
        }

        return api.getPropertiesList(uSymbols)
            .then(function (summaries) {
                cacheSummaries(summaries, cacheKeySuffix);
                return composePropertyMap(symbols, cacheKeySuffix);
            }, function (error) {
                switch (error.status) {
                case 401:
                    authService.handleUnauthorised();
                    return $q.reject({
                        reason: 'auth.session-required'
                    });
                default:
                    return $q.reject({
                        reason: 'property.summaries.unexpected-error'
                    });
                }

            });
    };

    /**
     * @ngdoc method
     * @name propertyService#getImages
     *
     * @description
     * Given one property model (or it's symbol and version) returns a promise that resolves with a list of photos
     * Invokes the webapp API endpoint: `GET /properties/:symbol/photos?version`.
     * Promises are cached, only one request ever made per property/version pair.
     *
     * @param {string|Object} symbolOrProperty
     * @param {number=} version
     * @returns {Promise}
     */
    api.getImages = function (symbolOrProperty) {
        return normalizedRequest('photos', symbolOrProperty);
    };

    function getTitleReportDescription(property) {
        var capitalRaise = '£x';
        if (property.valuesFixed) {
            capitalRaise = $filter('currency')(property.valuesFixed.capital_raise, '£');
        }

        return TITLE_REGISTER_DESCRIPTION.replace(':capital_raise', $filter('currency')(property.valuesFixed.capitalRaise, '£'));
    }

    function getCertificateOfIncorporationDescription() {
        return CERTIFICATE_OF_INCORPORATION_DESCRIPTION;
    }

    function getStaticDescription(property, type) {
        if (type === 'incorporation') {
            return $q.when(getCertificateOfIncorporationDescription());
        }
        if (type === 'titleRegister') {
            return $q.when(getTitleReportDescription(property));
        }
    }

    /**
     * @ngdoc method
     * @name propertyService#getEvents
     *
     * @description
     * Given one property model (or it's symbol) returns a promise that resolves with a list of events.
     * Invokes the webapp API endpoint: `GET /properties/:symbol/events?pageNumber=1&pageSize=100`.
     * Promises are cached, only one request ever made per property/version pair.
     *
     * @param {string|Object} symbolOrProperty
     * @returns {Promise}
     *
     * @todo pagination
     */
    api.getEvents = function (symbolOrProperty) {
        var symbol = angular.isObject(symbolOrProperty) ? symbolOrProperty.symbol : symbolOrProperty;
        var endpoint = API_PROPERTY_STATUS_ENDPOINT.replace(':symbol', symbol);
        var cacheKey = symbol + '.events';

        function convertDateToTimestamp(date) {
            var dayArray = date.split('-');
            var dateObject = new Date(dayArray[2], dayArray[1] - 1, dayArray[0]); // note: month is 0-base
            return dateObject.getTime();
        }

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (response) {
                for (var i = 0; i < response.data.length; i++) {
                    response.data[i].timestamp = convertDateToTimestamp(response.data[i].activeFrom);
                }
                return response.data;
            });
        }
        return promiseCache[cacheKey];
    };

    api.purgeCache = function (pattern) {
        if (pattern) {
            pattern = new RegExp(pattern);
        }
        for (var prop in promiseCache) {
            if (!pattern || pattern.test(prop)) {
                delete promiseCache[prop];
            }
        }
    };

    api.purgeDataCache = function () {
        dataCache = {};
    };

    /**
     * @ngdoc method
     * @name propertyService#getPrimaryAvailableShares
     *
     * @description
     * Given a property symbol returns a promise resolved with the number of available shares.
     *
     * Example response:
     * ```
     * {
     *   totalUnits: <number>,
     * }
     * ```
     * @param {string} symbol
     * @param {boolean} ignoreCache
     * @returns {Promise}
     */
    api.getPrimaryAvailableShares = function (symbol, ignoreCache) {
        var endpoint = API_PRIMARY_AVAILABLE_ENDPOINT.replace(':symbol', symbol);

        var options = {};
        options.headers = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        var cacheKey = symbol + '.primary.shares';

        if (ignoreCache || !promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                return response.data.unitsAvailable;
            }, function (error) {
                // this service generates unknown errors (probably HTML responses)
                return $q.reject({
                    reason: error.data && error.data.code || 'primary.shares.unexpected-error'
                });
            });
        }
        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name propertyService#checkPrimaryAvailableShares
     *
     * @description
     * Given a property symbol and an expected number of shares, returns a promise
     * that is resolved with the number of shares available (if greater than or equal to the expected)
     * or is rejected with a reason code and the number of available shares, if any.
     *
     * Example response:
     * ```
     * {
     *   totalUnits: <number>,
     * }
     * ```
     * @param {string} symbol
     * @param {number} minimumShares
     * @returns {Promise}
     */
    api.checkPrimaryAvailableShares = function (symbol, minimumShares) {
        return api.getPrimaryAvailableShares(symbol, true).then(function (sharesAvailable) {
            if (sharesAvailable >= minimumShares) {
                return sharesAvailable;
            } else if (sharesAvailable > 0) {
                return $q.reject({
                    reason: 'primary.shares.partially-available',
                    sharesAvailable: sharesAvailable
                });
            } else {
                return $q.reject({
                    reason: 'primary.shares.unavailable',
                    sharesAvailable: 0
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name propertyService#calculateMaxInvestmentCost
     *
     * @description
     * Given a user amount entered calculate the maximum amount can be spent on shares
     * This amount will be posted to the backend to get a quote which the
     * overall price including fees and taxes must be lower than the amount entered
     *
     * @param {number} budget
     * @param {number} feeRate
     * @param {number} taxRate
     * @returns {number}
     */
    api.calculateMaxInvestmentCost = function (budget, taxRate, feeRate) {
        budget = Number(budget);
        taxRate = Number(taxRate);
        feeRate = Number(feeRate);
        return numberService.floor2(budget / (1 + taxRate + feeRate));
    };

    /**
     * @ngdoc method
     * @name propertyService#calculateMaxBidCost
     *
     * @description
     * Given a user amount entered calculate the maximum amount can be bid on shares
     * overall price including fees and taxes must be lower than the amount entered
     * Recursive method created because in practice function calculateMaxBidCost is not
     * 100% reliable
     *
     * @param {number} budget
     * @param {number} feeRate
     * @param {number} taxRate
     * @returns {number}
     */
    api.calculateMaxBidCost = function (budget, taxRate, feeRate) {

        function totalCost(pBudget) {
            var cost = pBudget;
            cost = cost + numberService.ceil2(pBudget * feeRate);
            cost = cost + numberService.round2(pBudget * taxRate);
            return cost;
        }

        function calculateRecursively(testBudget) {
            var predictedBudget = api.calculateMaxInvestmentCost(testBudget, taxRate, feeRate);
            if (isNaN(predictedBudget) || !isFinite(predictedBudget)) {
                return;
            }

            return (totalCost(predictedBudget) <= budget) ? predictedBudget : calculateRecursively(testBudget - 0.01);
        }

        return calculateRecursively(budget);

    };

    /**
     * @ngdoc method
     * @name propertyService#getSharesAvailablePrimary
     *
     * @description
     * Given property, works out remaining number of shares
     * available in the property
     *
     * @param {object} property
     * @returns {number}
     */
    api.getSharesAvailablePrimary = function (property) {
        if (!ppUtil.hasPathIn(property, 'primary') || !ppUtil.hasPathIn(property, 'market.primary')) {
            return;
        }

        return property.primary.numberOfShares * (100 - property.market.primary.fundedPct) / 100;
    };

    /**
     * @ngdoc method
     * @name propertyService#getOrderSharesLiquidity
     *
     * @description
     * Given a property symbol returns a promise resolved with a list of data for best bids and offers.
     * Promises are cached, only one request ever made per property.
     *
     * Example response:
     * ```
     * { bids:
     *   [{
     *     askPrice: <NUMBER>,
     *     totalAmount: <NUMBER>,
     *     units: <NUMBER>,
     *     orders: <STRING>
     *   }...],
     *   offers:
     *   [{
     *     askPrice: <NUMBER>,
     *     totalAmount: <NUMBER>,
     *     units: <NUMBER>,
     *     orders: <STRING>
     *   }...],
     * }
     * ```
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getOrderSharesLiquidity = function (symbol) {
        var endpoint = API_BASE_PATH + API_SECONDARY_LIQUIDITY_ENDPOINT.replace(':symbol', symbol);

        var options = {};
        options.headers = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        var cacheKey = symbol + '.secondary.liquidity';
        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                return response.data;
            }, function (error) {
                // this service generates unknown errors (probably HTML responses)
                return $q.reject({
                    reason: error.data && error.data.code || 'secondary.liquidity.unexpected-error'
                });
            });
        }
        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryAvailableOffers
     *
     * @description
     * Given a property symbol returns a promise resolved with a list of offers available on secondary market.
     * Attribute "orderType" is either "BID" or "ASK" (for sell orders)
     * Promises are cached, only one request ever made per property.
     *
     * Example response:
     * ```
     * {[
     *   askPrice: <NUMBER>,
     *   units: <NUMBER>,
     *   orderType: <STRING>
     * ], {
     *   ...
     * ]}
     * ```
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getSecondaryAvailableOffers = function (symbol) {
        var endpoint = API_SECONDARY_AVAILABLE_ENDPOINT.replace(':symbol', symbol);

        var options = {};
        options.headers = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        var cacheKey = symbol + '.secondary.offers';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                emitter.emit(symbol + '.secondary-offers-updated', response.data);
                return response.data;
            }, function (error) {
                // this service generates unknown errors (probably HTML responses)
                return $q.reject({
                    reason: error.data && error.data.code || 'secondary.offers.unexpected-error'
                });
            });
        }
        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryLowestBid
     *
     * @description
     * Given a property symbol returns a promise resolved with the lowest available bid (as an object) or null if no bids available.
     *
     * Example response:
     * ```
     * {
     *   askPrice: <NUMBER>,
     *   units: <NUMBER>,
     *   orderType: "BID"
     * }
     * ```
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getSecondaryLowestBid = function (symbol) {
        return api.getSecondaryAvailableOffers(symbol).then(function (results) {
            var bids = results.filter(function (item) {
                return item.orderType === 'BID';
            });
            if (bids.length) {
                return bids.reduce(function (previous, current) {
                    return current.askPrice < previous.askPrice ? current : previous;
                }, bids[0]);
            } else {
                return null;
            }
        });
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryHighestSellOrder
     *
     * @description
     * Given a property symbol returns a promise resolved with the lowest available bid (as an object) or null if no bids available.
     *
     * Example response:
     * ```
     * {
     *   askPrice: <NUMBER>,
     *   units: <NUMBER>,
     *   orderType: "BID"
     * }
     * ```
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getSecondaryHighestSellOrder = function (symbol) {
        return api.getSecondaryAvailableOffers(symbol).then(function (results) {
            var offers = results.filter(function (item) {
                return item.orderType === 'ASK';
            });
            if (offers.length) {
                return offers.reduce(function (previous, current) {
                    return current.askPrice > previous.askPrice ? current : previous;
                }, offers[0]);
            } else {
                return null;
            }
        });
    };

    api.getSecondaryTradingHistory = function (symbol) {
        var endpoint = API_SECONDARY_TRADED_ENDPOINT.replace(':symbol', symbol);

        var options = {};
        options.headers = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        var cacheKey = symbol + '.trading.history';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                emitter.emit(symbol + '.secondary-trades-updated', response.data);
                return response.data;
            }, function (error) {
                // this service generates unknown errors (probably HTML responses)
                return $q.reject({
                    reason: error.data && error.data.code || 'secondary.offers.unexpected-error'
                });
            });
        }
        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryAvailableShares
     *
     * @description
     * Given a property symbol returns a promise resolved with the number of available shares on secondary market.
     * Promises are cached, only one request ever made per property.
     *
     * Example response:
     * ```
     * {
     *   totalUnits: <number>,
     * }
     * ```
     * @param {string} symbol
     * @returns {Promise}
     */
    api.getSecondaryAvailableShares = function (symbol) {
        return api.getSecondaryAvailableOffers(symbol).then(function (offers) {
            var available = 0;
            var ix = 0;
            for (ix = 0; ix < offers.length; ix++) {
                available += offers[ix].units;
            }
            return available;
        });
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryQuote
     *
     * @description
     * Given one property and an investment amount (and an optional currencyCode)
     * returns a promise resolved with a secondary market quote.
     *
     * Example request:
     * ```
     * {
     *   symbol: '<string>',
     *   budget: <number>,
     *   currencyCode: '<string>'
     * }
     * ```
     *
     * Example response:
     * ```
     * {
     *   reference: '<string>',
     *   totalUnits: <number>,
     *   totalCost: <number>,
     *   weightedAveragePrice: <number>,
     *   currencyCode: '<string>',
     *   fees: <number>,
     *   propertySymbol: '<string>',
     *   budget: <number>,
     *   tax: <number>
     * }
     * ```
     * @param {object} property containing `symbol` and `shareValuation` attributes
     * @param {number} budget
     * @param {number} feeRate
     * @param {number} taxRate
     * @param {string=} currencyCode defaults to 'GBP'
     * @returns {Promise}
     */
    api.getPrimaryQuote = function (property, budget, currencyCode) {
        var endpoint = (API_BASE_PATH + API_PRIMARY_QUOTE_ENDPOINT).replace(':symbol', property.symbol);

        currencyCode = currencyCode || 'GBP';

        var payload = {
            budget: budget,
            currencyCode: currencyCode
        };

        return $http.post(endpoint, payload).then(function (response) {
            var data = response.data;

            var quote = {
                budget: budget,
                currencyCode: data.currencyCode,
                propertySymbol: data.propertySymbol,
                taxTotal: data.tax,
                feesTotal: data.fees,
                investmentCost: data.totalCost,
                transactionCost: data.tax + data.fees,
                totalCost: numberService.round2(data.totalCost + data.tax + data.fees),
                shares: data.totalUnits,
                sharePrice: data.weightedAveragePrice
            };

            return quote;
        }, function (error) {
            //if response suggests user is logged out check heartbeat and force logout if they are
            switch (error.status) {
            case 401:
                authService.handleUnauthorised();
                return $q.reject({
                    reason: 'auth.session-required'
                });
            default:
                // 'generate.quote.validation'
                // 'generate.quote.no.liquidity' - no shares for sale
                // 'generate.quote.shares.unavailable' - not enough to buy one share
                // 'generate.quote.holding.exceeded' - shares request are greter than the 20% limit
                return $q.reject({
                    reason: error.data && error.data.code || 'primary.generate-quote.unexpected-error'
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryQuote
     *
     * @description
     * Given one property and an investment amount (and an optional currencyCode)
     * returns a promise resolved with a secondary market quote.
     *
     * Example request:
     * ```
     * {
     *   symbol: '<string>',
     *   budget: <number>,
     *   currencyCode: '<string>'
     * }
     * ```
     *
     * Example response:
     * ```
     * {
     *   reference: '<string>',
     *   totalUnits: <number>,
     *   totalCost: <number>,
     *   weightedAveragePrice: <number>,
     *   currencyCode: '<string>',
     *   fees: <number>,
     *   propertySymbol: '<string>',
     *   budget: <number>,
     *   tax: <number>
     * }
     * ```
     * @param {object} property containing `symbol` and `shareValuation` attributes
     * @param {number} budget
     * @param {number} feeRate
     * @param {number} taxRate
     * @param {string=} currencyCode defaults to 'GBP'
     * @returns {Promise}
     */
    api.getSecondaryQuote = function (property, budget, feeRate, taxRate, currencyCode) {
        var endpoint = API_BASE_PATH + API_SECONDARY_QUOTE_ENDPOINT;

        currencyCode = currencyCode || 'GBP';

        // the "budget" attribute is interpreted on the backend as bidget for investmentCost (not including tax or fees)
        var maxInvestmentCost = api.calculateMaxInvestmentCost(budget, taxRate, feeRate);

        var payload = {
            symbol: property.symbol,
            budget: maxInvestmentCost,
            currencyCode: currencyCode
        };
        return $http.post(endpoint, payload).then(function (response) {
            var data = response.data;
            // @todo conversion-phase-2 normalize /secondary/generate-quote response
            var quote = {
                budget: budget,
                currencyCode: data.currencyCode,
                propertySymbol: data.propertySymbol,
                reference: data.reference,
                taxTotal: data.tax,
                feesTotal: data.fees,
                investmentCost: data.totalCost,
                transactionCost: data.tax + data.fees,
                totalCost: numberService.round2(data.totalCost + data.tax + data.fees),
                shares: data.totalUnits,
                weightedAveragePrice: data.weightedAveragePrice
            };

            var shareValuation = ppUtil.path(property, 'valuation.share');
            var netYield = ppUtil.path(property, 'income.net.pct');

            quote.premium = Number(ppBig(quote.weightedAveragePrice).minus(shareValuation).div(shareValuation));
            quote.yield = Number(ppBig(1).div(ppBig(1).plus(quote.premium)).times(netYield));
            return quote;
        }, function (error) {
            //if response suggests user is logged out check heartbeat and force logout if they are
            switch (error.status) {
            case 401:
                authService.handleUnauthorised();
                return $q.reject({
                    reason: 'auth.session-required'
                });
            default:
                // 'secondary.generate.quote.validation'
                // 'secondary.generate.quote.no.liquidity' - no shares for sale
                // 'secondary.generate.quote.shares.unavailable' - not enough to buy one share
                // 'secondary.generate.quote.holding.exceeded' - shares request are greter than the 20% limit
                return $q.reject({
                    reason: error.data && error.data.code || 'secondary.generate.unexpected-error'
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name propertyService#getSecondaryQuote
     *
     * @description
     * Given one property, an investment amount and the total cost of a previous quote,
     * returns a promise that is resolved with the quote.reference of a cheaper quote (of available)
     * or rejected with the reason for such quote not being available
     *
     * @param {object} property containing `symbol` and `shareValuation` attributes
     * @param {number} budget
     * @param {number} feeRate
     * @param {number} taxRate
     * @param {number} totalCost
     * @returns {Promise}
     */
    api.getSecondaryReQuote = function (property, budget, feeRate, taxRate, totalCost, currentAveragePrice) {
        return api.getSecondaryQuote(property, budget, feeRate, taxRate).then(function (quote) {
            //Checking to see if new quote weightedAveragePrice is more than current quote
            //If it is then the quote has expired
            if (quote.weightedAveragePrice <= currentAveragePrice) {
                return quote.reference;
            } else {
                return $q.reject({
                    reason: 'secondary.requote.expired',
                    newQuote: quote
                });
            }
        }, function (error) {
            switch (error.reason) {
            case 'secondary.generate.quote.no.liquidity':
            case 'secondary.generate.quote.shares.unavailable':
            case 'secondary.generate.quote.holding.exceeded':
                return $q.reject({
                    reason: 'secondary.requote.expired'
                });
            case 'property.currently.not.tradable':
                return $q.reject({
                    reason: 'property.currently.not.tradable'
                });
            default:
                return $q.reject({
                    reason: 'secondary.requote.unexpected-error'
                });
            }
        });
    };

    api.calculateInvestmentCost = function (shares, sharePrice) {
        return numberService.ceil2(sharePrice * shares);
    };

    api.calculateFees = function (investmentCost, rate) {
        return numberService.ceil2(investmentCost * rate);
    };

    api.calculateTax = function (investmentCost, rate) {
        return numberService.round2(investmentCost * rate);
    };

    function createQuoteStorageKey(symbol) {
        return symbol + '.quote';
    }

    /**
     * @ngdoc method
     * @name propertyService#storeQuote
     *
     * @description
     * stores quote for a property in session storage
     *
     * @param {string} symbol
     * @param {object} quote
     * @returns {undefined}
     */
    api.storeQuote = function (symbol, quote) {
        var key = createQuoteStorageKey(symbol);
        browserStoreService.setSessionStorageItem(key, $filter('ppSerializeJson')(quote));
    };

    /**
     * @ngdoc method
     * @name propertyService#retrieveQuote
     *
     * @description
     * retrieves quote for a property from session storage
     *
     * @param {string} symbol
     * @returns {object}
     */
    api.retrieveQuote = function (symbol) {
        var key = createQuoteStorageKey(symbol);
        return angular.fromJson(browserStoreService.getSessionStorageItem(key));
    };

    /**
     * @ngdoc method
     * @name propertyService#deleteQuote
     *
     * @description
     * deletes quote for a property in session storage
     *
     * @param {string} symbol
     * @returns {undefined}
     */
    api.deleteQuote = function (symbol) {
        var key = createQuoteStorageKey(symbol);
        browserStoreService.deleteSessionStorageItem(key);
    };

    api.isPrimaryProperty = isPrimaryProperty;
    api.isPreviewProperty = isPreviewProperty;
    api.isPreorderProperty = isPreorderProperty;
    api.isPreorderClosedProperty = isPreorderClosedProperty;
    api.isPrimaryOfferedProperty = isPrimaryOfferedProperty;
    api.isPrimaryClosedProperty = isPrimaryClosedProperty;
    api.isSecondaryProperty = isSecondaryProperty;
    api.isSecondaryInformationalProperty = isSecondaryInformationalProperty;
    api.getPropertySharesAvailablePct = getPropertySharesAvailablePct.bind(this, ppUtil.hasPathIn);
    api.hasPrimarySharesAvailable = hasPrimarySharesAvailable.bind(this, ppUtil.hasPathIn);
    api.isPropertyInvestmentActive = isPropertyInvestmentActive;
    api.getRatesForProperty = getRatesForProperty;
    api.normaliseProperty = normaliseProperty;
    api.normaliseTile = normaliseTile;

    api._normalisePropertyDescriptions = normalisePropertyDescriptions;

    return api;
}]);
})(window, window.angular);