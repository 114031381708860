(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.trust-certificate-list', [
    'pp.widgets-templates',
    'pp.widgets.property-card-list',
    'pp.widgets.expandable-content',
    'pp.widgets.auth-signup-cta',
    'pp.services.marketplace',
    'pp.services.investor',
    'pp.services.preference',
    'pp.services.route',
    'pp.services.core',
    'pp.ui.services.risk-warning',
    'pp.ui.services.restricted-investor-dialog',
    'pp.ui.services.authenticate-investor',
    'pp.ui.services.classification-dialog',
    'pp.ui.services.suitability-quiz-dialog',
    'pp.ui.services.trust-disclaimer-dialog'
]);

ngModule.component('ppTrustCertificateList', {
    templateUrl: 'widgets/_angular/trust-certificate-list/trust-certificate-list.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', '$window', 'marketplaceService', 'investorService', 'routeService', 'preferenceService', 'R', 'riskWarningService', 'authenticateInvestorAnonReject', 'classificationDialogService', 'restrictedInvestorDialog', 'suitabilityQuizDialog', 'trustDisclaimerDialog', function ($scope, $timeout, $window, marketplaceService, investorService, routeService, preferenceService, R, riskWarningService, authenticateInvestorAnonReject, classificationDialogService, restrictedInvestorDialog, suitabilityQuizDialog, trustDisclaimerDialog) {
        var vm = this;

        // -- initial state
        vm.isLoading = true;
        vm.loadingCardCount = 2;
        vm.user = undefined;

        vm.expandedIntro = false;

        vm.faq1 = 'What am I investing in?';
        vm.faq2 = 'What is my investment being used for?';
        vm.faq3 = 'What is the value of the issuance?';
        vm.faq4 = 'What is the rate of return?';
        vm.faq5 = 'How is the investment secured?';
        vm.faq6 = 'What are the risks of investment?';

        vm.mortgageBondKnowledgebase = routeService.mortgageBondKnowledgebase;
        vm.trustFaqs = routeService.trustFaqs;
        vm.trustKeyRisks = routeService.trustKeyRisks;
        vm.isaLandingPage = routeService.isaLandingPage;
        vm.loginPath = routeService.loginPath;
        vm.bondKeyRisks = routeService.bondKeyRisks;
        vm.risksOverview = routeService.risksOverview;

        // -- util functions

        var listFilter = R.filter(R.allPass([
            R.propEq('isTrust', true)
        ]));

        // -- api

        vm.toggleReadMore = function () {
            vm.expandedIntro = !vm.expandedIntro;
        };

        // -- lifecycle

        // -- main
        authenticateInvestorAnonReject()
            .then(function (user) {
                vm.user = user;
                return classificationDialogService.classifyUser(user, angular.noop);
            })
            .then(restrictedInvestorDialog.reclassifyUserForRestrictedInvestment)
            .then(function () {
                return investorService.escapeFromIsa(vm.user);
            }).then(function () {
                preferenceService.purgeCache();
                return preferenceService.hasAgreedToTrustDisclaimer();
            })
            .then(function (hasAgreed) {
                if (!hasAgreed) {
                    return $timeout(function () {
                        return trustDisclaimerDialog.showDialog();
                    }, 100);
                }
            }).then(function () {
                return $timeout(function () {
                    return suitabilityQuizDialog.showForPassAnyQuizzes(['trust_1', 'trust_2'], false, vm.user.classification);
                }, 100);
            }).then(function () {
                riskWarningService.showDevLoanRiskWarning();
                vm.canSeePage = true;
                return marketplaceService.getPropertiesList({
                    showPrimaryInformational: true
                }).then(function (properties) {
                    vm.list = listFilter(properties);
                    vm.isLoading = false;
                });
            }).catch(function (err) {
                console.log(err);
                $window.location.href = routeService.marketplacePath;
            });

    }]
});
})(window, window.angular);