(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-docs', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route'
]);

ngModule.component('ppInvestmentDocs', {
    templateUrl: 'widgets/_angular/investment-docs/investment-docs.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['ppConfig', 'routeService', 'ppMoment', function (ppConfig, routeService, ppMoment) {
        var vm = this;
        var __config = ppConfig.get('pp.external.endpoints');

        // -- initial state
        vm.s3Endpoint = __config ? __config.assets : null;

        vm.investorTermsAndConditions = routeService.investorTermsAndConditions;
        vm.deedOfAmendment = routeService.deedOfAmendment;

        // -- util

        function createDownloadLink(date) {
            var basePath = routeService.holdingStatementDownloadPath;
            return date && ppMoment(date).isBefore(ppMoment()) ? basePath + '?atDate=' + ppMoment(date).format('YYYY-MM-DD[T]HH:mm:ss') : basePath;
        }

        // -- api

        vm.onChangeHoldingsDate = function () {
            vm.holdingsDownloadLink = createDownloadLink(vm.selectedHoldingsDate);
        };

        vm.resetHoldingsDate = function () {
            vm.selectedHoldingsDate = undefined;
            vm.onChangeHoldingsDate();
        };

        // -- main
        vm.$onInit = function () {
            vm.onChangeHoldingsDate();
        };
    }]
});
})(window, window.angular);