(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.list-menu-expandable-items', [
    'pp.components-templates',
    'pp.components.list-menu-expander-item'
]);

ngModule.component('ppListMenuExpandableItems', {
    templateUrl: 'components/_angular/list-menu-expandable-items/list-menu-expandable-items.tpl.html',
    bindings: {
        data: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$onChanges = function (changes) {
            if (changes.data && angular.isObject(changes.data.currentValue)) {
                vm.open = !!changes.data.currentValue.isOpen;
            }

        };

    }]
});
})(window, window.angular);