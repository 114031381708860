(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.marketplace', [
    'pp.services.core',
    'pp.services.property'
]);

var TRADES_TTL = 1000 * 10;
var STUDENT_THEME_NAME = 'Student';
var COMMERCIAL_THEME_NAME = 'Commercial';
var STATUS_SUSPENDED = 'suspended';
var STATUS_HALTED = 'halted';
var STATUS_SECONDARY = 'secondary';

/**
 * @ngdoc service
 * @name dataService
 *
 * @description
 * Provides methods to invoke the `/resource/data` endpoints.
 */

ngModule.service('marketplaceService', ['$http', '$q', '$filter', 'propertyService', 'ppConfig', 'ppUtil', 'R', 'ppBig', function ($http, $q, $filter, propertyService, ppConfig, ppUtil, R, ppBig) {

    var promiseCache = {};

    var api = {};

    var API_BASE_PATH = '/feapi/r1';

    var TRADING_INDEX_TYPE = '7-day-wasp';
    var TRADING_RESIDENTIAL_INDEX_TYPE = '7-day-wasp-residential';
    var TRADING_STUDENT_INDEX_TYPE = '7-day-wasp-student';

    var PROPERTY_INVESTMENT_VALUE_INDEX_TYPE = 'surveyor-property-iv';
    var PROPERTY_VACANT_POSESSION_VALUE_INDEX_TYPE = 'surveyor-property-vpv';
    var SHARE_INVESTMENT_VALUE_INDEX_TYPE = 'surveyor-share-iv';
    var SHARE_VACANT_POSESSION_VALUE_INDEX_TYPE = 'surveyor-share-vpv';

    var PROPERTY_RESIDENTIAL_INVESTMENT_VALUE_INDEX_TYPE = 'surveyor-property-residential-iv';
    var PROPERTY_RESIDENTIAL_VACANT_POSESSION_VALUE_INDEX_TYPE = 'surveyor-property-residential-vpv';
    var PROPERTY_STUDENT_INVESTMENT_VALUE_INDEX_TYPE = 'surveyor-property-student-iv';
    var SHARE_RESIDENTIAL_INVESTMENT_VALUE_INDEX_TYPE = 'surveyor-share-residential-iv';
    var SHARE_RESIDENTIAL_VACANT_POSESSION_VALUE_INDEX_TYPE = 'surveyor-share-residential-vpv';
    var SHARE_STUDENT_INVESTMENT_VALUE_INDEX_TYPE = 'surveyor-share-student-iv';

    var API_PROPERTIES_STATIC = API_BASE_PATH + '/properties?view=static';
    var API_PROPERTIES_MARKETDATA = API_BASE_PATH + '/properties?view=marketdata';
    var API_PROPERTIES_TILE = API_BASE_PATH + '/properties?view=tile';

    var API_GET_TRADES_ENDPOINT = API_BASE_PATH + '/market-data/trades/latest';
    var API_GET_MOST_TRADED_ENDPOINT = API_BASE_PATH + '/market-data/properties/most-traded';
    var API_HISTORIC_INDEX = API_BASE_PATH + '/market-data/historic-index/:indexType';
    var API_CURRENT_TRADING_INDEX = API_BASE_PATH + '/market-data/current-index/' + TRADING_INDEX_TYPE;

    var ALTERNATIVE_SORT_SUFFIX = '-alt';

    var API_GET_SORT_OPTIONS = API_BASE_PATH + '/properties/sort-orders';
    var API_GET_THEMES = API_BASE_PATH + '/properties/themes';

    var API_PROPERTY_SETS = '/properties/property-sets';

    var BEFORE_MOST_TRADED_PATTERN = /^xx\-/;

    var __config = ppConfig.get('pp.services.marketplace');

    // Big Decimal JS code
    var roundUp = 3;

    // Properties to exclude from any list in the marketplace
    var propertyNotEqualToSymbol = R.allPass([
        R.complement(R.propEq('symbol', 'UKEC4N5AT002')),
        R.complement(R.propEq('symbol', 'UKHNR001')),
        R.complement(R.propEq('symbol', 'UKEC4N5AT003'))
    ]);

    var isTrust = R.propEq('isTrust', true);
    var isNotTrust = R.complement(isTrust);

    // -- util functions

    function populatePropertyWithPremiumDiscount(property) {
        property.market.premiumDiscount = propertyService.getPremiumDiscount(property);
        return property;
    }

    // -- api

    api.getTrades = function () {

        var cacheKey = 'getTrades';
        var now = Date.now();

        if (!promiseCache[cacheKey] || (now - promiseCache[cacheKey].timestamp) > TRADES_TTL) {

            var promise = $http.get(API_GET_TRADES_ENDPOINT).then(function (response) {
                    if (!response) {
                        return undefined;
                    }
                    return R.filter(propertyNotEqualToSymbol, response.data);
                },
                function () {
                    return $q.reject({
                        reason: 'marketplace.error.unexpected'
                    });
                });

            promiseCache[cacheKey] = {
                promise: promise,
                timestamp: now
            };
        }

        return promiseCache[cacheKey].promise;
    };

    api.getPropertiesStatic = function (params) {

        var cacheKey = 'getPropertiesStatic' + JSON.stringify(params);
        var now;

        if (!promiseCache[cacheKey] || ((now = new Date() - promiseCache[cacheKey].timestamp) > TRADES_TTL)) {

            var promise = propertyService.fetchPropertiesStatic(params);

            promiseCache[cacheKey] = {
                promise: promise,
                timestamp: now
            };
        }

        return promiseCache[cacheKey].promise;
    };

    api.getPropertiesTile = function (params) {

        var cacheKey = 'getPropertiesTile' + JSON.stringify(params);
        var now;

        if (!promiseCache[cacheKey] || ((now = new Date() - promiseCache[cacheKey].timestamp) > TRADES_TTL)) {

            var promise = propertyService.fetchPropertiesTile(params);

            promiseCache[cacheKey] = {
                promise: promise,
                timestamp: now
            };
        }

        return promiseCache[cacheKey].promise;
    };

    api.transformTradedData = function (item) {
        item.id = item.symbol;
        item.propertyName = item.name;
        item.timestamp = item.lastTradedTimestamp;
        item.details = [{
            label: 'Volume Traded',
            value: item.cumulativeValue !== null ? $filter('ppCurrency')(item.cumulativeValue) : 'N/A'
        }, {
            label: 'Buy Price',
            value: $filter('ppPenceWholeOr2dp')(item.currentPrice)
        }, {
            label: 'Avg Traded Price',
            value: item.vwap !== null ? $filter('ppPenceWholeOr2dp')(item.vwap) : 'N/A'
        }];
        return item;
    };

    api.transformTradesData = function (item) {
        item.propertyName = item.name;
        item.details = [{
            label: 'Amount Traded',
            value: $filter('ppCurrency')(item.amountTraded)
        }, {
            label: 'Traded Price',
            value: $filter('ppPenceWholeOr2dp')(item.price)
        }, {
            label: '% Change',
            value: item.offerPriceChangePct !== null ? $filter('ppSignedPercentage')(item.offerPriceChangePct * 100, 2) : '0.00%'
        }];
        return item;
    };

    api.refreshLatestTradingData = function (oldTrades, oldMostTraded) {
        var newTradesPromise = api.getNewTrades(oldTrades);
        var mostTradedUpdatedPromise = api.getMostTradedWithUpdatedFlag(oldMostTraded);

        return $q.all([newTradesPromise, mostTradedUpdatedPromise]).then(function (data) {
            return {
                trades: data[0].map(api.transformTradesData),
                mostTraded: data[1].map(api.transformTradedData)
            };
        });
    };

    api.getPropertiesMarketdata = function (params) {

        var cacheKey = 'getPropertiesMarketdata' + JSON.stringify(params);
        var now;

        if (!promiseCache[cacheKey] || ((now = new Date() - promiseCache[cacheKey].timestamp) > TRADES_TTL)) {

            var promise = propertyService.fetchPropertiesMarketdata(params);

            promiseCache[cacheKey] = {
                promise: promise,
                timestamp: now
            };
        }

        return promiseCache[cacheKey].promise;
    };

    function escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    api.searchFilter = function (properties, search) {
        if (!search) {
            return properties;
        }

        var searchRegexp = new RegExp(escapeRegExp(search.replace(/[\s,\.]/g, '')), 'i');

        return properties.filter(function (property) {
            var searchableString = $filter('propertyLocation')(property.location) + property.location.location1;
            return searchRegexp.test(searchableString.replace(/[\s,\.]/g, ''));
        });
    };

    function createPropertySymbolMap(properties) {
        return properties.reduce(function (propertyMap, property) {
            propertyMap[property.symbol] = angular.copy(property);
            return propertyMap;
        }, {});
    }

    api.getPropertiesList = function (params, search) {
        return propertyService.getPropertiesList(params).then(function (properties) {
            return api.searchFilter(properties, search).map(populatePropertyWithPremiumDiscount);
        }, function (error) {
            switch (error.status) {
            case 404:
                return [];
            default:
                return $q.reject({
                    reason: 'marketplace.properties-list.unexpected-error'
                });
            }

        });
    };

    api.getThemeCountsMapFromPropertyList = function (properties) {

        if (!angular.isArray(properties)) {
            return {};
        }

        return properties.reduce(function (map, property) {
            property.themes = property.themes || [];
            property.themes.forEach(function (theme) {
                if (map[theme]) {
                    map[theme]++;
                } else {
                    map[theme] = 1;
                }
            });
            return map;
        }, {});
    };

    api.getNewTrades = function (oldTrades) {

        var oldTradesMap = {};

        if (oldTrades) {
            oldTrades.forEach(function (trade) {
                oldTradesMap[trade.id] = true;
            });
        }

        return api.getTrades().then(function (trades) {

            trades.forEach(function (trade) {
                if (oldTrades && !oldTradesMap[trade.id]) {
                    trade.new = true;
                }
            });

            return trades;
        });
    };

    api.getMostTraded = function () {

        var cacheKey = 'getMostTraded';
        var now = Date.now();

        if (!promiseCache[cacheKey] || (now - promiseCache[cacheKey].timestamp) > TRADES_TTL) {

            var promise = $http.get(API_GET_MOST_TRADED_ENDPOINT).then(function (response) {
                    return R.filter(R.allPass([propertyNotEqualToSymbol, isNotTrust]), response.data);
                },
                function () {
                    return $q.reject({
                        reason: 'marketplace.error.unexpected'
                    });
                });

            promiseCache[cacheKey] = {
                promise: promise,
                timestamp: now
            };
        }

        return promiseCache[cacheKey].promise;
    };

    api.getMostTradedWithUpdatedFlag = function (oldMostTraded) {

        var oldMostTradedMap = {};

        if (oldMostTraded) {
            oldMostTraded.forEach(function (property) {
                oldMostTradedMap[property.symbol] = property;
            });
        }

        return api.getMostTraded().then(function (newMostTraded) {

            return newMostTraded.map(function (property) {
                if (oldMostTraded && (!oldMostTradedMap[property.symbol] || property.lastTradedTimestamp !== oldMostTradedMap[property.symbol].lastTradedTimestamp)) {
                    property.updated = true;
                }
                return property;
            });

        });
    };

    api.getSortOptions = function () {
        var cacheKey = 'sortCriteria';

        if (!promiseCache[cacheKey]) {

            var promise = $http.get(API_GET_SORT_OPTIONS).then(function (response) {
                    var data = response.data || {};
                    return data['sort-orders'];
                },
                function () {
                    return $q.reject({
                        reason: 'marketplace.error.unexpected'
                    });
                });

            promiseCache[cacheKey] = promise;
        }

        return promiseCache[cacheKey];
    };

    api.getThemes = function () {
        var cacheKey = 'marketplaceThemes';

        if (!promiseCache[cacheKey]) {

            var promise = $http.get(API_GET_THEMES).then(function (response) {
                    var data = response.data || {};
                    if (!angular.isArray(data.themes)) {
                        return []; //note early return
                    }

                    return data.themes.map(function (item) {
                        if (item.key === 'uk') {
                            item.label = 'All Properties';
                        }
                        return item;
                    }).filter(function (item) {
                        return ['crossrail',
                            'commuter-belt',
                            'regeneration-areas',
                            'specialist'
                        ].indexOf(item.key) === -1;
                    });
                },
                function () {
                    return $q.reject({
                        reason: 'marketplace.error.unexpected'
                    });
                });

            promiseCache[cacheKey] = promise;
        }

        return promiseCache[cacheKey];
    };

    // -- PP-5177 These functions are a hack based on a feature request to have a featured property on the marketplace they should be removed

    // TODO remove post backend featured property functionality

    function findPropertyIndexBySymbol(symbol, properties) {
        var propertiesLength = properties.length;
        for (var ix = 0; ix < propertiesLength; ix++) {
            if (properties[ix].symbol === symbol) {
                return ix;
            }
        }
    }

    // TODO remove post backend featured property functionality

    function moveElementInArray(array, from, to) {
        var item = array[from];
        var length = array.length;
        var diff = from - to;

        if (diff > 0) {
            // move left
            return [].concat(
                array.slice(0, to), [item],
                array.slice(to, from),
                array.slice(from + 1, length)
            );
        } else if (diff < 0) {
            // move right
            return [].concat(
                array.slice(0, from),
                array.slice(from + 1, to + 1), [item],
                array.slice(to + 1, length)
            );
        } else {
            // Dont move if from == to
            return array;
        }
    }

    // TODO remove post backend featured property functionality

    api.shuffleFeaturedPropertiesToTop = function (featuredProperties, properties) {

        // if no featured properties
        if (!angular.isArray(featuredProperties) || !featuredProperties.length) {
            return properties;
        }

        var indexToInsert = properties.reduce(function (foundIndex, property, index) {
            if (angular.isNumber(foundIndex)) {
                return foundIndex;
            }

            if (property.state && property.state.status === STATUS_SECONDARY) {
                return index;
            }

        }, null);

        return featuredProperties.map(function (symbol) {
                return findPropertyIndexBySymbol(symbol, properties);
            })
            .reduce(function (shuffledProperties, index) {
                if (angular.isDefined(index)) {
                    return moveElementInArray(shuffledProperties, index, indexToInsert);
                } else {
                    return shuffledProperties;
                }
            }, properties);
    };

    function normalisePropertyWithPremium(property) {
        return propertyService
            .normaliseTile(populatePropertyWithPremiumDiscount(property));
    }

    function normalisePropertiesInsideSets(set) {
        if (set && set.properties && angular.isArray(set.properties)) {
            set.properties = set.properties.map(normalisePropertyWithPremium);
        }

        return set;
    }

    function getMostTradedProperties(properties) {
        try {
            return properties
                .sort(function (a, b) {
                    return b.market.secondary.volumeTraded30Days - a.market.secondary.volumeTraded30Days;
                }).slice(0, 10);
        } catch (err) {}
    }

    api.getPropertySets = function (propertyPromise) {
        var endpoint = API_BASE_PATH + API_PROPERTY_SETS;

        if (!promiseCache[endpoint]) {

            promiseCache[endpoint] = $q.all({
                sets: $http.get(endpoint),
                properties: propertyPromise || $q.when(null)
            }).then(function (res) {
                var mostTradedProperties = res.properties ? R.filter(R.allPass([propertyNotEqualToSymbol, isNotTrust]), getMostTradedProperties(res.properties)) : null;
                var sets;
                var indexOfBeforeMostTraded = res.sets.data.reduce(function (foundIndex, item, index) {
                    if (angular.isDefined(foundIndex)) {
                        return foundIndex;
                    } else if (item && item.key.match(BEFORE_MOST_TRADED_PATTERN)) {
                        return index;
                    }
                }, undefined);
                if (mostTradedProperties) {
                    sets = ppUtil.insertInList(res.sets.data, {
                        title: 'Most traded',
                        key: 'most-traded',
                        properties: mostTradedProperties
                    }, angular.isDefined(indexOfBeforeMostTraded) ? indexOfBeforeMostTraded + 1 : 0);
                } else {
                    sets = res.sets.data;
                }

                return sets.map(normalisePropertiesInsideSets);
            }, function (err) {
                return $q.reject({
                    reason: 'property-sets.error.unexpected'
                });
            });
        }

        return promiseCache[endpoint];
    };

    function getHistoricIndex(indexType) {

        return function () {
            var endpoint = API_HISTORIC_INDEX.replace(':indexType', indexType);
            var cacheKey = indexType + '-index';
            if (!promiseCache[cacheKey]) {
                promiseCache[cacheKey] = $http.get(endpoint).then(function (res) {
                    return res.data;
                }, function () {
                    return $q.reject({
                        reason: cacheKey + '.error.unexpected'
                    });
                });
            }

            return promiseCache[cacheKey];
        };
    }

    api.getHistoricTradingIndex = getHistoricIndex(TRADING_INDEX_TYPE);

    api.getHistoricResidentialIndex = getHistoricIndex(TRADING_RESIDENTIAL_INDEX_TYPE);
    api.getHistoricStudentIndex = getHistoricIndex(TRADING_STUDENT_INDEX_TYPE);

    api.getPropertyInvestmentValueIndex = getHistoricIndex(PROPERTY_INVESTMENT_VALUE_INDEX_TYPE);

    api.getPropertyVacantPossessionValueIndex = getHistoricIndex(PROPERTY_VACANT_POSESSION_VALUE_INDEX_TYPE);

    api.getShareInvestmentValueIndex = getHistoricIndex(SHARE_INVESTMENT_VALUE_INDEX_TYPE);

    api.getShareVacantPossessionValueIndex = getHistoricIndex(SHARE_VACANT_POSESSION_VALUE_INDEX_TYPE);

    api.getPropertyResidentialInvestmentValueIndex = getHistoricIndex(PROPERTY_RESIDENTIAL_INVESTMENT_VALUE_INDEX_TYPE);
    api.getPropertyResidentialVacantPossessionValueIndex = getHistoricIndex(PROPERTY_RESIDENTIAL_VACANT_POSESSION_VALUE_INDEX_TYPE);
    api.getPropertyStudentInvestmentValueIndex = getHistoricIndex(PROPERTY_STUDENT_INVESTMENT_VALUE_INDEX_TYPE);
    api.getShareResidentialInvestmentValueIndex = getHistoricIndex(SHARE_RESIDENTIAL_INVESTMENT_VALUE_INDEX_TYPE);
    api.getShareResidentialVacantPossessionValueIndex = getHistoricIndex(SHARE_RESIDENTIAL_VACANT_POSESSION_VALUE_INDEX_TYPE);
    api.getShareStudentInvestmentValueIndex = getHistoricIndex(SHARE_STUDENT_INVESTMENT_VALUE_INDEX_TYPE);

    api.getCurrentTradingIndex = function () {
        var endpoint = API_CURRENT_TRADING_INDEX;

        return $http.get(endpoint).then(function (res) {
            return res.data;
        }, function () {
            return $q.reject({
                reason: 'current-trading-index.error.unexpected'
            });
        });
    };

    // -- End of hack PP-5177

    var isNotNil = R.complement(R.isNil);
    var lastTradePctChangePath = R.path(['market', 'lastTradePctChange']);
    var hasLastTradePct = R.compose(isNotNil, lastTradePctChangePath);

    var propertyIsActive = R.anyPass([R.propEq('isActive', true), R.propEq('isInClosedPeriod', true)]);
    var propertyNotActive = R.complement(propertyIsActive);
    var propertyIsSecondaryTrading = R.propEq('isSecondaryTrading', true);

    var isRightsIssue = R.propEq('isRightsIssue', true);
    var notRightsIssue = R.complement(isRightsIssue);

    var isRightsIssueParent = R.anyPass([R.propEq('efAuctionVoteInProgress', true), R.propEq('isRightsIssueParent', true)]);
    var notRightsIssueParent = R.complement(isRightsIssueParent);

    var isBlockTrade = R.propEq('isBlockTrade', true);
    var notBlockTrade = R.complement(isBlockTrade);

    var isBlockTradeParent = R.anyPass([
        R.allPass([
            R.has('blockTradePropertySymbol'),
            R.propEq('blockTradeOnPropertyActive', true)
        ]),
        R.propEq('isExitMechanicVote', true),
        R.propEq('blockTradeOnPropertyClosed', true)
    ]);

    var notBlockTradeParent = R.complement(isBlockTradeParent);

    var isLiveTradingProperty = R.allPass([propertyNotEqualToSymbol, propertyIsActive, notRightsIssue, notRightsIssueParent, notBlockTrade, notBlockTradeParent, isNotTrust]);
    var isOtherInvestmentProperty = R.allPass([propertyNotEqualToSymbol, propertyNotActive, notRightsIssue, notRightsIssueParent, notBlockTrade, notBlockTradeParent, isNotTrust]);
    var isMapViewProperty = R.allPass([propertyNotEqualToSymbol, notRightsIssue, notRightsIssueParent, notBlockTrade, notBlockTradeParent, isNotTrust]);
    var canBeTopRiserOrFaller = R.allPass([hasLastTradePct, propertyNotEqualToSymbol, propertyIsSecondaryTrading, isNotTrust]);

    var defaultToZero = R.defaultTo(0);

    api.filterLiveTradingProperties = R.filter(isLiveTradingProperty);
    api.filterOtherInvestments = R.filter(isOtherInvestmentProperty);
    api.filterMapViewProperties = R.filter(isMapViewProperty);

    api.filterRightsIssues = function (properties) {
        var rightsIssueParents = R.filter(isRightsIssueParent, properties);
        var propertyDict = R.indexBy(R.prop('symbol'), properties);
        return R.map(function (item) {
            item.rightsIssueProperty = R.clone(propertyDict[item.rightsIssueFundingSymbol]);
            return item;
        }, rightsIssueParents);
    };

    api.filterBlockTrades = function (properties) {
        var blockTradeParents = R.filter(isBlockTradeParent, properties);
        var propertyDict = R.indexBy(R.prop('symbol'), properties);
        return R.map(function (item) {
            item.blockTradeProperty = R.clone(propertyDict[item.blockTradePropertySymbol]);
            return item;
        }, blockTradeParents);
    };

    api.filterRisersAndFallers = R.filter(canBeTopRiserOrFaller);

    var sortByPctChange = R.compose(R.sortBy(lastTradePctChangePath), api.filterRisersAndFallers);
    var getFirstFiveItems = R.slice(0, 5);

    var isTradingFilter = R.filter(R.propEq('isSecondaryTrading', true));

    // Expects a property object either tile or full

    api.getTopFallers = R.compose(getFirstFiveItems, sortByPctChange, isTradingFilter);
    api.getTopRisers = R.compose(getFirstFiveItems, R.reverse, sortByPctChange, isTradingFilter);

    function calculateRaiseProportion(holding, property) {
        return Math.ceil(Number(ppBig(defaultToZero(R.path(['units'], holding))).times(defaultToZero(property.equityRaiseNewSharesPct))));
    }

    api.calculateYourProportionOfRaiseShares = function (holding, property) {
        var raiseSharePrice = property.sharePriceFundraise;
        if (R.isNil(raiseSharePrice)) {
            return 0;
        }

        return calculateRaiseProportion(holding, property);
    };

    api.calculateYourProportionOfRaise = function (holding, property) {
        var raiseSharePrice = property.sharePriceFundraise;
        if (R.isNil(raiseSharePrice)) {
            return 0;
        }

        var raiseProportion = calculateRaiseProportion(holding, property);
        return Number(ppBig(raiseProportion).times(raiseSharePrice).round(2, roundUp));
    };

    var createEquityFundraiseItem = R.curry(function (holdingsMap, pendingInvestmentsMap, property) {
        var newProperty = R.mergeRight({
            yourProportionOfRaise: api.calculateYourProportionOfRaise(R.path([property.symbol], holdingsMap), property),
            yourProportionOfRaiseShares: api.calculateYourProportionOfRaiseShares(R.path([property.symbol], holdingsMap), property),
            amountYouHaveInvested: R.defaultTo(0, R.path([property.rightsIssueFundingSymbol, 'reservedValue'], pendingInvestmentsMap)),
            fundedPct: R.defaultTo(R.path(['rightsIssueProperty', 'market', 'primary', 'fundedPct'], property), R.path(['hardCodedFundedPct'], property))
        }, property);

        return newProperty;
    });

    var createBlockTradeItem = R.curry(function (pendingInvestmentsMap, votingMap, property) {
        var sharePrice = R.defaultTo(R.propEq('isVpvBased', true, property) ? R.path(['valuation', 'shareVpv'], property) : R.path(['valuation', 'share'], property), R.path(['blockTradeProperty', 'valuation', 'share'], property));
        var fundraiseShares = R.defaultTo(0, R.defaultTo(R.path([property.symbol, 'choicesDict', 'sell', 'sharesVoted'], votingMap), R.path(['blockTradeProperty', 'primary', 'numberOfShares'], property)));
        var targetFundraise = R.defaultTo(Number(ppBig(ppBig(sharePrice).round(4).times(fundraiseShares)).round(2, 3)), R.path(['blockTradeProperty', 'primary', 'capitalRaise'], property));
        return R.mergeRight({
            amountYouHaveInvested: R.defaultTo(0, R.path([property.blockTradePropertySymbol, 'reservedValue'], pendingInvestmentsMap)),
            fundedPct: R.defaultTo(R.path(['blockTradeProperty', 'market', 'primary', 'fundedPct'], property), R.path(['hardCodedFundedPct'], property)),
            sharePrice: sharePrice,
            fundraiseShares: fundraiseShares,
            targetFundraise: targetFundraise
        }, property);
    });

    api.createEquityFundraiseList = function (holdingsMap, properties, pendingInvestmentsMap) {
        return R.map(createEquityFundraiseItem(holdingsMap, pendingInvestmentsMap), properties);
    };

    api.createBlockTradeList = function (properties, votingMap, pendingInvestmentsMap) {
        return R.map(createBlockTradeItem(pendingInvestmentsMap, votingMap), properties);
    };

    return api;
}]);
})(window, window.angular);