(function(window, angular){'use strict';

var TERMS_DOCS_TYPE = 'general-terms';
var QUOTE_DEBOUNCE_PRIMARY_MS = 500;
var QUOTE_DEBOUNCE_SECONDARY_MS = 500;

var ngModule = angular.module('pp.widgets.quote-form', [
    'pp.widgets-templates',
    'pp.widgets.quote-breakdown',
    'pp.widgets.order-type-select',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.property',
    'pp.services.number',
    'pp.services.config',
    'pp.ui.services.terms-dialog',
    'pp.components.input-amount',
    'pp.filters.numbers'
]);

// @todo general purpose, lift
function ridGenerator(len) {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    var str = '';
    while (str.length < len) {
        str += S4();
    }
    return str.substring(0, len);
}

function isNaN(num) {
    var parsedNum = Number(num);
    return parsedNum !== parsedNum;
}

function formatNumber(num) {
    return !isNaN(num) ? Number(num).toFixed(2) : undefined;
}

function isUndefined(value) {
    return angular.isUndefined(value);
}

/**
 * @ngdoc component
 * @name ppQuoteForm
 * @description
 * Provides user with a quote given an investment amount.
 *
 * * **EVENTS**
 * - broadcasts on `$rootScope` event `pp.quote.onChange` with payload `{quote: <object>}` (consumed by investCtrl)
 * - listens on `$scope` event `pp.quote.updateAmount` with payload `{amount: <number>}` to force the amount update (broadcasted by investCtrl)
 * - listens on `$scope` event `pp.quote.updateQuote` with payload `{quote: <object>}` to force the entire quote update (broadcasted by investCtrl)
 *
 * **NOTE:** requires filter `ppCurrencyToo`.
 *
 * @restrict A
 * @scope
 * @param {Object} ppQuoteForm
 * @param {Object} user Logged in user, if available.
 * @param {Object} property
 * @param {Number} taxRate
 * @param {Number} feeRate
 * @param {string} focusOn set to 'auto' to auto-focus input, or to a string to listen for on $scope in order to focus on demand
 * @param {Function} onQuoteValidation callback invoked with `(valid, reason)` every time quote validation changes
 * @param {Boolean} quoteEnabled allow querying the API on secondary market offers (if disabled UI will be render showing "--.-" values)
 * @param {Boolean} quoteEditable allows the user to edit the quote
 * @param {Boolean} feedbackEnabled enabling showing validation feedback as the user type
 */
ngModule.component('ppQuoteForm', {
    templateUrl: 'widgets/_angular/quote-form/quote-form.tpl.html',
    bindings: {
        quote: '=',
        user: '<',
        userUnits: '<',
        property: '<',
        minimumTransaction: '<',
        maximumFunding: '<',
        minimumInvestment: '<',
        taxRate: '<',
        feeRate: '<',
        focusOn: '@',
        onQuoteValidation: '&',
        quoteEnabled: '=',
        quoteEditable: '=',
        feedbackEnabled: '=',
        supressLimitValidation: '=',
        isSidebar: '<?',
        maxOwnershipPerProperty: '<',
        orderType: '<',
        onChangeOrderType: '&'
    },
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$q', '$element', '$timeout', 'ppTrack', 'ppComponentEmitter', 'configService', 'investorService', 'numberService', 'propertyService', 'termsDialogService', function ($rootScope, $scope, $q, $element, $timeout, ppTrack, ppComponentEmitter, configService, investorService, numberService, propertyService, termsDialogService) {
        var vm = this;

        // -- initial state
        var __quoteDebounceMs;
        var __lastUpdateAmount;
        var __maxSharesPerInvestorPerProperty;
        var isPrimaryOffered;
        var isPrimary;
        var sharesAvailable;

        vm.processing = false;

        // input amount is decoupled from quote.budget
        // quote.budget is updated to inputAmount OR zero if input is invald
        vm.inputRegexp = /^\d*(\.\d{1,2})?$/;

        // -- util functions

        function onQuoteChange() {
            var quote = vm.quote;

            $rootScope.$broadcast('pp.quote.onChange', {
                quote: quote
            });

            if (vm.quoteValid) {
                propertyService.storeQuote(vm.property.symbol, vm.quote);
            }

            ppTrack.setContext({
                'quote.budget': quote.budget,
                'quote.shares': quote.shares,
                'quote.totalCost': formatNumber(quote.totalCost),
                'quote.payment': formatNumber(quote.paymentAmount)
            });

            if (!propertyService.isPrimaryOfferedProperty(vm.property)) {
                ppTrack.setContext('quote.premium', formatNumber(quote.premium));
                ppTrack.setContext('quote.yield', formatNumber(quote.yield));
            }

            ppTrack.setContext('quote.invalid', vm.quoteValid ? null : vm.quoteInvalidReason);
        }

        // utility to calculate amount payable, based on available funds
        // also, transactionCost is not served by secondary quote api
        // finally, force the paymentAmount to at least minimumTransaction (and calculate amount being funded in excess)
        function updateTotals() {
            var quote = vm.quote;
            quote.transactionCost = quote.feesTotal + quote.taxTotal;

            if (vm.user && vm.user.financials.funds > 0) {
                // Defensive because occasionally vm.quote.totalCost is not to 2 decimal places PP-2665
                // - 3907.03 (investment costs) + 78.15 (transaction costs) ->
                // - 3985.1800000000003
                // which results in
                // - 3985.1800000000003 (total investment) - 3985.18 (investor funds) ->
                // - leftToPay = 0.0000000000003
                quote.paymentAmount = numberService.round2(vm.quote.totalCost - vm.user.financials.funds);
                if (quote.paymentAmount < 0) {
                    quote.paymentAmount = 0;
                }
                vm.leftToPay = quote.paymentAmount;
            } else {
                quote.paymentAmount = vm.quote.totalCost;
                vm.leftToPay = 0;
            }
            if (quote.paymentAmount && quote.paymentAmount < vm.minimumTransaction) {
                quote.fundAmount = vm.minimumTransaction - quote.paymentAmount;
                quote.paymentAmount = vm.minimumTransaction;
            } else {
                quote.fundAmount = 0;
            }
        }

        // is invoked directly when reloading a quote issued by the backend (with a reference OR an error)
        // and when backend returns an error (valid = false, reason = <string>)
        function updateQuoteValidity(valid, reason) {
            vm.quoteValid = valid;
            vm.quoteInvalidReason = !valid ? reason : null;
            vm.onQuoteValidation({
                valid: valid,
                reason: reason
            });
        }

        function validateQuote() {
            var valid = true;
            var reason;
            var quote = vm.quote;
            var user = vm.user;
            var property = vm.property;
            var permissions = user.permissions;

            // - quote contains own error
            if (vm.quote.error) {
                valid = false;
                reason = vm.quote.error;
            }
            // - quote not active
            else if (!propertyService.isPropertyInvestmentActive(property)) {
                valid = false;
                reason = 'quote.error.offer.inactive';
            }
            // - invalid input
            else if (vm.minimumInvestment && vm.minimumInvestment > 0 && vm.form['vm.ppInputAmount'].$invalid && vm.form['vm.ppInputAmount'].$error.min) {
                valid = false;
                reason = 'quote.error.below-minimum';
            } else if ((vm.form && vm.form['vm.ppInputAmount'].$invalid) || !vm.quote.budget) {
                valid = false;
                reason = 'quote.error.invalid-input';
            } else if (angular.isDefined(vm.maximumFunding) && quote.paymentAmount > vm.maximumFunding && vm.user.isIsa) {
                valid = false;
                reason = 'quote.error.would-exceed-isa-funding';
            }
            // - primary: fully funded
            else if (isPrimaryOffered && !sharesAvailable) {
                valid = false;
                reason = 'quote.error.primary.funded';
            }
            // - primary or pre-order: budget < 1x share
            else if (isPrimary && quote.shares === 0) {
                valid = false;
                reason = 'quote.error.primary.zeroShares';
            }
            // - primary: ownership is greater than 19.99% (secondary 20% check is done by backend on quote)
            // - set valid = true to test how review page handles backend error
            else if ((isPrimaryOffered || propertyService.isPreorderProperty(property)) && !property.isFundingForBlockListing && !property.isTrust && !property.isRightsIssue && (quote.shares + vm.userUnits) > __maxSharesPerInvestorPerProperty) {
                valid = false;
                reason = 'quote.error.primary.individualShareExceeded';
            }

            updateQuoteValidity(valid, reason);
        }

        function updateQuotePrimary(inputAmount) {
            if ((vm.form && !vm.form['vm.ppInputAmount'].$valid) || isNaN(inputAmount) || inputAmount <= 0) {
                vm.quoteReady = false;
                vm.quote.budget = 0;
                return $q.when();
            } else {
                return propertyService.getPrimaryQuote(vm.property, inputAmount).then(function (quote) {
                    vm.quote = quote;
                    vm.quoteReady = quote.shares > 0;
                    vm.quote.reference = vm.quoteReady ? 'PRIMARY' : null;
                }, function (error) {
                    vm.quote.error = error.reason;
                    vm.quoteReady = false;
                    updateTotals();
                    validateQuote();
                });
            }
        }

        // stores last request id in order to mute responses and errors if subsquent requests are made before this one resolves/rejects
        // in which case, responses and errors are muted an processing state is preserved
        var __quoteRequestId;
        // issues a request for secondary market quote
        // then updates the quote object and propagates the changes
        function updateQuoteSecondary(inputAmount) {
            var quote = vm.quote;
            // if current input amount is not a number
            inputAmount = Number(inputAmount);

            // only request new quote if number is valid and above zero
            // @todo conversion-phase-2 ideally we would the cheapest share available here
            // request isn't genereated, but the __quoteRequestId is rewritten (responses to previous reqs will be ignored)
            __quoteRequestId = ridGenerator(4);
            if ((vm.form && !vm.form['vm.ppInputAmount'].$valid) || isNaN(inputAmount) || inputAmount <= 0) {
                vm.quoteReady = false;
                quote.budget = 0;
                return $q.when(quote);
            } else {
                vm.processing = true;
                var promise = propertyService.getSecondaryQuote(vm.property, inputAmount, vm.feeRate, vm.taxRate).then(function (result) {
                    // mute response, another request was made meanwhile
                    if (promise.quoteRequestId === __quoteRequestId) {
                        delete quote.error;
                        for (var attr in result) {
                            quote[attr] = result[attr];
                        }
                        // secondary quotes can switch state between ready and not ready
                        vm.quoteReady = true;
                    }
                }, function (error) {
                    // mute error, another request was made meanwhile
                    if (promise.quoteRequestId === __quoteRequestId) {
                        // clear quote
                        delete quote.error;
                        for (var attr in quote) {
                            quote[attr] = null;
                        }
                        quote.budget = inputAmount;
                        quote.error = error.reason;
                        // quote marked as not ready if request failed
                        vm.quoteReady = false;
                        updateTotals();
                        validateQuote();
                        return $q.reject();
                    }
                })['finally'](function () {
                    // do not cancel processing state if another request has been made
                    if (promise.quoteRequestId === __quoteRequestId) {
                        vm.processing = false;
                    }
                });
                promise.quoteRequestId = __quoteRequestId;
                return promise;
            }
        }

        // updates the quote object based on a new input amount
        // note: secondary is async
        function updateQuote(force) {

            var promise;
            // ppTrack last amount that actually generated an update
            // only update if necessary (or requested to force update, see below)
            var amount = vm.form && vm.form['vm.ppInputAmount'].$valid ? vm.inputAmount : 0;
            if (!force && amount === __lastUpdateAmount) {
                promise = $q.when(true);
            } else {
                vm.quote.processing = true;
                __lastUpdateAmount = amount;
                if (propertyService.isPrimaryProperty(vm.property)) {
                    promise = updateQuotePrimary(amount);
                } else {
                    promise = updateQuoteSecondary(amount);
                }
            }
            return promise.then(function () {
                vm.quote.processing = false;
                updateTotals();
                validateQuote();
                onQuoteChange();
            });
        }

        var __quoteTimer;

        // @todo refactor with proper debounce
        function debouncedUpdateQuote() {
            if (vm.quoteEnabled) {
                vm.quote.processing = true;
                $timeout.cancel(__quoteTimer);
                __quoteTimer = $timeout(function () {
                    __quoteTimer = null;
                    updateQuote();
                }, __quoteDebounceMs);
            }
        }

        // -- api

        vm.changeOrderType = function (orderType) {
            vm.onChangeOrderType({
                orderType: orderType
            });
        };

        // -- scope bindings

        // update totals and validity only if user available funds change
        $scope.$watch('vm.user.financials.funds', function (newValue, oldValue) {
            updateTotals();
            validateQuote();
        });

        // force update if offer shares available change
        $scope.$watch('vm.property.market.primary.fundedPct', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                updateQuote(true);
            }
        });

        // update when quote becomes enabled (typically after the controller has established the user's state)
        $scope.$watch('vm.quoteEnabled', function (newValue, oldValue) {
            if (newValue) {
                updateQuote();
            }
        });

        // debounced update when user types
        $scope.$watch('vm.invalidInputAmount', function (newValue, oldValue) {
            debouncedUpdateQuote();
        });

        // debounced update when user types
        $scope.$watch(function () {
            return vm.form && vm.form['vm.ppInputAmount'] ? vm.form['vm.ppInputAmount'].$invalid : undefined;
        }, function (newValue, oldValue) {
            debouncedUpdateQuote();
        });

        // update if an input amount is forced from outside
        $scope.$on('pp.quote.updateAmount', function ($ev, payload) {
            vm.inputAmount = payload.amount;
            updateQuote();
        });

        // update totals and validity if a new quote is forced from outside
        $scope.$on('pp.quote.updateQuote', function ($ev, payload) {
            var quote = vm.quote;
            delete quote.error;
            for (var attr in payload.quote) {
                quote[attr] = payload.quote[attr];
            }
            vm.quoteReady = !vm.quote.error;
            vm.inputAmount = vm.quote.budget;
            updateTotals();
            validateQuote();
            onQuoteChange();
        });

        $scope.$on('$destroy', function () {
            $timeout.cancel(__quoteTimer);
        });

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('quote.dom.loaded', $element);
        };

        vm.$onInit = function () {

            __quoteDebounceMs = propertyService.isPrimaryProperty(vm.property) ? QUOTE_DEBOUNCE_PRIMARY_MS : QUOTE_DEBOUNCE_SECONDARY_MS;

            // @TODO someday move into a service that takes property and percentage and gives back max shares per property per investor
            __maxSharesPerInvestorPerProperty = Math.floor(vm.maxOwnershipPerProperty * vm.property.primary.numberOfShares);

            isPrimaryOffered = propertyService.isPrimaryOfferedProperty(vm.property);
            vm.isPrimaryOffered = isPrimaryOffered;

            isPrimary = propertyService.isPrimaryProperty(vm.property);
            vm.isPrimary = isPrimary;

            sharesAvailable = propertyService.getSharesAvailablePrimary(vm.property);
            vm.sharesAvailable = sharesAvailable;

            ppComponentEmitter.emit('quote.controller.loaded', vm);

            // mark quote as ready in case a complete quote is passed from the outside (has a reference or an error attribute)
            // still needs to update totals (user payment takes into account their available funds)
            // note: the provided quote might be invalid (ex: user is not allowed to quote)
            vm.inputAmount = vm.quote.budget;

            if (vm.quote.reference && !vm.quote.expired || vm.quote.error) {
                __lastUpdateAmount = vm.quote.budget;
                vm.quoteReady = !vm.quote.error;
                updateTotals();
                updateQuoteValidity(!vm.quote.error, vm.quote.error);
            }
        };

    }]
});
})(window, window.angular);