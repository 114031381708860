(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.suitability', []);

ngModule.service('suitabilityService', ['$http', '$q', function ($http, $q) {

    var api = {};

    var API_BASE_ENDPOINT = '/feapi/r1';

    var INVESTMENT_PLAN_SUITABILITY_QUIZ_ENDPOINT = '/investor/investment-plan-orders/suitability';
    var FUND_SUITABILITY_QUIZ_ENDPOINT_1 = '/investor/quiz/fund-suitability-REIT-:classification-1';
    var FUND_SUITABILITY_QUIZ_ENDPOINT_2 = '/investor/quiz/fund-suitability-REIT-:classification-2';
    var MORTGAGE_QUIZ_ENDPOINT_1 = '/investor/quiz/mortgage-single-property-suitability-1';
    var MORTGAGE_QUIZ_ENDPOINT_2 = '/investor/quiz/mortgage-single-property-suitability-2';
    var TRUST_QUIZ_ENDPOINT_1 = '/investor/quiz/trust-certificate-quiz-1';
    var TRUST_QUIZ_ENDPOINT_2 = '/investor/quiz/trust-certificate-quiz-2';

    var QUIZ_ENDPOINTS = {
        investmentPlans: INVESTMENT_PLAN_SUITABILITY_QUIZ_ENDPOINT,
        fund_1: FUND_SUITABILITY_QUIZ_ENDPOINT_1,
        fund_2: FUND_SUITABILITY_QUIZ_ENDPOINT_2,
        mortgage_1: MORTGAGE_QUIZ_ENDPOINT_1,
        mortgage_2: MORTGAGE_QUIZ_ENDPOINT_2,
        trust_1: TRUST_QUIZ_ENDPOINT_1,
        trust_2: TRUST_QUIZ_ENDPOINT_2
    };

    api.getSuitability = function (quizType, classification) {

        var endpoint = API_BASE_ENDPOINT + QUIZ_ENDPOINTS[quizType].replace(':classification', classification);

        return $http.get(endpoint).then(function (res) {
            return res.data;
        });
    };

    api.submitAnswers = function (quizType, answers, classification) {

        var endpoint = API_BASE_ENDPOINT + QUIZ_ENDPOINTS[quizType].replace(':classification', classification);

        var payload = {
            answers: answers
        };

        return $http.put(endpoint, payload).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            case 401:
                return $q.reject({
                    reason: 'suitability.answers.investor-not-authorised'
                });
            case 403:
                return $q.reject({
                    reason: 'suitability.answers.quiz-failure-limit-exceeded'
                });
            case 409:
                return $q.reject({
                    reason: 'suitability.answers.not-enough-answers'
                });
            default:
                return $q.reject({
                    reason: 'suitability.answers.unexpected-error'
                });

            }
        });
    };

    api.submitFundSuitabilityAnswers = api.submitAnswers.bind(null, 'fund');

    return api;

}]);
})(window, window.angular);