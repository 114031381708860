(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.auth-nav-mobile-menu', [
    'pp.widgets-templates',
    'pp.components.list-menu',
    'pp.services.route',
    'pp.services.config'
]);

/**
 * @ngdoc directive
 * @name ppAuthNavUser
 * @description
 * Renders the dropdown and fund button
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppAuthNavMobileMenu', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-nav-mobile-menu/auth-nav-mobile-menu.tpl.html',
        scope: {
            user: '=ppAuthNavMobileMenu',
            blogUrl: '@',
            path: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$window', 'routeService', 'configService', function ($window, routeService, configService) {

            var vm = this;

            // -- initial state

            vm.menuItems = [{
                    label: 'Home',
                    href: '/'
                },
                {
                    label: 'Trading Exchange',
                    href: routeService.marketplacePath
                },
                {
                    label: 'Mortgage Bonds',
                    href: routeService.mortgageBondPath
                },
                {
                    label: 'Bridging Finance',
                    href: routeService.bridgingFinancePath
                },
                {
                    label: 'ISA',
                    href: routeService.isaLandingPage
                },
                {
                    label: 'About',
                    items: [{
                            label: 'How LHX works',
                            href: routeService.howItWorksPath
                        },
                        {
                            label: 'About LHX',
                            href: routeService.aboutusPath
                        }
                    ]
                },
                {
                    label: 'Resources',
                    items: [{
                            label: 'Knowledge base',
                            href: routeService.knowledgeBaseLink
                        },
                        {
                            label: 'Blog',
                            href: routeService.blogPath
                        },
                        {
                            label: 'Contact us',
                            href: routeService.contactUsPath
                        }
                    ]
                },
                {
                    label: 'Settings',
                    href: routeService.accoutSettingsPath,
                    authenticated: true
                }
            ];

            // -- api

            // -- main

        }]
    };
});
})(window, window.angular);